import React, { useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import inventoryApi from "../api/inventoryApi";
import * as constants from "../constants/CONSTANT";


import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PubSub from 'pubsub-js';
const Setting = (props) => {
  const [userInfo, setUserInfo] = useState({});
  const [syncButtonDisabled, setSyncButtonDisabled] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const [lastSynced, setLastSynced] = useState();
  const [syncError, setSyncError] = useState(false);
  const location = useLocation();
  useEffect(() => {
    /* try {
      if (localStorage.getItem("token")) {
        let user = jwt_decode(localStorage.getItem("token"));
        console.log("user:", user);
        setUserInfo(user);
        console.log("userInfo :", userInfo );
      }
    } catch (error) {
      console.log(error);
    }
    */
  }, []);

  useEffect(() => {
    if (props?.companyid) {
      async function init() {
        const companyResult = await inventoryApi.fetchCompanyInfoById(
          props.companyid
        );
        console.log("companyResult ", companyResult);
        setCompanyInfo(companyResult);
      }
      async function initPendingJobs() {
        const result = await inventoryApi.fetchJobByType('SYNC_ALL');
        console.log("result ", result);
        if(result.status === 'Pending'){
          setSyncButtonDisabled(true);
        }
        else if(result.status === 'Error'){
          setLastSynced(result.enddate);
          setSyncError(true)
        
        }else{
          setLastSynced(result.enddate);
        }
      }
      init();

      initPendingJobs();


    }
  }, [props]);

  const sfAuthorizeOpen = () => {
    //window.location.href=`https://login.salesforce.com/services/oauth2/authorize?client_id=${constants.CLIENT_ID}&redirect_uri=${window.location.protocol}//${window.location.hostname}/callback&response_type=code&state=${account.id}&prompt=login`;
    window.location.href = `https://login.salesforce.com/services/oauth2/authorize?client_id=${constants.SF_CLIENT_ID}&redirect_uri=${constants.REDIRECT_URI}&response_type=code&state=${companyInfo.id}&prompt=login`;
  };

  const sfSyncNow = async () => {
    setSyncButtonDisabled(true);
    let contact_request_body = {
      refresh_token: companyInfo.sf_refreshtoken,
      instanceUrl: companyInfo.sf_instanceurl,
      query:
        "SELECT id, firstname, lastname, salutation, title, phone, email FROM Contact",
        pgTableName : "Contact"
    };
    await inventoryApi
      .syncSFRecords(contact_request_body)
      .then((res) => {
        console.log("Contact Res ", res);
        
        if (res?.data) {
          PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Successfully Synced",
            message: "Contact records have been successfully synced.",
          });
         } else {
          setSyncButtonDisabled(false);
          PubSub.publish("RECORD_ERROR_TOAST", {
            title: "Failed",
            message: 'Contact records failed to sync.',
          });
          
         }
      })
      .catch((error) => {
        console.log("error ", error);
        PubSub.publish("RECORD_ERROR_TOAST", {
          title: "Failed",
          message: error,
        });
        setSyncButtonDisabled(false);
      });

    
    

    setSyncButtonDisabled(true);
    let lead_request_body = {
      refresh_token: companyInfo.sf_refreshtoken,
      instanceUrl: companyInfo.sf_instanceurl,
      query:
        "SELECT id, firstname, lastname, salutation, title, street, city, state, country,  phone, email, company, leadsource,  status, industry FROM lead",
      pgTableName : "Lead"
    };

    await inventoryApi
      .syncSFRecords(lead_request_body)
      .then((res) => {
        console.log("Lead Res ", res);
        if (res?.data) {
          PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Successfully Synced",
            message: "Lead records have been successfully synced.",
          });
         } else {
          PubSub.publish("RECORD_ERROR_TOAST", {
            title: "Failed",
            message: 'Lead records failed to sync.',
          });
         }
         setSyncButtonDisabled(false);
      })
      .catch((error) => {
        console.log("error ", error);
        PubSub.publish("RECORD_ERROR_TOAST", {
          title: "Failed",
          message: error,
        });
        setSyncButtonDisabled(false);
      });


  };

  return (
    <Container className="view-form">
      <Row className="view-form gx-5 mx-auto">
        <Col lg={12}>
          <Row className="view-form-header align-items-center">
            <Col lg={3}>Salesforce Connection Settings</Col>
            <Col lg={9} className="d-flex justify-content-end"></Col>
          </Row>

          <Row
            className="py-3 ibs-edit-form"
            style={{ backgroundColor: "#fff" }}
          >
            <Col lg={12} className="mb-2">
              {companyInfo.sf_syncenabled ? (
                <Row>
                <Col lg={6} style={{backgroundColor: "orange", padding: ".6rem", borderBottom: "1px solid #fff", textTransform: "uppercase", fontWeight: "bold"}}>Salesforce Organization Id </Col> <Col lg={6}> {companyInfo.sf_orgid} </Col>
                <Col lg={6} style={{backgroundColor: "orange", padding: ".6rem" , borderBottom: "1px solid #fff", textTransform: "uppercase", fontWeight: "bold"}}>Salesforce Org Details </Col> <Col lg={6}> {companyInfo.sf_instanceurl} </Col>
                <Col lg={6} style={{backgroundColor: "orange", padding: ".6rem", borderBottom: "1px solid #fff", textTransform: "uppercase", fontWeight: "bold"}}>Salesforce Username </Col> <Col lg={6}> {companyInfo.sf_username} </Col>
               
              
                
                </Row>
              ) : (
                ""
              )}
            </Col>
            <Col lg={12}>
              <div className="mb-3"><i>Last synced  {lastSynced} {syncError && <i class="fa-sharp fa-solid fa-circle-xmark" title="Sync Error" style={{cursor:"pointer", color: "tomato"}}></i>} </i></div>
              {companyInfo.sf_syncenabled ? (
                <>
                  {syncButtonDisabled ? (
                    <Button
                      className="btn-md"
                      variant="primary"
                      disabled
                      onClick={() => sfSyncNow()}
                    >
                      <i class="fas fa-sync fa-spin mx-2"></i>
                      Sync in progress...
                    </Button>
                  ) : (
                    <Button
                      className="btn-md"
                      variant="primary"
                      onClick={() => sfSyncNow()}
                    >
                      <i class="fas fa-sync mx-2"></i>
                      Sync Now
                    </Button>
                  )}

                  <Button
                    className="btn-md mx-2"
                    variant="primary"
                    onClick={() => sfAuthorizeOpen()}
                    disabled={syncButtonDisabled }
                  >
                    <i class="fa-solid fa-cloud mx-2"></i>
                    Re-Authorize To Salsforce
                  </Button>
                </>
              ) : (


                <Button
                  className="btn-md"
                  variant="primary"
                  onClick={() => sfAuthorizeOpen()}
                  
                >
                  <i class="fa-solid fa-cloud mx-2"></i>
                  Authorize To Salsforce 
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Setting;
