import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { ShimmerTable } from "react-shimmer-effects";
import moment from "moment/moment";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "./InfoPill";

const ProjectList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [projects, setProjects] = useState();
  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchProjects();
      console.log("result:", result);
      //if(result && result.errors && result.errors)
      if (result) {
        setBody(result);
        setProjects(result);
      } else {
        setBody([]);
        setProjects([]);
      }
    }
    init();
  }, []);

  const onFilterType = (event) => {
    if (event.target.value === "") {
      setBody(projects);
    } else {
      setBody(
        projects.filter((data) => {
          if (
            (data.type || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
        })
      );
    }
  };

  // Create table headers consisting of 4 columns.
  const header = [
    {
      title: "Name",
      prop: "name",
      isSortable: true,
      isFilterable: true,
      cell: (row) => (
        <Link to={"/projects/" + row.id} state={row}>
          {row.name}
        </Link>
      ),
    },
    {
      title: "Start Date",
      prop: "startdate",
      isSortable: true,
      isFilterable: true,
      cell: (row) =>
        row.startdate ? moment(row.startdate).format("DD-MM-YYYY") : "",
    },
    {
      title: "End Date",
      prop: "enddate",
      isSortable: true,
      isFilterable: true,
      cell: (row) =>
        row.enddate ? moment(row.enddate).format("DD-MM-YYYY") : "",
    },
    { title: "Budget", prop: "budget", isSortable: true, isFilterable: true },
    // { title: 'Street', prop: 'street', isSortable : true, isFilterable: true },
    // { title: 'Type', prop: 'type', isSortable : true, isFilterable: true},
    // { title: 'Area', prop: 'area', isSortable : true, isFilterable: true },
    // { title: 'Status', prop: 'status', isSortable : true, isFilterable: true },
    // { title: 'City', prop: 'city', isSortable : true, isFilterable: true },
    //{ title: 'Active', prop: 'isactive', isSortable : true, isFilterable: true },
    {
      title: "Address",
      prop: "address",
      isSortable: true,
      isFilterable: true,
      cell: (row) => {
        const addressParts = [row.street, row.city, row.pincode, row.state].filter(Boolean);
    
        return addressParts?.length > 0 ? (
          <div className="address">
            {addressParts.join(", ")}
          </div>
        ) : null;
      },
    },
    {
      title: "Active",
      prop: "isactive",
      cell: (row) => (
        <i
          style={{ fontSize: "1.5rem", color: "#a19f9d" }}
          className={
            row.isactive ? "fa-regular fa-square-check" : "fa-regular fa-square"
          }
        ></i>
      ),
    },
    // { title: 'Contact', prop: 'contactname', isSortable : true, isFilterable: true },
    // { title: 'Phone', prop: 'phone', isSortable : true, isFilterable: true },
    // { title: 'Assigned To', prop: 'ownername', isSortable : true, isFilterable: true },
  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createProject = () => {
    navigate(`/projects/e`);
  };

  return (
    <Container>
      <CustomSeparator
        // cmpListName="Report List"
        currentCmpName="Projects"
        indexLength="0"
        url="/projects"
      ></CustomSeparator>
    <Row className="g-0">
      
      <Col lg={12} className="px-4">
        {body ? (
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 15, 20],
              },
            }}
            sortProps={{
              initialState : {
              prop: "createddate",
              order: "desc"
            } }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={6}
                className="d-flex flex-col justify-content-start align-items-center"
              >
                <PaginationOptions labels={labels} />
                {/* <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
             
                                            <Form.Select aria-label="Enter status" name="type" onChange={onFilterType}>
                                                <option value="">--Select Type--</option>
                                                <option value="Flat">Flat </option>
                    <option value="Villa">Villa</option>
                    <option value="Plot">Plot</option>
                    <option value="Land">Land</option>
                    <option value="House">House</option>
                    <option value="Shop">Shop</option>                         
                    <option value="Apartment">Apartment </option>               
                    <option value="Commercial Space">Commercial Space</option>
                                            </Form.Select>
                                        </Form.Group> */}
                <InfoPill left="Total" right={body?.length} />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={2}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={() => createProject(true)}
                >
                  New Project
                </Button>
              </Col>
            </Row>
            <Table striped className="data-table">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />
          </DatatableWrapper>
        ) : (
          <ShimmerTable row={10} col={8} />
        )}
      </Col>
      <Col lg={2}></Col>
    </Row>
    </Container>
  );
};

export default ProjectList;
