import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { ShimmerTable } from "react-shimmer-effects";
import Form from "react-bootstrap/Form";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "./InfoPill";

const ContactList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [contctTypeArray, setContactTypeArray] = useState(
    JSON.parse(localStorage.getItem("contact_type"))
  );

  const [properties, setProperties] = useState();
  const [filterObj, setFilterObj] = useState({});

  useEffect(() => {
    async function init() {
      const contacts = await inventoryApi.fetchContacts();
      console.log("ContactList====>====", contacts);
      if (contacts && contacts.length) {
        console.log("true");
        setBody(contacts);
        setContacts(contacts);
        //setProperties(contacts);
      } else {
        console.log("false");
        setBody([]);
        // setProperties([]);
      }

      console.log("=======" + body.length);
    }
    init();
  }, []);

  // ====== Filter on Type =======
  const onFilterType = (event) => {
    console.log("onFilterType", onFilterType);
    if (event.target.value === "") {
      setBody(contacts);
      console.log("setBody", setBody);
    } else {
      setBody(
        contacts.filter((data) => {
          if (
            (data.type || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
        })
      );
    }
  };

  // const onFilterType = (event) => {
  //   const selectedType = event.target.value.toLowerCase(); // Convert to lowercase
  //   console.log('Selected Type:', selectedType);

  //   if (selectedType === '') {
  //     setBody(contacts);
  //   } else {
  //     const filteredContacts = contacts.filter((data) => {
  //       return data.type && data.type.toLowerCase() === selectedType; // Match lowercase type
  //     });
  //     console.log('Filtered Contacts:', filteredContacts);
  //     setBody(filteredContacts);
  //   }
  // };

  // useEffect(() => {
  //   onFilterType();
  // }, [filterObj]);

  // const onFilterType = () => {
  //   if (body) {
  //     let tempData = properties?.filter((item) => {
  //       if (!filterObj.type || filterObj.type === '' || item.type === filterObj.type) {
  //         return item;
  //       }
  //     });
  //     // tempData = tempData.filter((item) => {
  //     //   if (!filterObj.projectid || filterObj.projectid === '' || item.projectid === filterObj.projectid) {
  //     //     return item;
  //     //   }
  //     // });
  //     setBody(tempData);
  //   }
  // };

  // const handleChange = (e) => {
  //   setFilterObj({ ...filterObj, "type": e.target.value });
  // }

  // Create table headers consisting of 4 columns.
  const header = [
    {
      title: "Name",
      prop: "lastname",
      isFilterable: true,
      cell: (row) => (
        <div>
          <Link to={"/contacts/" + row.id} state={row} className="name">
            {`${row.firstname} ${row.lastname}`}
          </Link>
        </div>
      ),
    },
    // { title: 'First Name', prop: 'firstname' , isFilterable: true ,
    // cell: (row) => (
    //   <Link
    //     to={"/contacts/" + row.id}
    //     state={row}
    //    >
    //     {row.firstname}
    //   </Link>
    // ) },
    // { title: 'Last Name', prop: 'lastname', isFilterable: true ,
    // cell: (row) => (
    //   <Link
    //     to={"/contacts/" + row.id}

    //   >
    //     {row.lastname}
    //   </Link>
    // )},
    { title: "Email", prop: "email", isFilterable: true },
    { title: "Contact Type", prop: "type", isFilterable: true },
    { title: "Phone", prop: "phone", isFilterable: true },
    // {title: 'City', prop: 'city' , isFilterable: true },
    // {title: 'State', prop: 'state'}
    {
      title: "Address",
      prop: "address",
      isSortable: true,
      isFilterable: true,
      cell: (row) => {
        const addressParts = [row.street, row.city, row.pincode, row.state].filter(Boolean);
    
        return addressParts?.length > 0 ? (
          <div className="address">
            {addressParts.join(", ")}
          </div>
        ) : null;
      },
  },
  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createContact = () => {
    navigate(`/contacts/e`);
  };

  return (
    <Container>
      <CustomSeparator
        // cmpListName="Report List"
        currentCmpName="Contacts"
        indexLength="0"
        url="/contacts"
      ></CustomSeparator>

      <Row className="g-0">
        <Col lg={12} className="px-4">
          {/* {body && body.length &&  */}
          {/* {body ? */}
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20],
              },
            }}
            sortProps={{
              initialState : {
              prop: "createddate",
              order: "desc"
            } }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-center"
              >
                <PaginationOptions labels={labels} />
                <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                  <Form.Select
                    aria-label="Enter Type"
                    name="type"
                    onChange={onFilterType}
                  >
                    <option value="">--Select Type--</option>
                    {contctTypeArray?.map((item, index) => (
                      <option value={item.label} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">

                  <Form.Select aria-label="Enter status" name="type" onChange={handleChange}>
                  <option value="">--Select--</option>
        <option value="Property Owner">Property Owner</option>
        <option value="Buyer">Buyer</option>
        <option value="Seller">Seller</option>
        <option value="Agent">Agent</option>
        <option value="Staff">Staff</option>
                  </Form.Select>
                </Form.Group> */}
                <InfoPill left="Total" right={body?.length} />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={() => createContact(true)}
                >
                  New Contact
                </Button>
              </Col>
            </Row>
            {body ? (
              <Table striped className="data-table">
                <TableHeader />

                <TableBody />
              </Table>
            ) : (
              <ShimmerTable row={10} col={8} />
            )}

            <Pagination />
          </DatatableWrapper>
          {/* ) :(
           <ShimmerTable row={10} col={8} />
         )  */}
          {/* }    */}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </Container>
  );
};

export default ContactList;
