import React, { useState, useEffect } from "react";

import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RelatedListTask from "./RelatedListTask";
import inventoryApi from "../api/inventoryApi";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TaskEdit from "./TaskEdit";
import PropertySaleEdit from "./PropertySaleEdit";
import EmailComposer from "./common/EmailComposer";
import RelatedLead from "./RelatedLead";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailIcon,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";
import jwt_decode from "jwt-decode";
import * as constants from "../constants/CONSTANT";

const PropertyView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [property, setProperty] = useState(
    location.state ? location.state : {}
  );
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [relatedListTasks, setRelatedListTasks] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [relatedLeads, setRelatedLeads] = useState(true);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshFileList, setRefreshFileList] = useState();
  const [showTransactionModel, setShowTransactionModel] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [type, setType] = useState("Income");

  const [showVideo, setShowVideo] = useState(Boolean(property.vidurl));

  const [imageURL, setImageURL] = useState();

  const [showSocial, setShowSocial] = useState(false);
  const [shareUrl, setShareUrl] = useState(); /* yamini 25-10-2023 */

  const openVideoWindow = () => {
    setShowVideo(true);
  };

  const closeVideoWindow = () => {
    setShowVideo(false);
  };
  /* Start yamini 06-09-2023 */

  async function fetchImageURL() {
    let user = jwt_decode(localStorage.getItem("token"));
    let files = await inventoryApi.fetchFiles(property.id);
    let imageFiles = files?.filter((file) => {
      return file?.documenttype === "property_image";
    });
    if (imageFiles?.length) {
      let image =
        constants.PROJECT_BASE_URL +
        "/images/" +
        user.tenantcode +
        "/" +
        property.id +
        "/" +
        imageFiles[0].id +
        "." +
        imageFiles[0].filetype;
      setImageURL(image);
    } else {
      setImageURL("/NoFound.svg");
    }
  }

  /* End yamini 06-09-2023 */
  useEffect(() => {
    if (!window.myMap) {
      window.myMap = myMap;
      const googleMapScript = document.createElement("script");
      // googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBZsH0US1O6fSogoqBTUpUkvEWqs-rYMlY&callback=myMap`;
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDkVza2FtLItgn_kzJ27_A4l2Eyf3ZrOOI&callback=myMap`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
      //console.log('refresh:');
    } else {
      myMap();
    }
    fetchProperty();
    fetchImageURL();
    //
    fetchCompanyInfo(); /* yamini 25-10-2023 */
  }, []);

  /* Start yamini 25-10-2023 */

  const fetchCompanyInfo = async () => {
    let user = jwt_decode(localStorage.getItem("token"));
    const companyResult = await inventoryApi.fetchCompanyInfoById(
      user.companyid
    );
    console.log("companyResult.shareurl ", companyResult.shareurl);
    setShareUrl(companyResult.shareurl);
  }
  
/* End yamini 25-10-2023 */

  const fetchProperty = () => {
    // Coming from Email
    console.log("from url lead id: ", location, property.id);
    if (
      !property.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/")?.length >= 3
    ) {
      property.id = location.pathname.split("/")[2];
    }

    console.log("proepties ", property.id);
    async function initProperty() {
      let result = await inventoryApi.fetchProperty(property.id);
      ////console.log('result:', result);
      if (result) {
        //console.log('property: ',result);
        setProperty(result);
      } else {
        setProperty({});
      }
    }
    initProperty();
  };
  useEffect(() => {
    setRefreshFileList(Date.now());
  }, [property]);
  const createTransaction = (tType) => {
    setType(tType);
    setShowTransactionModel(true);
  };

  const submitTransaction = async () => {
    setShowTransactionModel(false);
    let propertyUpdate = { id: property.id, status: "Sold" };
    let result = await inventoryApi.saveProperty(propertyUpdate);
    navigate(`/properties/${property.id}`, { state: property });
    fetchProperty();
  };

  /*const taskList = () => {
    async function initTask() {
      let task = await inventoryApi.fetchTasks(property.id);
      if (task && task?.length > 0){
        setTasks(task);
      }else{
        setTasks([]);
      }
    }
    initTask();
  }

  const filesList = () => {
    async function initFiles() {
      let file = await inventoryApi.fetchFiles(property.id);
      //console.log(file)
      if (file && file?.length > 0) { 
        //console.log('file')
        setFiles(file);
      } else {
        setFiles([]);
      }
    }
    initFiles();
  }
  */
  const deleteProperty = async () => {
    const result = await inventoryApi.deleteProperty(property.id);
    if (result.success) navigate(`/properties`);
  };

  const editLead = () => {
    navigate(`/properties/${property.id}/e`, { state: property });
  };

  const submit = () => {
    setModalShowTask(false);
    navigate(`/properties/${property.id}`, { state: property });
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
    setModalShowEmail(false);
  };
  const submitfiles = () => {
    //console.log('called:');
    setModalShowFile(false);
    setRefreshFileList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setRelatedListTasks(true);
      setRelatedListFiles(false);
      setRelatedLeads(false);
    } else if (key === "files") {
      setRelatedListTasks(false);
      setRelatedListFiles(true);
      setRelatedLeads(false);
    } else if (key === "leads") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedLeads(true);
    }
  };

  const handleVideoLinkClick = (event) => {
    event.preventDefault();
    // Get the video URL from the project data
    const videoUrl = property.vidurl;
    if (videoUrl) {
      // Open the video URL in the same window
      window.location.href = videoUrl;
    }
  };

  const myMap = () => {
    let contentString = `<h4>${property.name}</h4>
    <label className='maplabel'>Address:</label> <p>${property.street},${property.city},${property.state},${property.pincode},${property.country}</p>
    <label className='maplabel'>Contact Person:</label> <p> ${property.contactname} </p>
    <label className='maplabel'>Description:</label> <p> ${property.description} </p>`;
    const infowindow = new window.google.maps.InfoWindow({
      content: contentString,
      ariaLabel: "Uluru",
    });

    var mapProp = {
      center: new window.google.maps.LatLng(51.508742, -0.12085),
      zoom: 14,
    };
    var map = new window.google.maps.Map(
      document.getElementById("googleMap"),
      mapProp
    );

    var geocoder = new window.google.maps.Geocoder();
    var address = `${property.street},${property.city},${property.state},${property.pincode},${property.country}`;
    geocoder.geocode({ address: address }, function (results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        map.setCenter(results[0].geometry.location);
        var marker = new window.google.maps.Marker({
          map: map,
          position: results[0].geometry.location,
          title: `${property.name}`,
        });

        marker.addListener("click", () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        });
      }
    });
  };

  const handleSocialIcon = () => {
    setShowSocial(!showSocial);
  };
  return (
    <div>
      <Container>
        <CustomSeparator
          cmpListName="Properties"
          currentCmpName={property.name}
          indexLength="2"
          url="/properties"
        ></CustomSeparator>
      </Container>
      {property && (
        <Container className="mt-4">
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteProperty={deleteProperty}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="property"
            />
          )}
          <Row className="view-form m-2">
            <Col lg={8} className="ibs-form-section">
              <Row className="view-form-header align-items-center">
                <Col lg={6}>
                  Property
                  <h4>{property.name}</h4>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editLead(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>
                  <Button
                    className="float-right btn-sm mx-2"
                    onClick={() => setModalShowEmail(true)}
                    variant="success"
                  >
                    <i class="fa-regular fa-envelope mx-2"></i>
                    Send Email
                  </Button>
                  {property.status !== "Sold" && (
                    <Button
                      className="btn-sm mx-2"
                      variant="warning"
                      onClick={() => setShowTransactionModel(true)}
                    >
                      Sale Property
                    </Button>
                  )}

                  {/* Start yamini 06-09-2023 */}
                  {shareUrl &&
                  
                    <div
                      tabIndex={0}
                      onClick={handleSocialIcon}
                      // onBlur={handleSocialIcon}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      <img
                        src="/share-svgrepo-com.svg"
                        width="24px"
                        style={{ borderRadius: "5px" }}
                      />
                      {showSocial && (
                        <div
                          style={{
                            position: "absolute",
                            zIndex: "100",
                            borderRadius: "10px",
                            padding: ".5rem",
                            backgroundColor: "#f0f1f1",
                          }}
                        >
                          <EmailShareButton
                            url={`${shareUrl.includes('?') ? `${shareUrl}${property.id}` : `${shareUrl}/${property.id}`}`}
                            className="mx-1 mb-1"
                          >
                            <EmailIcon size={24} round />
                          </EmailShareButton>
                          <br></br>
                          <FacebookShareButton
                            url={`${shareUrl.includes('?') ? `${shareUrl}${property.id}` : `${shareUrl}/${property.id}`}`}
                            quote="Estate Manager"
                            className="mx-1 mb-1"
                          >
                            <FacebookIcon size={24} round />
                          </FacebookShareButton>
                          <br></br>
                          <WhatsappShareButton
                            url={`${shareUrl.includes('?') ? `${shareUrl}${property.id}` : `${shareUrl}/${property.id}`}`}
                            className="mx-1 mb-1"
                          >
                            <WhatsappIcon size={24} round />
                          </WhatsappShareButton>
                          <br></br>
                          <LinkedinShareButton
                            url={`${shareUrl.includes('?') ? `${shareUrl}${property.id}` : `${shareUrl}/${property.id}`}`}
                            className="mx-1 mb-1"
                          >
                            <LinkedinIcon size={24} round />
                          </LinkedinShareButton>
                          <br></br>
                          <TelegramShareButton
                            url={`${shareUrl.includes('?') ? `${shareUrl}${property.id}` : `${shareUrl}/${property.id}`}`}
                            className="mx-1 mb-1"
                          >
                            <TelegramIcon size={24} round />
                          </TelegramShareButton>
                          <br></br>
                          <TwitterShareButton
                            className="mx-1 mb-1"
                            url={`${shareUrl.includes('?') ? `${shareUrl}${property.id}` : `${shareUrl}/${property.id}`}`}
                          >
                            <TwitterIcon size={24} round />
                          </TwitterShareButton>
                        </div>
                      )}
                    </div>
                  }

                  {/* End yamini 06-09-2023 */}
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  {/* Changed by Yamini 11-12-2023 */}
                  <label>Property Name</label>
                  <span>{property.name}</span>
                </Col>
                <Col lg={6}>
                  <label>Code</label>
                  <span>{property.code}</span>
                </Col>
                <Col lg={6}>
                  <label>Email</label>
                  <span>{property.email}</span>
                </Col>
                <Col lg={6}>
                  <label>Phone</label>
                  <span>{property.phone}</span>
                </Col>
                <Col lg={6}>
                  <label>Project Name</label>
                  <span>
                    <Link to={`/projects/${property.projectid}`}>
                      {property.projectname}
                    </Link>
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Project For</label>
                  <span>{property.propertyfor}</span>
                </Col>
                <Col lg={6}>
                  <label>Category</label>
                  <span>{property.legalstatus}</span>
                </Col>
                <Col lg={6}>
                  <label>Type</label>
                  <span>{property.type}</span>
                </Col>
                <Col lg={2}>
                  <label>Measure in</label>
                  <span>{property.areameasure || <br />}</span>
                </Col>
                {/* <Col lg={4}>
                  <label>Total Area</label>
                  <span>{property.area}</span>
                </Col> */}
                {/* Added by yamini 19-1-2024 */}
                <Col lg={2}>
                  <label>Area Type</label>
                  <span className="text-truncate">{property.areatype || <br />}</span>
                </Col>
                <Col lg={2}>
                  <label>Total Area</label>
                  <span>{property.area}</span>
                </Col>

                <Col lg={6}>
                  <label>Property Cost</label>
                  <span>{property.cost || <br />}</span>
                </Col>
                <Col lg={6}>
                  <label>Contact Person</label>
                  <span>
                    <Link to={`/contacts/${property.contactid}`}>
                      {property.contactname || <br />}
                    </Link>
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Assigned Staff</label>
                  <span>
                    <Link to={`/users/${property.ownerid}`}>
                      {property.ownername || <br />}
                    </Link>
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Street</label>
                  <span>{property.street}</span>
                </Col>
                <Col lg={6}>
                  <label>City</label>
                  <span>{property.city}</span>
                </Col>
                <Col lg={6}>
                  <label>State</label>
                  <span>{property.state}</span>
                </Col>
                <Col lg={6}>
                  <label>Pincode</label>
                  <span>{property.pincode}</span>
                </Col>
                <Col lg={6}>
                  <label>Country</label>
                  <span>{property.country}</span>
                </Col>
                <Col lg={6}>
                  <label>Show On Web</label>
                  <span>
                    {property.showonweb}{" "}
                    <input type="checkbox" checked={property.showonweb} />
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Status</label>

                  <Badge
                    bg={`${
                      property.status === "Available" ? "success" : "danger"
                    }`}
                  >
                    {property.status}
                  </Badge>
                </Col>
                <Col lg={6}>
                  <label>Description</label>
                  <span>{property.description}</span>
                </Col>
                <Col lg={6}>
                  <label>Video Url</label>
                  {/* <span>{property.vidurl || <br />}</span> */}
                  {property.vidurl && (
                    <div className="video-overlay">
                      <div className="video-container">
                        {/* <button className="close-button" onClick={closeVideoWindow}>
            Close
          </button> */}
                        <iframe
                          title="YouTube Video"
                          width="450"
                          height="315"
                          src={property.vidurl}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col>
              <div
                id="googleMap"
                style={{ width: "100%", height: "450px" }}
              ></div>
            </Col>
          </Row>

          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="leads"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                <Tab eventKey="leads" title="Intrested Leads"></Tab>
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
              </Tabs>
              {relatedListTasks && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}
                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={property.id}
                  table="property"
                  submitTasks={submitTasks}
                />
              )}
              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  Upload File
                </Button>
              )}
              {modalShowTaskfile && (
                <FilesCreate
                  show={modalShowTaskfile}
                  onHide={() => setModalShowFile(false)}
                  parent={property}
                  table="property"
                  submitfiles={submitfiles}
                />
              )}
            </Card.Header>
            <Card.Body>
              {property && property.id && relatedLeads && (
                <RelatedLead parent={property} />
              )}
              {relatedListTasks ? (
                <RelatedListTask
                  refreshTaskList={refreshTaskList}
                  parent={property}
                />
              ) : (
                ""
              )}
              {relatedListFiles ? (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={property}
                  table="property"
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
          <EmailComposer
            size="lg"
            show={modalShowEmail}
            onHide={() => setModalShowEmail(false)}
            parentid={property?.id}
            toEmail={property?.email}
            table="property"
            submitTasks={submitTasks}
          />
          {showTransactionModel && (
            <PropertySaleEdit
              show={showTransactionModel}
              onHide={() => setShowTransactionModel(false)}
              parentid={property.id}
              parentName={property.name}
              eventRec={transaction}
              table="user"
              type={"Income"}
              submitEvents={submitTransaction}
            ></PropertySaleEdit>
          )}
        </Container>
      )}
    </div>
  );
};

export default PropertyView;
