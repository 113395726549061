import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useRef,
  } from "react";
  import { Button, Col, Row, Table } from "react-bootstrap";
  import { Link } from "react-router-dom";
  import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader, } from "react-bs-datatable";
  import inventoryApi from "../api/inventoryApi";
  import moment from "moment";
  import { useNavigate } from "react-router-dom";
  import { useLocation } from "react-router-dom";
  import Confirm from "./Confirm";
  import PropertyEditOnClick from "./PropertyEditOnClick";
  
  const RealetedProperty = ({ parent, refreshPatientTestsList }) => {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = React.useState(false);
    const [reletedProperty, setReletedProperty] = React.useState("");
    const [modalShowPatientTests, setModalShowPatientTests] =
      React.useState(false);
    const [modalShowPatientTestsView, setModalShowPatientTestsView] =
      React.useState(false);
    const [modalShowProperty, setModalShowProperty] = useState(false);
    // Create table headers consisting of 4 columns.
    const [body, setBody] = useState([]);
    useEffect(() => {
      realetedPropertyList();
    }, []);
  
  
  
    const realetedPropertyList = () => {
      async function init() {
      console.log("parent.id... " , parent.id);
        let parentLeadList = await inventoryApi.findByOwnerId(
          parent.id
        );
        console.log('parentLeadList...', parentLeadList)
        if (parentLeadList && parentLeadList?.length > 0) {
          setBody(parentLeadList);
        } else {
          setBody([]);
        }
      }
      init();
    };
    const handleDelete = (row) => {
      setModalShow(true);
      setReletedProperty(row);
    };
    const editLead = (row) => {
      setModalShowProperty(true);
      console.log("Edit");
      setReletedProperty(row.row);
      console.log("Row " + JSON.stringify(row.row));
      navigate(`/properties/${row.row.id}/e`, { state: row.row });
    };
    const submitProperty = (row) => {
      setModalShowProperty(false);
      realetedPropertyList();
    };
  
    //   const labels = {
    //     beforeSelect: " "
    //   }
  
    const deleteProperty = async () => {
      const result = await inventoryApi.deleteProperty(reletedProperty.id);
      if (result.success) {
        setReletedProperty("");
        setModalShow(false);
        realetedPropertyList();
      }
    };
  
    //   const editPatientTests = (row) => {
    //     setModalShowPatientTests(true)
    //     setReletedProperty(row.row);
    //   }
  
    const header = [
      {
        title: "Name",
        prop: "name",
        isFilterable: true,
        cell: (row) => (
          <Link to={"/properties/" + row.id} state={row}>
            {row.name}
          </Link>
        ),
      },
     // { title: "Last Name", prop: "lastname" }, // cell: (row) => (moment(row.tdate).format('DD-MM-YYYY')) },
      { title: "Phone", prop: "phone" },
      { title: "Email", prop: "email" },
      { title: "City", prop: "city" },
      { title: "State", prop: "state" },
      { title: "Country", prop: "country" }, // cell: (row) => (row.totalamount - row.discount) },
  
      {
        title: "Actions",
        prop: "id",
        cell: (row) => (
          <>
            <Button className="btn-sm mx-2" onClick={() => editLead({ row })}>
              <i className="fa-regular fa-pen-to-square"></i>
            </Button>
            <Button
              className="btn-sm mx-2"
              variant="danger"
              onClick={() => handleDelete(row)}
            >
              <i className="fa-regular fa-trash-can"></i>
            </Button>
          </>
        ),
      },
    ];
  
    return (
      <>
        {modalShow && (
          <Confirm
            show={modalShow}
            onHide={() => setModalShow(false)}
            deleteProperty={deleteProperty}
            title="Confirm delete?"
            message="You are going to delete the record. Are you sure?"
            table="property"
          />
        )}
  
        {/* {modalShowProperty &&
           
          <PropertyEditOnClick
          show={modalShowProperty}
          onHide={() => setModalShowProperty(false)}
          parent={parent}
          reletedProperty={reletedProperty}
          submitProperty={submitProperty}
        /> 
          } */}
  
        {body ? (
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 5,
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              ></Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              ></Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              ></Col>
            </Row>
            <Table striped className="related-list-table" responsive = "sm">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />
          </DatatableWrapper>
        ) : (
          ""
        )}
      </>
    );
  };
  
  export default RealetedProperty;
  


