import logo from "./logo.svg";

import "./App.css";
import "./Sidebar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import { AccordionCollapse, Container } from "react-bootstrap";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import React, { useEffect, useState } from "react";

import EditProfile from "./components/EditProfile";
import BackupList from "./components/BackupList";

import LeadView from "./components/LeadView";
import LeadEdit from "./components/LeadEdit";
import LeadList from "./components/LeadList";
import PropertyList from "./components/PropertyList";
import ContactList from "./components/ContactList";
import ContactView from "./components/ContactView";
import ContactEdit from "./components/ContactEdit";
import MiniSidebar from "./components/MiniSidebar";
import * as constants from "./constants/CONSTANT";
import PropertyView from "./components/PropertyView";
import PropertyEdit from "./components/PropertyEdit";
import ProjectView from "./components/ProjectView";
import ProjectEdit from "./components/ProjectEdit";
import ProjectList from "./components/ProjectList";
import ChangePassword from "./components/ChangePassword";
import ReportView from "./components/ReportView";
import ReportList from "./components/ReportList";
import Calendar from "./components/Calendar";
import SalePurchaseList from "./components/SalePurchaseList";
import PubSub from "pubsub-js";
import { Toast, ToastContainer } from "react-bootstrap";
import UserList from "./components/UserList";
import UserView from "./components/UserView";
import UserEdit from "./components/UserEdit";
import jwt_decode from "jwt-decode";

import Setting from "./components/Setting";
import Callback from "./components/callback";
import EMICalculator from "./components/EMICalculator";
import io from "socket.io-client";
// const socket = io.connect("http://localhost:3001");

const ENDPOINT = constants.SOCKET_BASE_URL;

function App() {
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState("Confirmation");
  const [message, setMessage] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [permissions, setPermissions] = useState();
  const [variant, setVariant] = useState("");
  const [connectedSocket, setConnectedSocket] = useState();

  const mySubscriber = (msg, data) => {
    switch (msg) {
      case "RECORD_SAVED_TOAST":
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant("success");
        //console.log('mytopic: RECORD_SAVED_TOAST'); // add your desired log message
        break;
      case "RECORD_ERROR_TOAST":
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant("danger");
        //console.log('mytopic: RECORD_ERROR_TOAST'); // add your desired log message
        break;
      default:
        //console.log('mytopic: default'); // add your desired log message
        break;
    }
  };
  useEffect(() => {
    PubSub.subscribe("RECORD_SAVED_TOAST", mySubscriber);
    PubSub.subscribe("RECORD_ERROR_TOAST", mySubscriber);

    try {
      if (localStorage.getItem("token")) {
        let user = jwt_decode(localStorage.getItem("token"));
        setUserInfo(user);

        var perm = user.permissions
          .map(function (obj) {
            return obj.name;
          })
          .join(";");
        setPermissions(perm);

        //socket
        console.log("ENDPOINT", ENDPOINT);

        let socket = io(ENDPOINT, {
          // note changed URL here
          path: "/socket.io",
          transports: ["websocket"],
        });
        
        // let token  = localStorage.getItem('token');
        // let socket =   io(ENDPOINT, {auth: {token : token}, transports: ['websocket'], upgrade: false});


        socket.on("connect_error", (err) => {
          console.log(`connect_error due to`, err);
        });
        console.log("socket", socket);
        socket.emit("setup", user);
        socket.on("connect", (msg) => {
          console.log("connected client");
          setConnectedSocket(socket);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <ToastContainer className="p-3" position="top-center">
        <Toast
          show={modalShow}
          onClose={() => setModalShow(false)}
          delay={3000}
          bg={variant}
          className="text-white"
          autohide
        >
          {variant === "success" ? (
            <div
              className="p-1 m-1"
              style={{ backgroundColor: "#198754", color: "white" }}
            >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i
                className="fa-solid fa-xmark text-white float-right"
                style={{ float: "right" }}
                role="button"
                onClick={() => setModalShow(false)}
              ></i>
            </div>
          ) : (
            <div
              className="p-1 m-1"
              style={{ backgroundColor: "#DC3545", color: "white" }}
            >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i
                className="fa-solid fa-xmark text-white float-right"
                style={{ float: "right" }}
                role="button"
                onClick={() => setModalShow(false)}
              ></i>
            </div>
          )}

          <Toast.Body>
            {message instanceof Array ? (
              message.map((item) => {
                return <span>{item.msg}</span>;
              })
            ) : message instanceof Object ? (
              <span>{message.detail}</span>
            ) : (
              <span>{message}</span>
            )}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header socket={connectedSocket} />
                    <Home />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/meetings"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <Calendar />
                  </div>
                </div>
              </>
            }
          />
          {/* add wazid */}
          <Route
            path="/meetings/:today"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <Calendar />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="orders/:id/pdf"
            element={
              <>
                <div className="wrapper">
                  <div id="content"></div>
                </div>
              </>
            }
          />

          {/******** Edit Profile *******/}
          <Route
            path="/myprofile"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <EditProfile />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/contacts"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {permissions &&
                    (permissions.indexOf(constants.VIEW_CONTACT) >= 0 ||
                      permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
                      <ContactList />
                    ) : (
                      "You have No permission for contact"
                    )}
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="contacts/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ContactView />
                  </div>
                </div>
              </>
            }
            P
          />

          <Route
            path="/leads"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {permissions &&
                    (permissions.indexOf(constants.VIEW_LEAD) >= 0 ||
                      permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
                      <LeadList />
                    ) : (
                      "You have no permission to access lead"
                    )}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/properties"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {permissions &&
                    (permissions.indexOf(constants.VIEW_PROPERTY) >= 0 ||
                      permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
                      <PropertyList />
                    ) : (
                      "You have no permission to access property"
                    )}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/projects"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {permissions &&
                    (permissions.indexOf(constants.VIEW_PROPERTY) >= 0 ||
                      permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
                      <ProjectList />
                    ) : (
                      "You have no permission to access projects"
                    )}
                  </div>
                </div>
              </>
            }
          />

          {/*
            <Route
                path="/changepassword"
                element={
                  <>
                    <div class="wrapper">
                      <Sidebar />
                      <div id="content">
                        <Header />
                      {userId && <ChangePassword userId={userId}/>} 
                      </div>
                    </div>
                  </>
                }
              />
        */}

          <Route
            path="/changepassword"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {permissions &&
                    (permissions.indexOf(constants.VIEW_CONTACT) >= 0 ||
                      permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
                      <ChangePassword />
                    ) : (
                      "You have No permission for contact"
                    )}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="properties/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <PropertyView />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="projects/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ProjectView />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/properties/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <PropertyEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/projects/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ProjectEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/properties/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <PropertyEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/projects/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ProjectEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="contacts/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ContactEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/leads/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/contacts/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ContactEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="leads/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadView />
                  </div>
                </div>
              </>
            }
          />

          {/* ************Backup ********************/}

          <Route
            path="/backup"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BackupList />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="leads/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="reports/:id"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ReportView />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/reports"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ReportList />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/users"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserList />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/users/:id"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserView />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/users/e"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserEdit />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/users/:id/e"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserEdit />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/transactions"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {permissions &&
                    (permissions.indexOf(constants.VIEW_CONTACT) >= 0 ||
                      permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
                      <SalePurchaseList />
                    ) : (
                      "You have No permission for contact"
                    )}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/settings"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {permissions &&
                    (permissions.indexOf(constants.VIEW_CONTACT) >= 0 ||
                      permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
                      <Setting companyid={userInfo.companyid} />
                    ) : (
                      "You have No permission for contact"
                    )}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/callback"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <Callback />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/emicalculator"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <EMICalculator />
                  </div>
                </div>
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
