import * as constants from "../constants/CONSTANT";
import authApi from "../api/authApi";

const inventoryApi = {
  //.............. Fetch Project By active Id .............................
  async findActiveProject(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/projects/" + id + "/active",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const result = await response.json();
    return result;
  },

  //.............. Fetch Property By showonweb Id .............................
  async findActiveProperty(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/properties/" + id + "/active",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const result = await response.json();
    return result;
  },

  //************************ Contacts ***********************************//
  async fetchContacts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  //.............. Fetch Contact By Id .............................
  async fetchContact(id) {
    console.log(id);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const result = await response.json();

    return result;
  },

  async createUser(user) {
    const token = localStorage.getItem("token");

    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/createuser",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(user),
      }
    );
    console.log("response", response);
    return await response.json();
  },

  async saveUser(user) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/" + user.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(user),
      }
    );
    //console.log('response',response)

    return await response.json();
  },
  async deleteMessage(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/messages/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return await response.json();
  },
  async createMessage(message) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/messages/", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(message),
    });

    return await response.json();
  },
  async fetchMessages(pid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(
      constants.API_BASE_URL + "/api/messages/" + pid + "/*",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      //console.log(result)
      return result;
    }
    return null;
  },

  async fetchUnreadMessages() {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(
      constants.API_BASE_URL + "/api/messages/unread",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      //console.log(result)
      return result;
    }
    return null;
  },
  async fetchUsers() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/users", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const result = await response.json();
    console.log("----->", JSON.stringify(result));

    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async createContact(contact) {
    //console.log('if contact create call');
    //console.log(contact);
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/contacts", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(contact),
    });
    console.log(response);
    return await response.json();
  },
  //.............. Fetch Lead By Id .............................
  async fetchUserById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/users/" + id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const result = await response.json();
    //console.log('response',result)
    return result;
  },

  /********************************** CReate Property*************************** */
  async createProperty(property) {
    //console.log('if contact create call');
    //console.log(property);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/properties", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(property),
    });
    //console.log(response);
    return await response.json();
  },

  /********************************** CReate Project*************************** */
  async createProject(project) {
    //console.log('if contact create call');
    //console.log(property);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/projects", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(project),
    });
    //console.log(response);
    return await response.json();
  },

  /********************************** Save Contact*************************** */

  async saveContact(contact) {
    console.log("====Save contact===");
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/contacts/" + contact.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(contact),
      }
    );
    console.log(response);
    return await response.json();
  },

  /********************************** Save Property*************************** */
  async saveProperty(property) {
    console.log("if edit save contact call");
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/properties/" + property.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(property),
      }
    );
    //console.log(response);
    return await response.json();
  },

  /********************************** Save Project*************************** */

  async saveProject(project) {
    console.log("if edit save contact call");
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/projects/" + project.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(project),
      }
    );
    //console.log(response);
    return await response.json();
  },

  /********************************** delete Contact*************************** */

  async deleteContact(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return await response.json();
  },

  /********************************** delete Project*************************** */

  async deleteProject(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/projects/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return await response.json();
  },

  //************************ Lead ***********************************//

  async fetchLeads() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchLead(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const result = await response.json();

    return result;
  },

  //************************ Prperty ***********************************//

  async fetchProperties() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/properties", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },

  //************************ Project ***********************************//

  async fetchProjects() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/projects", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },

  //************************ Lead ***********************************//

  async fetchTransactions() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/transactions", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async createTransaction(transaction) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/transactions", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(transaction),
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    return result;
  },

  /******************************** Property***************** */

  async fetchProperty(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/properties/" + id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },

  //----------------------------------ReletedProperties-----------------------------------
  async findByOwnerId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/projects/" + id + "/properties",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const result = await response.json();
    return result;
  },

  /******************************** Project***************** */

  async fetchProject(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/projects/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },

  async createLead(lead) {
    //console.log('if data enter', lead);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(lead),
    });
    //console.log(response)
    return await response.json();
  },
  // ******************************create product ***************************************
  async createProduct(product) {
    //console.log('if product create call');
    //console.log(product);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(product),
    });
    //console.log(response);
    return await response.json();
  },

  async saveLead(lead) {
    //console.log('Enter lead data',lead)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/leads/" + lead.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(lead),
      }
    );
    //console.log(response)
    return await response.json();
  },

  //*************************************save Product********************************************* */
  async saveProduct(product) {
    //console.log('if edit enter for save')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/products/" + product.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(product),
      }
    );
    //console.log(response)
    return await response.json();
  },
  // ***********************Product************************************//
  async fetchProduct() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const result = await response.json();
    //console.log(result)
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async deleteProduct(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  },

  async deleteLead(id) {
    //console.log('delete enter in inventory')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    //console.log(response)
    return await response.json();
  },
  //************** order **************** */
  async deleteOrder(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return await response.json();
  },

  async fetchOrder() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/orders", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async deletOrder(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/orders/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    //console.log(response)
    return await response.json();
  },

  async createOrder(order) {
    //console.log('order enter to create in inventory')
    const token = localStorage.getItem("token");
    //console.log(order)
    let response = await fetch(constants.API_BASE_URL + "/api/orders", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(order),
    });
    //console.log(response)
    return await response.json();
  },

  async fetchOrderById(row) {
    //console.log('order enter to show data in pdf with row.id')
    const token = localStorage.getItem("token");
    //console.log(row)
    let response = await fetch(
      constants.API_BASE_URL + "/api/orders/" + row.id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    //console.log(response)

    return await response.json();
  },

  //************************ Task ***********************************//
  async fetchTasks(pid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/" + pid + "/*",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      //console.log(result)
      return result;
    }
    return null;
  },
  async fetchAllMeetings(today) {
    const token = localStorage.getItem("token");

    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/meetings/today",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async createTask(task) {
    const token = localStorage.getItem("token");
    //console.log('task:', task);
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(task),
    });

    return await response.json();
  },

  async saveTask(task) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/" + task.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(task),
      }
    );

    return await response.json();
  },

  async deleteTask(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return await response.json();
  },

  async createFile(pid, formData) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + pid, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: token,
      },
      body: formData,
    });

    return await response.json();
  },

  //************************ files ***********************************//

  async fetchFiles(pid) {
    //console.log(pid)
    const token = localStorage.getItem("token");
    //

    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + pid + "/all",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    const result = await response.json();
    //console.log('result',result);
    //console.log(response)
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async downloadFiles(file) {
    //console.log("save",file.id);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + file.id + "/download",
      {
        method: "GET",
        //mode: "cors",

        headers: {
          Authorization: token,
        },
      }
    );
    const fileBody = await response.blob();

    return fileBody;
  },

  async saveFiles(file) {
    //console.log("save",file);

    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + file.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(file),
      }
    );

    return await response.json();
  },

  async deleteFile(id) {
    //console.log('delete call',id)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return await response.json();
  },

  async deleteProperty(id) {
    //console.log('delete call',id)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/properties/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    return await response.json();
  },

  async fetchPurchaseOrder() {
    //console.log('enter to fetch purchase order')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/purchaseorders", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const result = await response.json();
    //console.log(result)
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async createPurchaseOrder(purchase) {
    //console.log('purchase enter to create in order')
    const token = localStorage.getItem("token");
    //console.log(purchase)
    let response = await fetch(constants.API_BASE_URL + "/api/purchaseorders", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(purchase),
    });
    //console.log(response)
    return await response.json();
  },

  async savePurchaseOrder(purchase) {
    const token = localStorage.getItem("token");
    //console.log(purchase);
    let response = await fetch(
      constants.API_BASE_URL + "/api/purchaseorders/" + purchase.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(purchase),
      }
    );
    //console.log(response)
    return await response.json();
  },

  async deletePurchaseOrder(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/purchaseorders/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    //console.log(response)
    return await response.json();
  },

  async updateUser(user) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      //constants.API_BASE_URL + "/api/auth/" + user.id,
      constants.API_BASE_URL + "/api/auth/updatepassword",
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(user),
      }
    );

    return await response.json();
  },

  // --------------------------------- Report ----------------------------------------

  async fetchReports() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async sendEmailTask(task) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/sendemail",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(task),
      }
    );

    return await response.json();
  },

  async fetchReportsById(row) {
    const token = localStorage.getItem("token");
    console.log(row);
    let response = await fetch(
      constants.API_BASE_URL + "/api/reports/" + row.id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log(response);
    return await response.json();
  },

  async fetchCompanyInfoById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/company/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // console.log("response  @@" , response);
    return await response.json();
  },
  async fetchJobByType(type) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/jobs/type/" + type, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // console.log("response  @@" , response);
    return await response.json();
  },
  
  async syncSFRecords(request_body) {
    console.log("request_body ", request_body);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/salesforce/sf/syncrecord",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(request_body),
      }
    );
    return await response.json();
  },

  async fetchAndSaveSalesforceToken(code, companyid) {
    const token = localStorage.getItem("token");
    let request_body = {
      code: code,
    };
    let response = await fetch(
      constants.API_BASE_URL + "/api/salesforce/" + companyid,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(request_body),
      }
    );
    return await response.json();
  },

  async fetchFilespic(pid, type) {
    console.log(pid);
    const token = localStorage.getItem("token");
    //

    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + pid + "/projects/plan/" + type,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    const result = await response.json();
    console.log("resultfetchFilespic", result);
    //console.log(response)
    if (result) {
      return result;
    }
    return null;
  },

  //Added by rahul joshi : 17-08-2023
  async fetchCountOfProperties() {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/dashboard/totalproject/",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    const result = await response.json();
    return result;
  },

  //Added by rahul joshi : 17-08-2023
  async fetchCountOfContacts() {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/dashboard/totalcontacts/",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    const result = await response.json();
    return result;
  },
  //Added by rahul joshi : 17-08-2023
  async fetchCountOfLeads() {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/dashboard/totalleads/",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    const result = await response.json();
    return result;
  },

  //Added by rahul joshi : 17-08-2023
  async fetchTotalIncome() {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/dashboard/totalincome/",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    const result = await response.json();
    return result;
  },

  async getLoginUserData() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/getuser", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const result = await response.json();
    //console.log('----->', JSON.stringify(result))

    return result;
  },

  async saveStaffMemberEditProfile(userid, selectedFiles, staffMember) {
    //console.log("Enter staff data", userid);
    //console.log('selectedFiles',selectedFiles)
    const formData = new FormData();
    formData.append("file", selectedFiles);
    formData.append("staffRecord", staffMember);
    //console.log([...formData])
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/" + userid + "/profile",
      {
        method: "PUT",
        mode: "cors",
        headers: {
          Authorization: token,
        },
        body: formData,
      }
    );
    //console.log(response);
    return await response.json();
  },
  async createBackup() {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/backup", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      // body: JSON.stringify(lead),
    });
    return await response.json();
  },

  async fetchBackups() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/backup", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async downloadBackupFile(filename) {
    //console.log("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/backup/download/" + filename,
      {
        method: "GET",
        //mode: "cors",

        headers: {
          Authorization: token,
        },
      }
    );
    const fileBody = await response.blob();
    console.log("filebody in api ", fileBody);
    return fileBody;
  },

  async deleteBackupFile(filename) {
    //console.log("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/backup/delete/" + filename,
      {
        method: "GET",
        //mode: "cors",

        headers: {
          Authorization: token,
        },
      }
    );
    return await response.json();
  },

  async fetchReportByName(reportname) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/reports/byname/" + reportname,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async download3DFiles(fileid) {
    //console.log("save",file.id);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + fileid + "/3dfile/download",
      {
        method: "GET", //mode: "cors",
        headers: {
          Authorization: token,
        },
      }
    );
    const fileBody = await response.blob();
    return fileBody;
  },

  async fetchTasksWithoutParent() {
    const token = localStorage.getItem("token");

    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/opentasks",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async savetransactionRec(transaction) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/transactions/" + transaction.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(transaction),
      }
    );
    //console.log(response);
    return await response.json();
  },

  //----------------------------------ReletedLeads-----------------------------------
  async findByLeadId(id) {
    console.log("idasasdsa", id);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/properties/" + id + "/relatedleads/",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const result = await response.json();
    return result;
  },

  async markReadMessage(notificationid) {
    console.log('===>>>> mark read message')
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/messages/markread/" + notificationid, {
      method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        }
    });
    console.log('markReadMessage' , response)
    const result = await response.json();
    console.log("markReadMessage" , result);
    if (result.length > 0) {
      console.log("markReadMessage" , result)
      return result;
    }
    return null;

  },


  async deletePushNotification(notificationid) {
    console.log('===>>>>  delete push notification')
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/messages/markdelete/" + notificationid, {
      method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        }
    });
    console.log("deletePushNotification response", response)
    const result = await response.json();
    console.log("deletePushNotification result", result);
    if (result.length > 0) {
      console.log("deletePushNotification result", result)
      return result;
    }
    return null;

  },


  async fetchSystemNotifications(staffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/notifications", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    console.log('fetchSystemNotifications result', result)
    if (result && result.length > 0) {
      return result;
    }
    return null;

  },


  async createUserMsgAlert(msgList) {
    console.log('===>>>> create user msg alert')
    console.log('===>>>> createUserMsgAlert==>', msgList)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/messages/usermsg",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(msgList),

      });
    console.log('response.json()==>', response.json())
    return await response;
  },

  
};
export default inventoryApi;
