import React, { useState, useEffect } from "react";

import { Button, Card, Col, Container, Row, useParams } from "react-bootstrap";
import Confirm from "./Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RelatedListTask from "./RelatedListTask";
import inventoryApi from "../api/inventoryApi";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TaskEdit from "./TaskEdit";
import Path from "./common/Path";
import Chat from "./common/Chat";
import EmailComposer from "./common/EmailComposer";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";

const LeadView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [lead, setLead] = useState(location.state ? location.state : {});
  const [leadStatusArray, setleadStatusArray] = useState(
    JSON.parse(localStorage.getItem("lead_status"))
  );

  console.log("lead---->>>", JSON.stringify(lead));
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [relatedListTasks, setRelatedListTasks] = useState(true);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [refreshTaskList, setRefreshTaskList] = useState(Date.now());

  useEffect(() => {
    fetchLead();
  }, []);

  const fetchLead = () => {
    // Coming from Email
    console.log("from url lead id: ", location);
    if (
      !lead.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      lead.id = location.pathname.split("/")[2];
      setRefreshFileList(Date.now());
      setRefreshTaskList(Date.now());
    }

    console.log("lead id - ", lead.id);
    async function initLead() {
      let result = await inventoryApi.fetchLead(lead.id);
      console.log("result==123=>:", result);
      if (result) {
        setLead(result);
      } else {
        setLead({});
      }
    }
    initLead();
  };

  const deleteLead = async () => {
    const result = await inventoryApi.deleteLead(lead.id);
    if (result.success) navigate(`/leads`);
  };

  const editLead = () => {
    navigate(`/leads/${lead.id}/e`, { state: lead });
  };

  const submit = () => {
    setModalShowTask(false);
    navigate(`/leads/${lead.id}`, { state: lead });
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setModalShowEmail(false);

    setRefreshTaskList(Date.now());
  };
  const submitfiles = () => {
    setModalShowFile(false);

    setRefreshFileList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setRelatedListTasks(true);
      setRelatedListFiles(false);
    } else if (key === "files") {
      setRelatedListTasks(false);
      setRelatedListFiles(true);
    }
  };
  return (
    <div>
      <Container>
        <CustomSeparator
          cmpListName="Leads"
          currentCmpName={lead.firstname + " " + lead.lastname}
          indexLength="2"
          url="/leads"
        ></CustomSeparator>
      </Container>

      {lead && (
        <Container className="mt-4">
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteLead={deleteLead}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="lead"
            />
          )}
          <Row className="view-form gx-5 mx-auto">
            <Col lg={8}>
              <Row className="view-form-header align-items-center">
                <Col lg={3}>
                  Lead
                  <h4>{lead.firstname + " " + lead.lastname}</h4>
                </Col>
                <Col lg={9} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editLead(true)}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </Button>
                  <Button
                    className="float-right btn-sm mx-2"
                    onClick={() => setModalShowEmail(true)}
                    variant="success"
                  >
                    <i class="fa-regular fa-envelope mx-2"></i>
                    Send Email
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
              <Row
                className="py-3 ibs-edit-form"
                style={{ backgroundColor: "#fff" }}
              >
                <Col lg={12}>
                  <Path values={leadStatusArray} selectedValue={lead.status} />
                </Col>
                <Col lg={6}>
                  <label>Name</label>
                  <span>
                    {lead.salutation} {lead.firstname} {lead.lastname}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Title</label>
                  <span>{lead.title}</span>
                </Col>
                <Col lg={6}>
                  <label>Status</label>
                  <span>{lead.status}</span>
                </Col>
                <Col lg={6}>
                  <label>Industry</label>
                  <span>{lead.industry}</span>
                </Col>
                <Col lg={6}>
                  <label>Lead Source</label>
                  <span>{lead.leadsource}</span>
                </Col>
                <Col lg={6}>
                  <label>Intrested Property</label>
                  <span>
                    <Link to={`/properties/${lead.propertyid}`}>
                      {lead.propertyname}
                    </Link>
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Intrested Location</label>
                  <span>{lead.location}</span>
                </Col>
                <Col lg={6}>
                  <label>Budget</label>
                  <span>{lead.budget}</span>
                </Col>
                <Col lg={6}>
                  <label>Email</label>
                  <span>{lead.email}</span>
                </Col>
                <Col lg={6}>
                  <label>Phone</label>
                  <span>{lead.phone}</span>
                </Col>
                <Col lg={6}>
                  <label>Company</label>
                  <span>{lead.company}</span>
                </Col>
                <Col lg={6}>
                  <label>Street</label>
                  <span>{lead.street}</span>
                </Col>
                <Col lg={6}>
                  <label>City</label>
                  <span>{lead.city}</span>
                </Col>
                <Col lg={6}>
                  <label>State</label>
                  <span>{lead.state}</span>
                </Col>
                <Col lg={6}>
                  <label>Pincode</label>
                  <span>{lead.pincode}</span>
                </Col>
                <Col lg={6}>
                  <label>Country</label>
                  <span>{lead.country}</span>
                </Col>
                <Col lg={6}>
                  <label>Assigned Staff</label>
                  <span>
                    <Link to={`/users/${lead.ownerid}`}>{lead.ownername}</Link>
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Created By</label>
                  <span>{lead.createdbyname}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={4}>
              <Chat parentid={lead.id} />
            </Col>
          </Row>
          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="tasks"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                <Tab eventKey="tasks" title="Tasks"></Tab>
                <Tab eventKey="files" title="Files"></Tab>
              </Tabs>
              {relatedListTasks && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}
                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={lead.id}
                  table="lead"
                  submitTasks={submitTasks}
                />
              )}
              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  Upload File
                </Button>
              )}
              {modalShowTaskfile && (
                <FilesCreate
                  show={modalShowTaskfile}
                  onHide={() => setModalShowFile(false)}
                  parent={lead}
                  table="lead"
                  submitfiles={submitfiles}
                />
              )}
            </Card.Header>
            <Card.Body>
              {relatedListTasks ? (
                <RelatedListTask
                  parent={lead}
                  refreshTaskList={refreshTaskList}
                />
              ) : (
                ""
              )}
              {relatedListFiles ? (
                <RelatedListFiles
                  parent={lead}
                  refreshFileList={refreshFileList}
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
          <EmailComposer
            size="lg"
            show={modalShowEmail}
            onHide={() => setModalShowEmail(false)}
            parentid={lead?.id}
            toEmail={lead?.email}
            table="lead"
            submitTasks={submitTasks}
          />
        </Container>
      )}
    </div>
  );
};

export default LeadView;
