import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import * as constants from "../constants/CONSTANT";
import jwt_decode from "jwt-decode";

import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(true);
  const [permissions, setPermissions] = useState();
  const [userInfo, setUserInfo] = useState({});

  const location = useLocation();
  useEffect(() => {
    try {
      if (localStorage.getItem("token")) {
        let user = jwt_decode(localStorage.getItem("token"));
        console.log("user:", user);
        setUserInfo(user);

        var perm = user.permissions
          .map(function (obj) {
            return obj.name;
          })
          .join(";");
        console.log("perm:", perm);
        setPermissions(perm);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <>
      <nav id="sidebar" className="">
        <div className="sidebar-header text-center">
          <div className="pb-1">
            <img src={userInfo.sidebarbgurl} style={{ width: "90%" }} />
          </div>
        </div>

        <ul
          className="list-unstyled components"
          style={{ borderTop: "1px solid #ddd" }}
        >
          <li>
            <Link to="/" /*className="dropdown-toggle"*/>
              {" "}
              <i className="fa-solid fa-house mx-2"></i> Home
            </Link>
          </li>
          <li>
            {/* <Link to="/"> <i className="fa-solid fa-chart-simple mx-2"></i> Accounts</Link> */}
          </li>
          {permissions &&
          (permissions.indexOf(constants.VIEW_LEAD) >= 0 ||
            permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
            <li
              className={`${
                location.pathname.includes("/leads") ? "active" : ""
              }`}
            >
              <Link to="/leads">
                {" "}
                <i className="fa-solid fa-ear-listen mx-2"></i> Leads
              </Link>
            </li>
          ) : (
            ""
          )}
          {permissions &&
          (permissions.indexOf(constants.VIEW_CONTACT) >= 0 ||
            permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
            <li
              className={`${
                location.pathname.includes("/contacts") ? "active" : ""
              }`}
            >
              <Link to="/contacts">
                {" "}
                <i className="fa-solid fa-address-book mx-2"></i> Contacts
              </Link>
            </li>
          ) : (
            ""
          )}

          {permissions &&
          (permissions.indexOf(constants.VIEW_PROPERTY) >= 0 ||
            permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
            <li
              className={`${
                location.pathname.includes("/projects") ? "active" : ""
              }`}
            >
              <Link to="/projects">
                {" "}
                <i className="fa-solid fa-city mx-2"></i> Projects
              </Link>
            </li>
          ) : (
            ""
          )}

          {permissions &&
          (permissions.indexOf(constants.VIEW_PROPERTY) >= 0 ||
            permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
            <li
              className={`${
                location.pathname.includes("/properties") ? "active" : ""
              }`}
            >
              <Link to="/properties">
                {" "}
                <i className="fa-solid fa-building mx-2"></i> Properties
              </Link>
            </li>
          ) : (
            ""
          )}


          {permissions &&
          (permissions.indexOf(constants.VIEW_PROPERTY) >= 0 ||
            permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
            <li
              className={`${
                location.pathname.includes("/emicalculator") ? "active" : ""
              }`}
            >
              <Link to="/emicalculator">
                {" "}
                <i className="fa-solid fa fa-calculator mx-2"></i> Calculate EMI
              </Link>
            </li>
          ) : (
            ""
          )}

          {/* {permissions && (permissions.indexOf(constants.VIEW_PRODUCT) >= 0 
                                                      || permissions.indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/products"> <i className="fa-solid fa-chart-simple mx-2"> </i> Products</Link>
                </li>
                : ''} */}

          {permissions &&
          (permissions.indexOf(constants.VIEW_CONTACT) >= 0 ||
            permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
            <li
              className={`${
                location.pathname.includes("/transactions") ? "active" : ""
              }`}
            >
              <Link to="/transactions">
                <i className="fa-solid fa-cart-shopping mx-2"></i> Income /
                Expense
              </Link>
            </li>
          ) : (
            ""
          )}

          {permissions &&
          (permissions.indexOf(constants.VIEW_CONTACT) >= 0 ||
            permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
            <li
              className={`${
                location.pathname.includes("/meetings") ? "active" : ""
              }`}
            >
              <Link to="/meetings">
                {" "}
                <i class="fa-solid fa-calendar-days mx-2"> </i> Meetings
              </Link>
            </li>
          ) : (
            ""
          )}

          {permissions &&
          (permissions.indexOf(constants.VIEW_CONTACT) >= 0 ||
            permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
            <li
              className={`${
                location.pathname.includes("/users") ? "active" : ""
              }`}
            >
              <Link to="/users">
                {" "}
                <i class="fa-solid fa-user mx-2"> </i> Users
              </Link>
            </li>
          ) : (
            ""
          )}

          {permissions &&
          (permissions.indexOf(constants.VIEW_CONTACT) >= 0 ||
            permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
            <li
              className={`${
                location.pathname.includes("/reports") ? "active" : ""
              }`}
            >
              <Link to="/reports">
                {" "}
                <i class="fa-solid fa-chart-simple mx-2"> </i> Reports
              </Link>
            </li>
          ) : (
            ""
          )}

          <li
            className={`${
              location.pathname.includes("/myprofile") ? "active" : ""
            }`}
          >
            <Link to="/myprofile">
              {" "}
              <i className="fa fa-user-circle mx-2"></i>My Profile
            </Link>
          </li>

          {permissions &&
          (permissions.indexOf(constants.VIEW_CONTACT) >= 0 ||
            permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
            <li
              className={`${
                location.pathname.includes("/backup") ? "active" : ""
              }`}
            >
              <Link to="/backup">
                {" "}
                <i class="fa-solid fa-database mx-2"></i> Backup
              </Link>
            </li>
          ) : (
            ""
          )}

          {/* {permissions &&
          (permissions.indexOf(constants.VIEW_CONTACT) >= 0 ||
            permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
            <li
              className={`${
                location.pathname.includes("/changepassword") ? "active" : ""
              }`}
            >
              <Link to="/changepassword">
                <i className="fa-solid fa-key mx-2"></i>Change Password
              </Link>
            </li>
          ) : (
            ""
          )} */}

          {/* <li className={`${location.pathname.includes('/settings') ? 'active' : ''}`}>
                <Link to="/settings"><i className="fa-solid fa-cog mx-2"></i>Settings</Link>
                </li> */}

          {/* <li>
                  <div className='mx-4' style={{position:"fixed",bottom:"1rem", color: "#fff", textAlign : "center"}}>
                  <h5>ESTATE MANAGER</h5>
                  <img style={{width: "150px", paddingTop: "1rem"}} src="/indicrm-white.png" />
                  </div>
                  </li> */}

          <li>
            <div
              className="mx-4 mt-4"
              style={{  color: "#fff", textAlign: "center" }}
            >
              <img
                style={{ width: "150px", paddingTop: "1rem" }}
                src="/sidebar.png"
              />
            </div>
          </li>
          {/* {permissions && (permissions.indexOf(constants.VIEW_ORDER) >= 0 
                                                      || permissions.indexOf(constants.MODIFY_ALL) >= 0 ) ?
                <li>
                <Link to="/orders"> <i className="fa-solid fa-chart-simple mx-2"> </i> Order</Link> 
                </li>:''} */}
          {/* <li>
                <Link to="/purchaseorder"> <i className="fa-solid fa-chart-simple mx-2"> </i> Purchase Order</Link>
                </li> */}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
