import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import PubSub from "pubsub-js";
import moment from "moment/moment";

import CityState from "../constants/CityState.json";
import Select from "react-select";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";

const ProjectEdit = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [active, setActive] = useState(
    location?.state?.isactive ? true : false
  );

  const [state, setState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const [selectedContact, setSelectedContact] = useState();
  const [optionContact, setOptionContact] = useState({});
  const [option, setoption] = useState();

  console.log("location.state", location.state);

  //const { accounts } = useSelector((state) => state.api);

  /*useEffect(() => {
      let fetchedAccounts = [];
      accounts.map((item) => {
        fetchedAccounts.push({
          label: item.firstname,
          population: 4780127,
          capital: "Montgomery",
          region: "South",
        });
      });
      setOptions(fetchedAccounts);
    }, [accounts]);*/

  const [project, setProject] = useState(location.state ? location.state : {});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target.value;
    const newValue = type === "checkbox" ? checked : value;
    if (e.target.name === "vidurl") {
      let arr = e.target.value.split("/");
      let vid = arr[arr.length - 1];
      console.log(arr);
      setProject({
        ...project,
        [e.target.name]: "https://www.youtube.com/embed/" + vid,
      });
    } else {
      setProject({ ...project, [e.target.name]: e.target.value });
    }
  };

  const [contactList, setContactList] = useState([]);
  const [defaultContact, setDefaultContact] = useState([]);

  const [ownerList, setownerList] = useState([]);
  const [defaultOwner, setDefaultOwner] = useState([]);
  useEffect(() => {
    async function init() {
      // Fetch Owners from user
      const result = await inventoryApi.fetchUsers();
      if (result) {
        setownerList(result);
        if (project.id) {
          console.log("edit case", project);
          setDefaultOwner([
            { id: project.ownerid, username: project.ownername },
          ]);
        } else {
          console.log("create  case");
          setDefaultOwner([{ id: "", username: "" }]);
        }
      }

      // Fetch Contacts
      const conResult = await inventoryApi.fetchContacts();
      console.log(project);
      if (conResult) {
        setContactList(conResult);
        if (project.id) {
          console.log("edit case", project);
          setDefaultContact([
            { id: project.contactid, contactname: project.contactname },
          ]);
        } else {
          console.log("create  case");
          setDefaultContact([{ id: "", contactname: "" }]);
        }
      }

      let contactList;
      contactList = await inventoryApi.fetchContacts();
      console.log("accountList--> ", contactList);
      if (contactList && contactList.length) {
        console.log("accountList--> ", contactList);
        let ar = [];
        contactList.map((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.firstname + " " + item.lastname;
          ar.push(obj);
          console.log("obj--> ", obj);
        });
        if (location?.state) {
          console.log("ar", ar);
          console.log("location.state.contactid", location.state);
          let acc = ar.filter((acc) => acc.value === location.state.contactid);
          console.log("acc", acc);
          setoption(acc);
        }
        setOptionContact(ar);
      } else {
        setOptionContact([]);
      }
    }

    init();
    let defaultValue = location?.state?.state
      ? location?.state?.state
      : "Rajasthan";
    let st = [];
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === defaultValue) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
      var state = {};
      obj.value = obj.state;
      obj.label = obj.state;
      ////console.log(" obj.label >"+ obj.label)
      st.push(obj);
    });
    setCities(filteredCities);

    let finalStates = {};
    st = st.filter(function (currentObject) {
      if (currentObject.value in finalStates) {
        return false;
      } else {
        finalStates[currentObject.value] = true;
        return true;
      }
    });
    //console.log('st:', st);
    setState(st);

    if (location?.state) {
    } else {
      setProject({
        ...project,
        city: "Ajmer",
        state: "Rajasthan",
        country: "India",
      });
    }
  }, []);

  const handleOwnerName = (event) => {
    if (event.length > 0) {
      setProject({ ...project, ownerid: event[0].id });
    } else {
      setProject({ ...project, ownerid: "" });
    }
  };

  const handleContactName = (event) => {
    console.log("handle contact name", event.length);
    if (event.length > 0) {
      console.log("--" + event[0].id);
      setProject({ ...project, contactid: event[0].id });
    } else {
      setProject({ ...project, contactid: "" });
    }
  };

  const checkRequredFields = () => {
    console.log(project.name);
    //    console.log(project.phone);
    //    console.log(project.type);
    //    console.log(project.ownerid);
    console.log(project.contactid);

    if (
      project.name &&
      project.name.trim() !== ""
      //    && (project.phone && project.phone.trim() !== '') &&
      //       (project.type && project.type.trim() !== '') && (project.ownerid && project.ownerid.trim() !== '') &&
      //       (project.contactid && project.contactid.trim() !== '')
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    console.log("====handle submit====");
    e.preventDefault();

    project.isactive = active;
    const projectWithActiveProduct = await inventoryApi.findActiveProject(
      project.id
    );
    // if (project?.id) {
    //   if (projectWithActiveProduct !== null && projectWithActiveProduct?.id &&
    //     project?.id !== projectWithActiveProduct.id && project.active === true) {
    //     //PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: 'Already exists active project' });
    //     PubSub.publish('RECORD_SAVED_TOAST', {title: 'Record Saved', message: 'Record saved successfully'});
    //            navigate(`/projects/${project.id}`, { state: project });
    //   } else {
    //     const result = await inventoryApi.saveProject(project);
    //   }
    //   navigate(`/projects/${project.id}`, { state: project });
    // } else {
    //     let result;
    //   if (projectWithActiveProduct !== null && projectWithActiveProduct?.id && project.active === true) {
    //    // PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: 'Already exists active project' });
    //     PubSub.publish('RECORD_SAVED_TOAST', {title: 'Record Saved', message: 'Record saved successfully'});
    //              navigate(`/projects/${result.id}`, { state: result });
    //   } else {
    //     result = await inventoryApi.createProject(project);
    //   }
    //   navigate(`/projects/${result.id}`, { state: result });
    // }

    if (checkRequredFields()) {
      setValidated(true);
      return;
    }

    let result = {};
    console.log("project: ", JSON.stringify(project));

    if (project.id) {
      console.log("====edit-====");
      result = await inventoryApi.saveProject(project);
      if (result.success) {
        console.log("if result true");
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/projects/${project.id}`, { state: project });
      }
    } else {
      console.log("====create====");
      result = await inventoryApi.createProject(project);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/projects/${result.id}`, { state: result });
      }
    }

    console.log("result:", result);
  };

  const handleCancel = () => {
    if (project.id) navigate("/projects/" + project.id, { state: project });
    else navigate("/projects");
  };

  const handleState = (e) => {
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    setCities(filteredCities);
    setProject({ ...project, state: e.value, city: "" });
  };

  const handleSelectListChange = (value, name) => {
    //console.log('contact:', lead);
    setProject({ ...project, [name]: value.value });
    setSelectedCity(value.value);
  };

  const handleContact = (e) => {
    console.log("e", e);
    setoption(e);
    // setProperty({ ...property, projecttid: e.value, projectname: e.label });
    setProject({ ...project, contactid: e.value });
  };

  return (
    <Container className="view-form">
      {location?.state?.id ? (
        <CustomSeparator
          cmpListName="Projects"
          cmpViewName={project.name}
          currentCmpName="Edit"
          indexLength="2"
          indexViewLength="3"
          cmpViewUrl={"/projects/" + project.id}
          url="/projects"
        ></CustomSeparator>
      ) : (
        <CustomSeparator
          cmpListName="Projects"
          currentCmpName="Create"
          indexLength="2"
          url="/projects"
        ></CustomSeparator>
      )}
      <Row className="mt-4">
        <Col></Col>
        <Col lg={8} className="ibs-form-section">
          <Form
            className="mt-3"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                {project.id ? (
                  <>
                    Edit Project
                    <h4>{project.name}</h4>
                  </>
                ) : (
                  "New Project"
                )}
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicFirstName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    value={project.name}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
              {/* <Col lg={6}>
                            <Form.Group className="mx-3" controlId="formBasicLastName">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLastName"
                                    >
                                        Project Code
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="code"
                                        placeholder="Enter code"
                                        value={project.code}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                </Col> */}
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicStreet">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Budget
                  </Form.Label>
                  <Form.Control
                    style={{ height: "37px" }}
                    type="number"
                    name="budget"
                    placeholder="Enter Budget"
                    value={project.budget}
                    onChange={handleChange}
                  />
                  {console.log("budget cost => ", project.budget)}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicPhone">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPhone"
                  >
                    Start Date
                  </Form.Label>
                  <Form.Control
                    //required
                    style={{ height: "37px" }}
                    type="date"
                    name="startdate"
                    placeholder="Enter Start Date"
                    value={moment(project.startdate).format("YYYY-MM-DD")}
                    onChange={handleChange}
                  />
                  {console.log(
                    "project.startdate => ",
                    moment(project.startdate).format("MM/DD/YYYY")
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicPhone">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPhone"
                  >
                    End Date
                  </Form.Label>
                  <Form.Control
                    //required
                    style={{ height: "37px" }}
                    type="date"
                    name="enddate"
                    placeholder="Enter End Date"
                    value={moment(project.enddate).format("YYYY-MM-DD")}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              {/* <Col>
                                <Form.Group className="mx-3" controlId="formBasicEmail">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicEmail"
                                    >
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        placeholder="Enter email"
                                        value={project.email}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col> */}
              {/* <Col>
                                <Form.Group  className="mx-3" controlId="formBasicPhone">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicPhone"
                                    >
                                        Phone
                                    </Form.Label>
                                    <Form.Control 
                                        required
                                        type="phone"
                                        name="phone"
                                        placeholder="Enter phone"
                                        value={project.phone}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col> */}
            </Row>
            <Row>
              {/* <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicType">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicTitle"
                                    >
                                        Type
                                    </Form.Label>
                                    <Form.Select
                                        required
                                        aria-label="Enter Payment Status"
                                        name="type"
                                        value={project.type}
                                        onChange={handleChange}
                                    >
                                        <option value=''>--Select--</option>
                                        <option value="Flat">Flat </option>
                                        <option value="Villa">Villa</option>
                                        <option value="Plot">Plot</option>
                                        <option value="Land">Land</option>
                                        <option value="House">House</option>
                                        <option value="Shop">Shop</option>                         
                                        <option value="Apartment">Apartment </option>               
                                        <option value="Commercial Space">Commercial Space</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col> */}

              {/* <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicArea">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicTitle"
                                    >
                                        Total Area
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="area"
                                        placeholder="Enter area"
                                        value={project.area}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col> */}
              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Project Contact Name
                  </Form.Label>
                  <Select
                    placeholder="Contact Name... "
                    value={option}
                    onChange={handleContact}
                    options={optionContact}
                    //value={salutationValue}
                  ></Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide Contact Name.
                  </Form.Control.Feedback>
                  {console.log("project.handleContact => ", handleContact)}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCountry">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCountry"
                  >
                    Video Url
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="vidurl"
                    placeholder="Enter Video Url"
                    value={project.vidurl}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label">Active</Form.Label>

                  <Form.Check
                    defaultChecked={active} // if checkbox should be checked by default
                    // defaultValue="yes" // use this instead of value if you want uncontrolled component
                    value={active} // state-controlled value of checkbox
                    onChange={(e) => {
                      // e.target.checked will return true or false if checkbox is checked
                      e.target.checked ? setActive(true) : setActive(false);
                    }}
                  ></Form.Check>
                </Form.Group>
              </Col>

              <Col lg={12} className="section-header">
                ADDRESS INFORMATION
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicState">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicState"
                  >
                    State
                  </Form.Label>
                  <Select
                    placeholder="State"
                    value={{ label: project.state, value: project.state }}
                    onChange={handleState}
                    options={state}
                  ></Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCity">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCity"
                  >
                    City
                  </Form.Label>
                  <Select
                    options={cities}
                    placeholder="Enter City"
                    onChange={(e) => {
                      handleSelectListChange(e, "city");
                    }}
                    name="city"
                    value={{ label: project.city, value: project.city }}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicStreet">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Street
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    placeholder="Enter street"
                    value={project.street}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
              {/* <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicCity">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCity"
                                    >
                                        City
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        placeholder="Enter city"
                                        value={project.city}
                                        onChange={handleChange}
                                        style={{ height: '38px' }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicState">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicState"
                                    >
                                        State
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="state"
                                        placeholder="Enter state"
                                        value={project.state}
                                        onChange={handleChange}
                                        style={{ height: '38px' }}
                                    />
                                </Form.Group>
                            </Col> */}

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicPin">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPin"
                  >
                    Pincode
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="pincode"
                    placeholder="Enter pincode"
                    value={project.pincode}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCountry">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCountry"
                  >
                    Country
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    placeholder="Enter country"
                    value={project.country}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>

              {/* <Col lg={6}>
                            <Form.Group className="mx-3" controlId="formBasicOwner">
                                <Form.Label className="form-view-label" htmlFor="formBasicOwner" >
                                    Assign Staff
                                </Form.Label>

                                {defaultOwner.length > 0 ?
                                <Typeahead
                                required
                                    id="basic-typeahead-single"
                                    defaultSelected={defaultOwner}
                                    name="owner"
                                    labelKey="username"
                                    options={ownerList}
                                    onChange={handleOwnerName}
                                    placeholder="Choose a state..."
                                    /> : ''}
                            
                            </Form.Group>
                            </Col> */}
              {/* <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicContactId">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicStreet"
                                    >
                                        Contact Person
                                    </Form.Label>
                                    {defaultContact.length > 0 ?
                                        <Typeahead
                                            required
                                            id="basic-typeahead-single2"
                                            defaultSelected={defaultContact}
                                            name="contact"
                                            labelKey="contactname"
                                            options={contactList}
                                            onChange={handleContactName}
                                            placeholder="Choose a contact..."
                                            /> : ''}
                                </Form.Group>
                            </Col> */}
              {/* <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicDescription">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicStreet"
                                    >
                                        Active
                                    </Form.Label>
                                    <Form.Control
                    //as="textarea"
                    name="isactive"
                    placeholder="Active or Not"
                    value={project.isactive}
                    onChange={handleChange}
                  />
                                </Form.Group>
                            </Col> */}
              {/* <Col lg={4}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Active
                      </Form.Label>

                      <Form.Check
                        defaultChecked={active} // if checkbox should be checked by default
                        // defaultValue="yes" // use this instead of value if you want uncontrolled component
                        value={active} // state-controlled value of checkbox
                        onChange={(e) => {
                          // e.target.checked will return true or false if checkbox is checked
                          e.target.checked ? setActive(true) : setActive(false);
                        }}
                      ></Form.Check>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicCountry">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCountry"
                                    >
                                        Video Url
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="vidurl"
                                        placeholder="Enter Video Url"
                                        value={project.vidurl}
                                        onChange={handleChange}
                                        style={{ height: '38px' }}
                                    />
                                </Form.Group>
                            </Col> */}
            </Row>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default ProjectEdit;
