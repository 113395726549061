
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import inventoryApi from "../api/inventoryApi";
import { useLocation } from "react-router-dom";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";
import ReportPDF from './ReportPDF'

// import { ExportJsonCsv } from 'react-export-json-csv';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import moment from "moment";
import csvDownload from 'json-to-csv-export'
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";



const ReportView = () => {
  const [arrName, setArrName] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const report = location.state;
  const [body, setBody] = useState([]);
  const [refreshList, setRefreshList] = useState(Date.now());
  const [fields, setFields] = useState([]);
  const [values, setValues] = useState([]);
  useEffect(() => {
    async function init() {
      console.log(report)
      console.log('reports', report.id)
      const result = await inventoryApi.fetchReportsById(report);
      console.log("api response", result);
      if(result && result.length > 0){
        setArrName(Object.keys(result[0]));
        setBody(result);
      } 
      
      
    }

    init()
  }, []);



  const header = arrName.map((name) => ({
    title: name.charAt(0).toUpperCase() + name.slice(1),
    prop: name,
    isFilterable: true,
  }));

  const labels = {
    beforeSelect: " "
  }
  console.log(arrName)
  const ReportDataCSV = {
    data: body,
    filename: 'Report',
    delimiter: ',',
    headers: arrName
  }

  const pdfRecorder = () => {
    setRefreshList(Date.now());
    setTimeout(function () {
      pdf();
    }, 500)
  };
  const pdf = () => {
    const pdfTable = document.getElementById("divToPrint2");
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });
    const documentDefinition = {
      content: [html],
      pageBreakBefore: function (currentNode) {
        return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
      }
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();

  };

  return (
    <>
      {/* <CustomSeparator cmpListName="" cmpViewName="" > </CustomSeparator> */}
      <Container>
      <CustomSeparator
        cmpListName="Report Name"
        currentCmpName={report.name}
        indexLength="2"
        url="/reports" >
      </CustomSeparator>
      </Container>
      <Row className="g-0">
        <Col lg={2} className="mx-2"></Col>
        <Col lg={12} className="p-lg-4">
       
          {body ? (
            <DatatableWrapper body={body} headers={header} paginationOptionsProps={{ initialState: { rowsPerPage: 100 } }}>
              
              <div className="d-flex justify-content-between align-items-center mb-4">
                {/* <div className="col-6 col-sm-3">
                <p style={{ fontSize: "small" }}>Report Name:</p>
                <b>{report.name}</b>
              </div> */}
             
              
             <Col xs={3} lg={3} className="d-flex flex-col justify-content-end align-items-end">
          <Filter />
        </Col>
                {/* <Col>
                  <div className="row">
                    <div className="d-flex align-items-center mt-3">
                      <Button
                        className="btn-sm mr-2"
                        variant="outline-success"
                        onClick={() => csvDownload(ReportDataCSV)}
                      >
                        <i className="fa-solid fa-file-csv" style={{ fontSize: '24px' }}></i>
                      </Button>
                    </div>
                    <div className="w-100"></div>
                  </div>
                  <div className="col-3 col-sm-2">
                    <Button className='btn-sm' variant='danger' onClick={() => pdfRecorder()} s>
                      <i className="fa-solid fa-print"></i> PDF
                    </Button>
                  </div>
                </Col> */}

<Col xs={4} lg={5} className="d-flex flex-row justify-content-end">
  <div className="d-flex align-items-center">
    <Button
      className="btn-sm mr-2"
      variant="outline-success"
      onClick={() => csvDownload(ReportDataCSV)}
    >
      <i className="fa-solid fa-file-csv" style={{ fontSize: '24px' }}></i>
    </Button>
    <div style={{ width: '30px' }}></div> {/* Empty div for spacing */}
    <Button className='btn-sm' variant='outline-danger' onClick={() => pdfRecorder()} s>
      <i className="fa-solid fa-file-pdf" style={{ fontSize: '24px' }}></i>
    </Button>
  </div>
</Col>
      </div>
              
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            ""
          )}
        </Col>
        </Row>
        <Row>
        <Col lg={2}></Col>
        <div className="App container mt-5" style={{ display: 'none' }}>

          <div id="divToPrint2">
            <ReportPDF reports={body} report={report} headers={header} refresh={refreshList} arrName={arrName} values={values} fields={fields} />
          </div>
        </div>
      </Row>
    </>
  );
}

export default ReportView