import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import PubSub from "pubsub-js";

import CityState from "../constants/CityState.json";
import Select from "react-select";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";

const ContactEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [options, setOptions] = useState([]);

  const [state, setState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    let fetchedAccounts = [];
    accounts.map((item) => {
      fetchedAccounts.push({
        label: item.firstname,
        population: 4780127,
        capital: "Montgomery",
        region: "South",
      });
    });
    setOptions(fetchedAccounts);

    let defaultValue = location?.state?.state
      ? location?.state?.state
      : "Rajasthan";
    let st = [];
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === defaultValue) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
      var state = {};
      obj.value = obj.state;
      obj.label = obj.state;
      ////console.log(" obj.label >"+ obj.label)
      st.push(obj);
    });
    setCities(filteredCities);

    let finalStates = {};
    st = st.filter(function (currentObject) {
      if (currentObject.value in finalStates) {
        return false;
      } else {
        finalStates[currentObject.value] = true;
        return true;
      }
    });
    //console.log('st:', st);
    setState(st);

    if (location?.state) {
    } else {
      setContact({
        ...contact,
        city: "Ajmer",
        state: "Rajasthan",
        country: "India",
      });
    }
  }, [accounts]);

  const location = useLocation();
  const navigate = useNavigate();
  //const [contact, setContact] = useState(location.state);
  const [contact, setContact] = useState(location.state ? location.state : {});

  const handleChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const checkRequredFields = () => {
    console.log(contact.firstname);
    console.log(contact.lastname);
    console.log(contact.phone);

    if (
      contact.firstname &&
      contact.firstname.trim() !== "" &&
      contact.lastname &&
      contact.lastname.trim() !== "" &&
      contact.phone &&
      contact.phone.trim() !== ""
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkRequredFields()) {
      setValidated(true);
      return;
    }

    //========= Logic to perform Create or Edit ======
    let result2 = {};
    if (contact.id) {
      console.log("---Update---" + contact.id);
      result2 = await inventoryApi.saveContact(contact);
      if (result2.success) {
        console.log("if result true");
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/contacts/${contact.id}`, { state: contact });
      }
    } else {
      console.log("--Create---");
      result2 = await inventoryApi.createContact(contact);
      if (result2) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/contacts/${result2.id}`, { state: result2 });
      }
    }
  };

  const handleCancel = () => {
    if (contact.id) navigate("/contacts/" + contact.id, { state: contact });
    else navigate("/contacts");
  };

  const handleState = (e) => {
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    setCities(filteredCities);
    setContact({ ...contact, state: e.value, city: "" });
  };

  const handleSelectListChange = (value, name) => {
    //console.log('contact:', lead);
    setContact({ ...contact, [name]: value.value });
    setSelectedCity(value.value);
  };

  return (
    <Container className="view-form">
      {location?.state?.id ? (
        <CustomSeparator
          cmpListName="Contacts"
          cmpViewName={contact.firstname + " " + contact.lastname}
          currentCmpName="Edit"
          indexLength="2"
          indexViewLength="3"
          cmpViewUrl={"/contacts/" + contact.id}
          url="/contacts"
        ></CustomSeparator>
      ) : (
        <CustomSeparator
          cmpListName="Contacts"
          currentCmpName="Create"
          indexLength="2"
          url="/contacts"
        ></CustomSeparator>
      )}
      <Row>
        <Col></Col>
        <Col lg={8} className="ibs-form-section">
          <Form
            className="mt-3"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                {contact.id ? (
                  <>
                    Edit Contact
                    <h4>{contact.firstname + " " + contact.lastname}</h4>
                  </>
                ) : (
                  "New Contact"
                )}
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicLastName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Contact Type
                  </Form.Label>
                  <Form.Select
                    aria-label="Select Contact Type"
                    name="type"
                    value={contact.type}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="Property Owner">Property Owner</option>
                    <option value="Buyer">Buyer</option>
                    <option value="Seller">Seller</option>
                    <option value="Agent">Agent</option>
                    <option value="Staff">Staff</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                {/* <Form.Group className="mx-3" controlId="formBasicsalutation">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicsalutation"
                  >
                    Salutation
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="salutation"
                    placeholder="Enter salutation"
                    value={contact.salutation}
                    onChange={handleChange}
                  />
                </Form.Group> */}
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Salutation
                  </Form.Label>
                  <Form.Select
                    aria-label="Select Salutation"
                    name="salutation"
                    value={contact.salutation}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="Mr">Mr.</option>
                    <option value="Mrs">Mrs.</option>
                    <option value="Ms">Ms.</option>
                    <option value="Dr">Dr.</option>
                    <option value="Prof">Prof.</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicFirstName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    First Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="firstname"
                    placeholder="Enter firstname"
                    value={contact.firstname}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicLastName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicLastName"
                  >
                    Last Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="lastname"
                    placeholder="Enter lastname"
                    value={contact.lastname}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Form.Group className="mx-3" controlId="formBasicTitle">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicTitle"
                  >
                    Title
                  </Form.Label>
                  {/* <Form.Control
                    type="text"
                    name="title"
                    placeholder="Enter title"
                    value={contact.title}
                    onChange={handleChange}
                  /> */}

                  <Form.Select
                    value={contact.title}
                    name="title"
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="CEO">CEO</option>
                    <option value="Director">Director</option>
                    <option value="Manager">Manager</option>
                    <option value="Owner">Owner</option>
                    <option value="Partner">Partner</option>
                    <option value="Executive">Executive</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Enter Title.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              {/* <Col>
                  <Form.Group className="mx-3" controlId="formBasicLastName">
                
      
      <Form.Label
          className="form-view-label"
          htmlFor="formBasicFirstName"
      >
          Contact Type
      </Form.Label>
      <Form.Select
          aria-label="Select Contact Type"
          name="type"
          value={contact.type}
          onChange={handleChange}
      >
          <option value="">--Select--</option>
          <option value="Property Owner">Property Owner</option>
          <option value="Buyer">Buyer</option>
          <option value="Seller">Seller</option> */}
              {/* <option value="Dr">Dr.</option>
        <option value="Prof">Prof.</option> */}
              {/* </Form.Select>

</Form.Group>
              </Col> */}
              <Col>
                <Form.Group className="mx-3" controlId="formBasicEmail">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicEmail"
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={contact.email}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicPhone">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPhone"
                  >
                    Phone
                  </Form.Label>
                  <Form.Control
                    required
                    type="phone"
                    name="phone"
                    placeholder="Enter phone"
                    value={contact.phone}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicTitle">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicTitle"
                  >
                    Title
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Enter title"
                    value={contact.title}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col> */}

              {/* <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicStreet">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Account
                  </Form.Label>
                  <Typeahead
                    id="basic-example"
                    onChange={setSelected}
                    options={options}
                    placeholder="Search Accounts..."
                    selected={selected}
                    clearButton
                    defaultFilterBy="label"
                    labelKey="label"
                  />
                </Form.Group>
              </Col> */}
              <Col lg={12} className="section-header">
                ADDRESS INFORMATION
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicState">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicState"
                  >
                    State
                  </Form.Label>
                  <Select
                    placeholder="State"
                    value={{ label: contact.state, value: contact.state }}
                    onChange={handleState}
                    options={state}
                  ></Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCity">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCity"
                  >
                    City
                  </Form.Label>
                  <Select
                    options={cities}
                    placeholder="Enter City"
                    onChange={(e) => {
                      handleSelectListChange(e, "city");
                    }}
                    name="city"
                    value={{ label: contact.city, value: contact.city }}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicStreet">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Street
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    placeholder="Enter Street"
                    value={contact.street}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              {/* <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCity">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCity"
                  >
                    City
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="Enter City"
                    value={contact.city}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicState">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicState"
                  >
                    State
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    placeholder="Enter State"
                    value={contact.state}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col> */}

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicPin">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPin"
                  >
                    Pincode
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="pincode"
                    placeholder="Enter pincode"
                    value={contact.pincode}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCountry">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCountry"
                  >
                    Country
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    placeholder="Enter country"
                    value={contact.country}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              {/* <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicStreet">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Street
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    placeholder="Enter email"
                    value={contact.street}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col> */}
            </Row>
            {/*<Row className="justify-content-md-center">
                            <Col lg={8}>
                                <Button  className="mx-3" variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Col>
                </Row>*/}
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default ContactEdit;
