import React, { useEffect, useState } from 'react'

import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BarChart from './charts/BarChart';
import PieChart from './charts/PieChart';
import Card from 'react-bootstrap/Card';
import EventEdit from './EventEdit'
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
 

  
import inventoryApi from "../api/inventoryApi";
  

const Home = () => {
  const [totalProperties, setTotalProperties] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [todayMeetings, setTodayMeetings] = useState([]);
  const [showEventModel, setShowEventModel] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    //const st = "Open - Not Contactecd"
    async function init() {

      const allMeetings = await inventoryApi.fetchAllMeetings('today');
      setTodayMeetings(allMeetings ? allMeetings : []);
      
      let totalPropertiesData = await  inventoryApi.fetchCountOfProperties();
      if(totalPropertiesData?.length > 0){
        setTotalProperties(totalPropertiesData[0]['totalproperties']);
      }

      let totalContactsData = await  inventoryApi.fetchCountOfContacts();
      if(totalContactsData?.length > 0){
        setTotalContacts(totalContactsData[0]['totalcontacts']);
      }
      let totalLeadsData = await  inventoryApi.fetchCountOfLeads();
      if(totalLeadsData?.length > 0){
        setTotalLeads(totalLeadsData[0]['totalleads']);
      }
      let totalIncomeData = await  inventoryApi.fetchTotalIncome();
      if(totalIncomeData?.length > 0){
        setTotalIncome(totalIncomeData[0]['totalincome']);
      }

      
      
    }

    init();
  }, []);

  const createLead = () => {
    navigate(`/leads/e`);
  };


  const submitEvents = async (eventRec) => {
    //console.log('eventRec home submit', eventRec)
    setShowEventModel(false);
  }
   
  return (
    <Container>
   
    <Row >
    <Col lg={3}>
    <Link to="/properties" className='text-decoration-none text-reset'>
      <div className="p-3 d-flex align-items-center m-3" style={{backgroundColor: 'white', borderLeft: '4px solid #1a293b'}}>
    {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

    <span class="fa-stack small">
    <i class="fa-solid fa-circle fa-stack-2x" style={{color:'#1a293b'}}></i>
    <i class="fa-solid fa-building fa-stack-1x" style={{color: 'white', fontSize : '2rem'}}></i>
  </span>
    <div className="flex-grow-1">
      <h6 className="text-muted mb-1">PROPERTIES</h6>
      <h1 className='mb-0 d-inline '>{totalProperties}</h1><Badge bg="light" text="dark">ALL</Badge>
      </div>
    </div>
    </Link>
    </Col>
    <Col lg={3}>
    <Link to="/contacts" className='text-decoration-none text-reset'>
    <div className="p-3 d-flex align-items-center m-3" style={{backgroundColor: 'white', borderLeft: '4px solid tomato'}}>
    {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

    <span class="fa-stack small">
    <i class="fa-solid fa-circle fa-stack-2x" style={{color:'tomato'}}></i>
    <i class="fa-solid fa-cart-shopping fa-stack-1x" style={{color: 'white', fontSize : '2rem'}}></i>
  </span>
    <div className="flex-grow-1">
      <h6 className="text-muted mb-1 ">CUSTOMERS</h6>
      <h1 className='mb-0 d-inline '>{totalContacts}</h1><Badge bg="light" text="dark">Converted</Badge>
      </div>
    </div>
    </Link>
    </Col>
    <Col lg={3}>
    <Link to="/leads" className='text-decoration-none text-reset'>
    <div className="p-3 d-flex align-items-center m-3" style={{backgroundColor: 'white', borderLeft: '4px solid #198754'}}>
    {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

    <span class="fa-stack small">
    <i class="fa-solid fa-circle fa-stack-2x" style={{color:'#198754'}}></i>
    <i class="fa-solid fa-phone fa-stack-1x" style={{color: 'white', fontSize : '2rem'}}></i>
  </span>
    <div className="flex-grow-1">
      <h6 className="text-muted mb-1">LEADS</h6>
      <h1 className='mb-0 d-inline '>{totalLeads}</h1><Badge bg="light" text="dark">ALL</Badge>
      </div>
    </div>
</Link>
    </Col>
    
    <Col lg={3}>
    <Link to="/transactions" className='text-decoration-none text-reset'>
    <div className="p-3 d-flex align-items-center m-3" style={{backgroundColor: 'white', borderLeft: '4px solid #4798b5'}}>
    {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

    <span class="fa-stack small">
    <i class="fa-solid fa-circle fa-stack-2x" style={{color:'#4798b5'}}></i>
    <i class="fa-solid fa-indian-rupee-sign fa-stack-1x" style={{color: 'white', fontSize : '2rem'}}></i>
  </span>
    <div className="flex-grow-1">
      <h6 className="text-muted mb-1">INCOME</h6>
      <h1 className='mb-0 d-inline '>{parseFloat(totalIncome/100000).toFixed(2)}</h1> <Badge bg="light" text="dark">₹ Lakh</Badge>
      </div>
    </div>
    </Link>
    </Col>
    </Row>
    <Row className="mt-5">
    <Col lg={6} className="text-center ">
    <p>Properties sales monthly</p>
    <div style={{height: '400px'}}><BarChart/></div>
    
    </Col>
    <Col lg={6} className="text-center">
    <p className="d-block">Area wise properties</p>
    <div className="text-center" style={{height: '400px'}}>
    <PieChart/>
    </div>

    {/* <Col lg={6} className="mt-3">

<div style={{ height: '350px' }}>

  <Card className="">
    <Card.Header style={{ backgroundColor: "#009ab6", borderBottom: "none", color: "#fff", textAlign: "center" }}>QUICK ACTIONS</Card.Header>
    <Card.Body>
      <Row>
        <Col>
          <Card.Text>
            <div width>
              <Button className=" btn-primary btn-sm my-2 btn-block" style={{ width: '80%' }} onClick={() => createLead(true)}> <i className="fa-solid fa-bolt"></i> NEW LEAD</Button>

            </div>
            <div>
              <Button className=" btn-custom btn-sm btn-block" style={{ width: '80%' }} onClick={() => setShowEventModel(true)}> <i className="fa-solid fa-list-check" ></i> NEW METINGS</Button>

            </div>
          </Card.Text>
        </Col>
        <Col>
          <center>
            <div ><i className="fa-regular fa-calendar-days fa-2x" style={{ color: "#E73F59" }}></i></div>
            <p className='pt-2'><i>You have <Link to={"/meetings/today"}>
            <b>{todayMeetings?.length}</b>
</Link> meetings today</i></p>
          </center>
        </Col>
      </Row>
    </Card.Body>
    <Card.Footer className="text-muted">Followup with <a href="/leads" style={{ textDecoration: "none" }}>these</a> leads today</Card.Footer>
  </Card>
</div>
</Col> */}
{/* </Row> */}
{/* {showEventModel && (
<EventEdit
show={showEventModel}
onHide={() => setShowEventModel(false)}
parentid="abc"
//eventRec={event}
table="user"
submitEvents={submitEvents}
/>
)} */}
    
    </Col>
    </Row>
    
    </Container>
   
  )
}

export default Home
