import React, { useState, useEffect } from "react";

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import Confirm from "./Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RelatedListTask from "./RelatedListTask";
import inventoryApi from "../api/inventoryApi";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TaskEdit from "./TaskEdit";
//import ProjectSaleEdit from "./ProjectSaleEdit";
import EmailComposer from "./common/EmailComposer";
import moment from "moment/moment";
import RealetedProperty from "./RelatedProperty";
import PubSub from "pubsub-js";
//import Image from "react-bootstrap";
import * as constants from "../constants/CONSTANT";
import Project3DFile from "./Project3DFile";
import ProjectPlanFile from "./ProjectPlanFile";
import ProjectPlanFile2 from "./ProjectPlanFile2";
import jwt_decode from "jwt-decode";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";

const ProjectView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [project, setProject] = useState(location.state ? location.state : {});
  const [show3DURL, setShow3DURL] = useState("");
  const [show3D, setShow3D] = useState(false);
  const [showProjectPlan, setShowProjectPlan] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [relatedListTasks, setRelatedListTasks] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshFileList, setRefreshFileList] = useState();
  const [showTransactionModel, setShowTransactionModel] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [type, setType] = useState("Income");
  const [relatedListProperties, setRelatedListPrperty] = useState(true);

  const [uploadedImage, setUploadedImage] = useState(null);

  const [showVideo, setShowVideo] = useState(Boolean(project.vidurl));
  const [showImageButton, setShowImageButton] = useState(false);

  const [projectPlanComponent, setProjectPlanComponent] =
    useState("ProjectPlanFile");

  const openVideoWindow = () => {
    setShowVideo(true);
  };

  const closeVideoWindow = () => {
    setShowVideo(false);
  };

  const handleImageUpload = (image) => {
    setUploadedImage(image);
  };

  const renderPreview = (type, body) => {
    //console.log(type);
    switch (type) {
      case constants.PNG:
      case constants.JPG:
      case constants.JPEG:
        return <Image src={body} thumbnail width="800px" />;
      default:
        return <i className="fa-solid fa-file-file fa-2xl file-thumbnail"></i>;
    }
  };
  useEffect(() => {
    if (showProjectPlan) {
      var componentNames = Array("ProjectPlanFile", "ProjectPlanFile2");
      var componentName =
        componentNames[Math.floor(Math.random() * componentNames.length)];
      setProjectPlanComponent(componentName);
    }
  }, [showProjectPlan]);

  useEffect(() => {
    if (!window.myMap) {
      window.myMap = myMap;
      const googleMapScript = document.createElement("script");
      // googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBZsH0US1O6fSogoqBTUpUkvEWqs-rYMlY&callback=myMap`;
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDkVza2FtLItgn_kzJ27_A4l2Eyf3ZrOOI&callback=myMap`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
      //console.log('refresh:');
    } else {
      myMap();
    }
    try {
      if (localStorage.getItem("token")) {
        let user = jwt_decode(localStorage.getItem("token"));
        console.log("user:", user);
        setUserInfo(user);
      }
    } catch (error) {
      console.log(error);
    }
    fetchProject();
    //fetchFilespic();

    //
  }, []);

  const fetchProject = () => {
    // Coming from Email
    //console.log("from url lead id: ", location);
    if (
      !project.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      project.id = location.pathname.split("/")[2];
    }

    //console.log('proepties ', project.id);
    async function initProject() {
      //console.log("34242 3c45 ");
      let result = await inventoryApi.fetchProject(project.id);
      //console.log("result:", result);
      let user = jwt_decode(localStorage.getItem("token"));
      if (result) {
        let fileresult = await inventoryApi.fetchFilespic(
          result.id,
          "project_plan"
        );
        //console.log("fileresult 123123222131233123", fileresult);
        if (fileresult && fileresult.id) {
          //let resultBody = await inventoryApi.download3DFiles(fileresult.id);
          //console.log('result:', result);
          result.body = `${constants.PROJECT_BASE_URL}/images/${user.tenantcode}/${result.id}/${fileresult.id}.${fileresult.filetype}`;
        }

        if (result.file3d) {
          let url3D = result.file3d.split("/");
          const fileId = url3D[url3D.length - 1];
          //////console.log(`${constants.PROJECT_BASE_URL}/images/${user.tenantcode}/${result.id}/${fileId}`)
          //const filetype = url3D[url3D.length - 1].split(".")[1];
          //result.url = `${constants.PROJECT_BASE_URL}/images/${user.tenantcode}/${result.id}/${fileId}`
          setShow3DURL(
            `${constants.PROJECT_BASE_URL}/images/${user.tenantcode}/${result.id}/${fileId}`
          );
        }
        //console.log('project: ',result);
        setProject(result);
      } else {
        setProject({});
      }
    }
    initProject();
  };

  const createTransaction = (tType) => {
    setType(tType);
    setShowTransactionModel(true);
  };

  const submitTransaction = (eventRec) => {
    setShowTransactionModel(false);
  };

  /*const taskList = () => {
    async function initTask() {
      let task = await inventoryApi.fetchTasks(project.id);
      if (task && task?.length > 0){
        setTasks(task);
      }else{
        setTasks([]);
      }
    }
    initTask();
  }

  const filesList = () => {
    async function initFiles() {
      let file = await inventoryApi.fetchFiles(project.id);
      //console.log(file)
      if (file && file?.length > 0) { 
        //console.log('file')
        setFiles(file);
      } else {
        setFiles([]);
      }
    }
    initFiles();
  }
  */
  const deleteProject = async () => {
    const result = await inventoryApi.deleteProject(project.id);
    if (result.success) navigate(`/projects`);
  };

  const editLead = () => {
    navigate(`/projects/${project.id}/e`, { state: project });
  };

  const fetchFilespic = () => {
    //fetchProject();
    /* setModalShowTask(false);
    navigate(`/projects/${project.id}`, { state: project }); */
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
    setModalShowEmail(false);
  };
  const submitfiles = () => {
    //console.log('called:');
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    fetchProject();
    //fetchFilespic();
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setRelatedListTasks(true);
      setRelatedListFiles(false);
      setRelatedListPrperty(false);
    } else if (key === "files") {
      setRelatedListTasks(false);
      setRelatedListFiles(true);
      setRelatedListPrperty(false);
    } else if (key === "property") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListPrperty(true);
    }
  };

  const handleVideoLinkClick = (event) => {
    event.preventDefault();
    // Get the video URL from the project data
    const videoUrl = project.vidurl;
    if (videoUrl) {
      // Open the video URL in the same window
      window.location.href = videoUrl;
    }
  };

  const handleRefreshList = () => {
    fetchProject();
  };

  const myMap = () => {
    let contentString = `<h4>${project.name}</h4>
    <label className='maplabel'>Address:</label> <p>${project.street},${project.city},${project.state},${project.pincode},${project.country}</p>
    <label className='maplabel'>Project Contact Person:</label> <p> ${project.contactname} </p>
    <label className='maplabel'>Description:</label> <p> ${project.description} </p>`;
    const infowindow = new window.google.maps.InfoWindow({
      content: contentString,
      ariaLabel: "Uluru",
    });

    var mapProp = {
      center: new window.google.maps.LatLng(51.508742, -0.12085),
      zoom: 14,
    };
    var map = new window.google.maps.Map(
      document.getElementById("googleMap"),
      mapProp
    );

    var geocoder = new window.google.maps.Geocoder();
    var address = `${project.street},${project.city},${project.state},${project.pincode},${project.country}`;
    geocoder.geocode({ address: address }, function (results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        map.setCenter(results[0].geometry.location);
        var marker = new window.google.maps.Marker({
          map: map,
          position: results[0].geometry.location,
          title: `${project.name}`,
        });

        marker.addListener("click", () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        });
      }
    });
  };

  return (
    <div>
      <Container>
        <CustomSeparator
          cmpListName="Projects"
          currentCmpName={project.name}
          indexLength="2"
          url="/projects"
        ></CustomSeparator>
      </Container>

      {project && (
        <Container className="mt-4">
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteProject={deleteProject}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="project"
            />
          )}
          <Row className="view-form m-2">
            <Row className="view-form m-2">
              <Col lg={8} className="ibs-form-section">
                <Row className="view-form-header align-items-center">
                  <Col lg={6}>
                    Project
                    <h4>{project.name}</h4>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    <Button
                      className="btn-sm mx-2"
                      onClick={() => editLead(true)}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={() => setModalShow(true)}
                    >
                      Delete
                    </Button>
                    <Button
                      className="float-right btn-sm mx-2"
                      onClick={() => setModalShowEmail(true)}
                      variant="success"
                    >
                      <i class="fa-regular fa-envelope mx-2"></i>
                      Send Email
                    </Button>
                    {/* <Button
                  className="btn-sm mx-2"
                  variant="warning"
                  onClick={() => setShowTransactionModel(true)}
                >
                  Sale Project
                </Button> */}
                  </Col>
                </Row>
                <Row className="view-form-content">
                  <Col lg={4}>
                    <Row>
                      <Col lg={12}>
                        <label>Name</label>
                        <span>{project.name || <br />}</span>
                      </Col>
                      <Col lg={12}>
                        <label>Budget</label>
                        <span>{project.budget || <br />}</span>
                      </Col>
                      <Col lg={12}>
                        <label>Contact Person</label>
                        <span>
                          <Link to={`/contacts/${project.contactid}`}>
                            {project.contactname || <br />}
                          </Link>
                        </span>
                      </Col>

                      <Col lg={12}>
                        <label>Start Date</label>

                        <span>
                          {project.startdate ? moment(project.startdate || <br />).format(
                            "DD-MM-YYYY"
                          ) : "" } 
                        </span>
                      </Col>

                      <Col lg={12}>
                        <label>End Date</label>
                        <span>
                          {moment(project.enddate || <br />).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </Col>

                      

                      <Col lg={12}>
                        <label>Street</label>
                        <span>{project.street || <br />}</span>
                      </Col>

                      <Col lg={12}>
                        <label>City</label>
                        <span>{project.city || <br />}</span>
                      </Col>

                      <Col lg={12}>
                        <label>State</label>
                        <span>{project.state || <br />}</span>
                      </Col>

                      <Col lg={12}>
                        <label>Pincode</label>
                        <span>{project.pincode || <br />}</span>
                      </Col>

                      <Col lg={12}>
                        <label>Country</label>
                        <span>{project.country || <br />}</span>
                      </Col>
                      <Col lg={12}>
                        <label>Active</label>
                        <span>
                          {project.isactive}{" "}
                          <input type="checkbox" checked={project.isactive} />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={8}>
                    <center>
                      <div className="image-container">
                        <div className="image-wrapper">
                          {project && project.body ? (
                            <Image
                              src={project.body}
                              thumbnail
                              style={{ maxHeight: "40rem", maxWidth: "40rem" }}
                            />
                          ) : (
                            <div className="image-container-NoImg">
                              <img
                                className="maparea float-end"
                                id="Image-Maps-Com-image-maps-2023-08-22-094056"
                                src="/NoFound.svg"
                                border="0"
                                usemap="#image-maps-2023-08-22-094056"
                                alt=""
                                style={{
                                  maxHeight: "40rem",
                                  maxWidth: "40.8rem",
                                }}
                              />
                            </div>
                          )}
                          {project && project.body && (
                            <Button
                              className="image-button"
                              onClick={() => setShowProjectPlan(true)}
                            >
                              <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
                            </Button>
                          )}
                        </div>
                      </div>
                    </center>
                  </Col>
                </Row>
              </Col>
              <Col>
                <div
                  id="googleMap"
                  style={{ width: "100%", height: "550px" }}
                ></div>
              </Col>
            </Row>
            <Row className="view-form m-2">
              <Col className="ibs-form-section" lg={6}>
                <label>Video Url</label>
                <span>
                  {project.vidurl && (
                    <div className="video-overlay">
                      <div className="video-container">
                        <iframe
                          title="YouTube Video"
                          width="100%"
                          height="350"
                          src={project.vidurl}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  )}
                </span>
              </Col>
              <Col className="ibs-form-section" lg={6}>
                <label>Project 3D Plan </label>
                {project && project.file3d ? (
                  <span className="">
                    <model-viewer
                      style={{
                        width: "100%",
                        height: "355px",
                        background: "linear-gradient(#80FFDB, #5390D9)",
                      }}
                      // className="model-viewer"
                      src={show3DURL}
                      shadow-intensity="1"
                      ar
                      ar-scale="fixed"
                      camera-controls
                      touch-action="pan-y"
                      xr-environment
                      alt="A 3D model carousel"
                    >
                      <Button
                        className="image-button-3d btn-sm"
                        onClick={() => setShow3D(true)}
                      >
                        <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                      </Button>
                    </model-viewer>
                  </span>
                ) : (
                  <img
                    className="maparea float-end"
                    id="Image-Maps-Com-image-maps-2023-08-22-094056"
                    src="/NoFound.svg"
                    border="0"
                    usemap="#image-maps-2023-08-22-094056"
                    alt=""
                    style={{
                      width: "100%",
                      height: "355px",
                    }}
                  />
                )}
              </Col>
            </Row>
          </Row>
          {show3D && (
            <Project3DFile
              show={show3D}
              onHide={() => setShow3D(false)}
              project={project}
              show3DURL={show3DURL}
            />
          )}

          {showProjectPlan &&
            (projectPlanComponent === "ProjectPlanFile2" ? (
              <ProjectPlanFile2
                show={showProjectPlan}
                project={project}
                onHide={() => setShowProjectPlan(false)}
              />
            ) : (
              <ProjectPlanFile
                show={showProjectPlan}
                project={project}
                onHide={() => setShowProjectPlan(false)}
              />
            ))}

          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="property"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                <Tab eventKey="property" title="Properties"></Tab>
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
              </Tabs>
              {relatedListTasks && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}
                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={project.id}
                  table="lead"
                  submitTasks={submitTasks}
                />
              )}
              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  Upload File
                </Button>
              )}
              {modalShowTaskfile && (
                <FilesCreate
                  show={modalShowTaskfile}
                  onHide={() => setModalShowFile(false)}
                  parent={project}
                  table="project"
                  submitfiles={submitfiles}
                />
              )}
            </Card.Header>
            <Card.Body>
              {project && project.id && relatedListProperties && (
                <RealetedProperty parent={project} />
              )}
              {relatedListTasks ? (
                <RelatedListTask
                  refreshTaskList={refreshTaskList}
                  parent={project}
                />
              ) : (
                ""
              )}
              {relatedListFiles ? (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={project}
                  table="project"
                  handleRefreshList={handleRefreshList}
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
          <EmailComposer
            size="lg"
            show={modalShowEmail}
            onHide={() => setModalShowEmail(false)}
            parentid={project?.id}
            toEmail={project?.email}
            table="lead"
            submitTasks={submitTasks}
          />
          {/* {showTransactionModel && 
      <ProjectSaleEdit show={showTransactionModel}
      onHide={() => setShowTransactionModel(false)}
      parentid={project.id}
      parentName={project.name}
      eventRec={transaction}
      table="user"
      type={'Income'}
      submitEvents={submitTransaction}></ProjectSaleEdit>  } */}
          {/* ... */}
          {/* <FilesCreate
          parent={project}
          onImageUpload={handleImageUpload} // Pass the callback function
        /> */}
        </Container>
      )}
    </div>
  );
};

export default ProjectView;
