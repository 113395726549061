import React, { useState } from 'react';
import { Col, Row, Container } from "react-bootstrap";
import AmortizationScheduleEMITable from './AmortizationScheduleEMITable';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CustomSeparator from "./Breadcrumbs/CustomSeparator";

function EMICalculator() {
  const [homeValue, setHomeValue] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTenure, setLoanTenure] = useState('');
  const [selectedTenure, setSelectedTenure] = useState('years');
  const [buttonStyleYears, setButtonStyleYears] = useState('active');
  const [buttonStyleMonths, setButtonStyleMonths] = useState('');
  const [emi, setEMI] = useState(null);
  const [calculateClicked, setCalculateClicked] = useState(false);

  const handleTenureChange = (value) => {
    setSelectedTenure(value);
    setLoanTenure('');
    if (value === 'years') {
      setButtonStyleYears('active');
      setButtonStyleMonths('');
    } else {
      setButtonStyleYears('');
      setButtonStyleMonths('active');
    }
  };
  const parseValue = (value) => {
    return parseFloat(value.replace(/,/g, ''));
  };

  const calculateLoanAmount = (homeVal, downPay) => {
    if (homeVal && downPay) {
      const homeValueFloat = parseValue(homeVal);
      const downPaymentFloat = parseValue(downPay);
      const calculatedLoanAmount = homeValueFloat - downPaymentFloat;
      setLoanAmount(calculatedLoanAmount);
    } else {
      setLoanAmount('');
    }
  };
  
  const handleHomeValueChange = (value) => {
    setHomeValue(value);
    setCalculateClicked(false);
    calculateLoanAmount(value, downPayment);
  };
  
  const handleDownPaymentChange = (value) => {
    setDownPayment(value);
    setCalculateClicked(false);
    calculateLoanAmount(homeValue, value);
  }; 

  const handleLoanAmountChange = (value) => {
    setLoanAmount(value);
    setCalculateClicked(false);
  };

  const handleInterestRateChange = (value) => {
    setInterestRate(value);
    setCalculateClicked(false);
  };

  const handleLoanTenureChange = (value) => {
    setLoanTenure(value);
    setCalculateClicked(false);
  };

  const calculateEMI = () => {
    if (loanAmount && interestRate && loanTenure) {
      const p = parseFloat(loanAmount);
      const r = parseFloat(interestRate) / (12 * 100);
      const n = parseFloat(loanTenure);

      const totalLoanTenureMonths = selectedTenure === 'years' ? n * 12 : n;

      const emiValue = (p * r * Math.pow(1 + r, totalLoanTenureMonths)) / (Math.pow(1 + r, totalLoanTenureMonths) - 1);
      setEMI(emiValue);
      setCalculateClicked(true);
    } else {
      setEMI(null);
    }
  };

  return (
    <>
    <Container>
        <CustomSeparator 
        currentCmpName="Calculate EMI"
        indexLength="0"
        url="/calculator" > 
        </CustomSeparator>
   
        <Container className='pt-5'>    
        <Row>
        <Col lg={4}>
            <div className="emi-calculator " style={{height:"80vh"}}>
            <div className='d-flex justify-content-between '>
            <div className='d-flex justify-content-start'>
                <h3>EMI Calculator</h3>
            </div>
                {emi !== null && (
                <div className='d-flex justify-content-end'>
                <div className="result">
                    <h3>Loan EMI</h3>
                    <p>₹ {emi.toFixed(2)}</p>
                </div>
                </div>
                )}
            
            </div>

            <div className="input-container">
                <div>
                <Form.Label>Home Amount (₹)</Form.Label>
                <Form.Control
                    type="number"
                    min="0"
                    value={homeValue}
                    onChange={(e) => handleHomeValueChange(e.target.value)}
                />
                </div>
                <div>
                <Form.Label>Down Payment (₹)</Form.Label>
                <Form.Control
                    type="number"
                    min="0"
                    value={downPayment}
                    onChange={(e) => handleDownPaymentChange(e.target.value)}
                />
                </div>
                <div>
                <Form.Label>Loan Amount (₹)</Form.Label>
                <Form.Control
                    type="number"
                    min="0"
                    value={loanAmount}
                    onChange={(e) => handleLoanAmountChange(e.target.value)}
                    disabled={!homeValue || !downPayment} 
                />
                </div>
                <div>
                <Form.Label>Interest Rate (%)</Form.Label>
                <Form.Control
                    type="number"
                    min="0"
                    value={interestRate}
                    onChange={(e) => handleInterestRateChange(e.target.value)}
                />
                </div>
                <Form.Label>Loan Tenure</Form.Label>
                <InputGroup>
                <Form.Control
                    aria-label="Recipient's username with two button addons"
                    min="0"
                    type="number"
                    value={loanTenure}
                    onChange={(e) => handleLoanTenureChange(e.target.value)}
                />
                <Button variant="outline-secondary" className={`tenure-button ${buttonStyleYears}`} onClick={() => handleTenureChange('years')}>Year</Button>
                <Button variant="outline-secondary" className={`tenure-button ${buttonStyleMonths}`} onClick={() => handleTenureChange('months')}>Month</Button>
                </InputGroup>
                <Button className="mt-3" variant="primary" onClick={calculateEMI}>Calculate</Button>
            </div>
            
            </div>
        </Col>
        <Col lg={8}>
        {emi !== null && (
            <div className="emi-calculator-table" style={{height:"80vh"}}>
            <AmortizationScheduleEMITable
                emi={emi}
                loanAmount={loanAmount}
                interestRate={interestRate}
                loanTenure={loanTenure}
                selectedTenure={selectedTenure}
                calculateClicked={calculateClicked}
            />
            </div>
        )}
        </Col>
        </Row>
        </Container>
    </Container>
    </>
  );
}
export default EMICalculator;
