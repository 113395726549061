import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import PubSub from "pubsub-js";
import Select from "react-select";

import CityState from "../constants/CityState.json";
import ContactNewModal from "./ContactNewModal";
import jwt_decode from "jwt-decode";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";

const PropertyEdit = (props) => {
  const [userInfo, setUserInfo] = useState({});
  const location = useLocation();
  const [validated, setValidated] = useState(false);

  const [state, setState] = useState("Rajasthan");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const [active, setActive] = useState(
    location?.state?.showonweb ? true : false
  );
  const [modalShow, setModalShow] = useState(false);

  // const [stateOptions, setStateOptions] = useState([]);
  // const [cityOptions, setCityOptions] = useState([]);

  //const { accounts } = useSelector((state) => state.api);

  /*useEffect(() => {
      let fetchedAccounts = [];
      accounts.map((item) => {
        fetchedAccounts.push({
          label: item.firstname,
          population: 4780127,
          capital: "Montgomery",
          region: "South",
        });
      });
      setOptions(fetchedAccounts);
    }, [accounts]);*/

  const navigate = useNavigate();
  const [option, setoption] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [selectedOwner, setSelectedOwner] = useState();
  const [optionProject, setOptionProject] = useState({});
  const [optionContact, setOptionContact] = useState();
  const [optionOwner, setOptionOwner] = useState();
  // const [property, setProperty] = useState(
  //   location.state ? location.state : {}
  // );
  const [contactList, setContactList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [defaultContact, setDefaultContact] = useState([]);

  const [ownerList, setownerList] = useState([]);
  const [defaultOwner, setDefaultOwner] = useState([]);
  // Start Updated by yamini 12-12-2023
  const [property, setProperty] = useState(
    location.state
      ? location.state
      : {
          type: "",
          areameasure: "",
        }
  );
  // End Updated by yamini 12-12-2023

  // Start Updated by yamini 12-12-2023
  const handleChange = (e) => {
    if (e.target.name === "vidurl") {
      let arr = e.target.value.split("/");
      let vid = arr[arr.length - 1];
      setProperty({
        ...property,
        [e.target.name]: "https://www.youtube.com/embed/" + vid,
      });
    } else {
      // Handle changes for other form inputs
      const { name, value } = e.target;

      // If the changed input is "type," reset the areameasure
      if (name === "type") {
        setProperty({ ...property, type: value, areameasure: "" });
      } else {
        setProperty({ ...property, [name]: value });
      }
    }
  };

  const measurementOptions = {
    "Flat/Apartment": ["Square Feet"],
    "Plot/Land": ["Square Yard", "Acres", "Bigha", "Hectare"],
    "Villa/House": ["Square Yard", "Square Feet"],
    "Shop/Commercial Space": ["Square Feet"],
  };
  const renderMeasurementOptions = () => {
    const selectedType = property.type;
    const options = measurementOptions[selectedType] || [];

    return (
      <>
        <option value="">--Select--</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </>
    );
  };
 
  const areaOptions = {
    "Flat/Apartment": ["Super Built-Up Area", "Built-Up Area", "Carpet Area"],
    "Plot/Land": ["Plot Area"],
    "Villa/House": ["Plot Area", "Built Up Area"],
    "Shop/Commercial Space": ["Super Built-Up Area", "Built-Up Area", "Carpet Area"], /* Edit By yamini 26-12-2023*/
  };

  const renderAreaOptions = () => {
    const selectedType = property.type;
    const options = areaOptions[selectedType] || [];

    return (
      <>
        <option value="">--Select--</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </>
    );
  };

  // const categoryOptions = {
  //   "Flat/Apartment": ["Commercial", "Residential", "Agricultural"],
  //   "Plot/Land": ["Commercial", "Residential", "Agricultural"],
  //   "Villa/House": ["Commercial", "Residential", "Agricultural"],
  //   "Shop/Commercial Space": ["Commercial"],
  // };

  // const renderCategoryOptions = () => {
  //   const selectedType = property.type;
  //   const options = categoryOptions[selectedType] || [];

  //   return (
  //     <>
  //       <option value="">--Select--</option>
  //       {options.map((option) => (
  //         <option key={option} value={option}>
  //           {option}
  //         </option>
  //       ))}
  //     </>
  //   );
  // };

  // End Updated by yamini 12-12-2023

  useEffect(() => {
    async function init() {
      if (localStorage.getItem("token") && !location?.state) {
        let user = jwt_decode(localStorage.getItem("token"));
        setUserInfo(user);
        var obj = {};
        obj.value = user.id;
        obj.label = user.username;
        setSelectedOwner(obj);
        setProperty({ ...property, ownerid: user.id });
      }

      // Fetch Owners from user
      const result = await inventoryApi.fetchUsers();
      if (result) {
        setownerList(result);
        if (property.id) {
          setDefaultOwner([
            { id: property.ownerid, username: property.ownername },
          ]);
        } else {
          setDefaultOwner([{ id: "", username: "" }]);
        }
      }

      // Fetch Contacts
      const conResult = await inventoryApi.fetchContacts();
      if (conResult) {
        setContactList(conResult);
        if (property.id) {
          setDefaultContact([
            { id: property.contactid, contactname: property.contactname },
          ]);
        } else {
          setDefaultContact([{ id: "", contactname: "" }]);
        }
      }

      // Fetch Contacts
      // const proResult = await inventoryApi.fetchProjects();
      // console.log('proResult',proResult);
      // if(proResult) {
      //     setProjectList(proResult);
      //     if(property.id){
      //         console.log('edit case', property);
      //         setDefaultContact([{'id':property.projectid, 'contactname':property.projectname}]);
      //     } else {
      //         console.log('create  case');
      //         setDefaultContact([{'id':'', 'projectname':''}]);
      //     }
      // }
      let projectList;
      // let contactList;
      let ownerList;
      projectList = await inventoryApi.fetchProjects();
      console.log("accountList--> ", projectList);
      if (projectList && projectList.length) {
        console.log("accountList--> ", projectList);
        let ar = [];
        projectList.map((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.name;
          obj.city = item.city;
          obj.country = item.country;
          obj.pincode = item.pincode;
          obj.state = item.state;
          obj.street = item.street;
          obj.contactid = item.contactid;
          ar.push(obj);
        });
        if (location?.state) {
          let acc = ar.filter((acc) => acc.value === location.state.projectid);
          setSelectedProject(acc);
        }
        setOptionProject(ar);
      } else {
        setOptionProject([]);
      }

      /* contactList = await inventoryApi.fetchContacts();
            console.log('accountList--> ', contactList)
            if (contactList && contactList.length) {
                console.log('accountList--> ', contactList)
                let ar = [];
                contactList.map((item) => {
                    var obj = {};
                    obj.value = item.id;
                    obj.label = item.firstname + " " + item.lastname;
                    ar.push(obj);
                    console.log('obj--> ', obj)
                })
                if (location?.state) {
                    console.log('ar', ar)
                    console.log('location.state.contactid', location.state)
                    let acc = ar.filter((acc) => acc.value === location.state)
                    console.log('acc', acc)
                    setoption(acc);
                }
                setOptionContact(ar);
            } else {
                setOptionContact([]);
            } */

      ownerList = await inventoryApi.fetchUsers();
      console.log("accountList--> ", ownerList);
      if (ownerList && ownerList.length) {
        console.log("ownerList-->", ownerList);
        let ar = [];
        ownerList.map((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.firstname + " " + item.lastname;
          ar.push(obj);
          console.log("obj--> ", obj);
        });
        if (location?.state) {
          console.log("ar", ar);
          console.log("location.state.contactid", location.state);
          let acc = ar.filter((acc) => acc.value === location.state.ownerid);
          console.log("acc", acc);
          setSelectedOwner(acc);
        }
        setOptionOwner(ar);
      } else {
        setOptionOwner([]);
      }
    }

    // let st = [];

    //     CityState.map((item) => {
    //         ////console.log(item.state,item.id);
    //         var obj = {};
    //         obj.value = item.state;
    //         obj.label = item.state;
    //         ////console.log(" obj.label >"+ obj.label)
    //         st.push(obj);

    //     });
    //     let finalStates = {};
    //     st = st.filter(function(currentObject) {
    //         if (currentObject.value in finalStates) {
    //             return false;
    //         } else {
    //             finalStates[currentObject.value] = true;
    //             return true;
    //         }
    //     });
    //     console.log('st:', st);
    //     setState(st);
    let defaultValue = location?.state?.state
      ? location?.state?.state
      : "Rajasthan";
    let st = [];
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === defaultValue) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
      var state = {};
      obj.value = obj.state;
      obj.label = obj.state;
      ////console.log(" obj.label >"+ obj.label)
      st.push(obj);
    });
    setCities(filteredCities);

    let finalStates = {};
    st = st.filter(function (currentObject) {
      if (currentObject.value in finalStates) {
        return false;
      } else {
        finalStates[currentObject.value] = true;
        return true;
      }
    });
    //console.log('st:', st);
    setState(st);

    if (location?.state) {
    } else {
      setProperty({
        ...property,
        city: "Ajmer",
        state: "Rajasthan",
        country: "India",
      });
    }

    init();
    fetchContactList();
  }, []);

  const fetchContactList = () => {
    async function init() {
      let contactList = await inventoryApi.fetchContacts();
      console.log("accountList--> ", contactList);
      if (contactList && contactList.length) {
        console.log("accountList--> ", contactList);
        let ar = [];
        contactList.map((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.firstname + " " + item.lastname;
          ar.push(obj);
          console.log("obj--> ", obj);
        });
        if (location?.state) {
          console.log("ar", ar);
          console.log("location.state.contactid", location.state);
          let acc = ar.filter((acc) => acc.value === location.state.contactid);
          console.log("acc", acc);
          setoption(acc);
        }
        setOptionContact(ar);
      } else {
        setOptionContact([]);
      }
    }
    init();
  };

  const handleOwnerName = (event) => {
    console.log('event' , event);
    setSelectedOwner(event);
    setProperty({ ...property, ownerid: event.value });
  };

  const handleContactName = (event) => {
    console.log("handle contact name", event.length);
    if (event.length > 0) {
      console.log("--" + event[0].id);
      setProperty({ ...property, contactid: event.value });
    } else {
      setProperty({ ...property, contactid: "" });
    }
  };

  const handleProjectName = (event) => {
    console.log("handle conproject name", event.length);
    if (event.length > 0) {
      console.log("--" + event[0].id);
      setProperty({ ...property, projectid: event[0].id });
    } else {
      setProperty({ ...property, projectid: "" });
    }
  };

  const handleProject = (e) => {
    //console.log('e', e)
    setSelectedProject(e);
    let st = [];
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.state) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
      var state = {};
      obj.value = obj.state;
      obj.label = obj.state;
      ////console.log(" obj.label >"+ obj.label)
      st.push(obj);
    });
    setCities(filteredCities);

    let finalStates = {};
    st = st.filter(function (currentObject) {
      if (currentObject.value in finalStates) {
        return false;
      } else {
        finalStates[currentObject.value] = true;
        return true;
      }
    });

    let contactData = optionContact.filter((con) => con.value === e.contactid);
    setoption(contactData);
    // setContact({ ...contact, accountid: e.value, accountname: e.label });
    setProperty({
      ...property,
      projectid: e.value,
      city: e.city,
      country: e.country,
      pincode: e.pincode,
      state: e.state,
      street: e.street,
      contactid: e.contactid,
    });
  };

  const handleContact = (e) => {
    console.log("e", e);
    setoption(e);
    // setProperty({ ...property, projecttid: e.value, projectname: e.label });
    setProperty({ ...property, contactid: e.value });
  };

  const handleNewContact = (event) => {
    setModalShow(true);
  };

  const submitContact = (newContact) => {
    fetchContactList();
    setoption({
      value: newContact.id,
      label: newContact.firstname + " " + newContact.lastname,
    });
    setProperty({ ...property, contactid: newContact.id });
    setModalShow(false);
  };

  // Edit by Yamini 14-12-2023 remove type, phone, contactid and ownerid
  const checkRequredFields = () => {
    console.log(property.name);
    // console.log(property.phone);
    // console.log(property.type);
    // console.log(property.ownerid);
    // console.log(property.contactid);

    if (
      property.name &&
      property.name.trim() !== "" 
      // property.phone &&
      // property.phone.trim() !== "" &&
      // property.type &&
      // property.type.trim() !== ""
      // property.ownerid &&
      // property.ownerid.trim() !== "" &&
      // property.contactid &&
      // property.contactid.trim() !== ""
    ) {
      // if ((property.name && property.name.trim() !== '') && (property.phone && property.phone.trim() !== '') &&
      // (property.type && property.type.trim() !== '') &&
      // (property.contactid && property.contactid.trim() !== ''))
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    console.log("====handle submit====");
    e.preventDefault();

    property.showonweb = active;

    console.log("property  ", property);
    //const projectWithActiveProduct = await inventoryApi.findActiveProperty(property.id);
    //if (property?.id) {
    //   if (projectWithActiveProduct !== null && projectWithActiveProduct?.id &&
    //     project?.id !== projectWithActiveProduct.id && project.active === true) {
    //     //PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: 'Already exists active project' });
    //     PubSub.publish('RECORD_SAVED_TOAST', {title: 'Record Saved', message: 'Record saved successfully'});
    //            navigate(`/projects/${project.id}`, { state: project });
    //   } else {
    //     const result = await inventoryApi.saveProject(project);
    //   }
    //   navigate(`/projects/${project.id}`, { state: project });
    // } else {
    //     let result;
    //   if (projectWithActiveProduct !== null && projectWithActiveProduct?.id && project.active === true) {
    //    // PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: 'Already exists active project' });
    //     PubSub.publish('RECORD_SAVED_TOAST', {title: 'Record Saved', message: 'Record saved successfully'});
    //              navigate(`/projects/${result.id}`, { state: result });
    //   } else {
    //     result = await inventoryApi.createProject(project);
    //   }
    //   navigate(`/projects/${result.id}`, { state: result });
    // }

    //NEED to discuss
    // Start Uncomment by Yamini 14-12-2023
    if (checkRequredFields()) {
      setValidated(true);
      return;
    }
    // End Uncomment by Yamini 14-12-2023
    let result = {};
    console.log("property: ", JSON.stringify(property));

    if (property.id) {
      console.log("====edit-====");
      result = await inventoryApi.saveProperty(property);
      if (result.success) {
        console.log("if result true");
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/properties/${property.id}`, { state: property });
      }
    } else {
      console.log("====create====");
      result = await inventoryApi.createProperty(property);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/properties/${result.id}`, { state: result });
      }
    }

    console.log("result:", result);
  };

  const handleCancel = () => {
    if (property.id)
      navigate("/properties/" + property.id, { state: property });
    else navigate("/properties");
  };

  const handleState = (e) => {
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    setCities(filteredCities);
    setProperty({ ...property, state: e.value, city: "" });
  };

  const handleSelectListChange = (value, name) => {
    //console.log('contact:', lead);
    setProperty({ ...property, [name]: value.value });
    setSelectedCity(value.value);
  };

  // const handleStateChange = (selectedState) => {
  //     const filteredCities = CityState.filter(item => item.state === selectedState.value);
  //     const cityOptions = filteredCities.map(city => ({
  //         label: city.name,
  //         value: city.name
  //     }));
  //     setCityOptions(cityOptions);

  //     setProperty({
  //         ...property,
  //         state: selectedState.value,
  //         city: cityOptions[0].value
  //     });

  //     setState(selectedState.value); // Update the state state with the selected state
  //};

  return (
    <Container className="view-form">
      {location?.state?.id ? (
        <CustomSeparator
          cmpListName="Properties"
          cmpViewName={property.name}
          currentCmpName="Edit"
          indexLength="2"
          indexViewLength="3"
          cmpViewUrl={"/properties/" + property.id}
          url="/properties"
        ></CustomSeparator>
      ) : (
        <CustomSeparator
          cmpListName="Properties"
          currentCmpName="Create"
          indexLength="2"
          url="/properties"
        ></CustomSeparator>
      )}
      {modalShow && (
        <ContactNewModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          submitContact={submitContact}
        />
      )}
      <Row>
        <Col></Col>
        <Col lg={8} className="ibs-form-section">
          <Form
            className="mt-3"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                {property.id ? (
                  <>
                    Edit Property
                    <h4>{property.name}</h4>
                  </>
                ) : (
                  "New Property"
                )}
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                  style={{ height: "38px" }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicFirstName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    {/* Changed by Yamini 11-12-2023 */}
                    Property Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    placeholder="Enter Property Name"
                    value={property.name}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicLastName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicLastName"
                  >
                    Property Code
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    placeholder="Enter code"
                    value={property.code}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicEmail">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicEmail"
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={property.email}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicPhone">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPhone"
                  >
                    Phone
                  </Form.Label>
                  <Form.Control
                    // required {/* Edit by Yamini 12-12-2023 */}
                    type="phone"
                    name="phone"
                    placeholder="Enter phone"
                    value={property.phone}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* <Col lg={6} >

<Form.Group >
    <Form.Label
        className="form-view-label"
        htmlFor="formBasicLeadSource"
    >
                                        Type
                                    </Form.Label>
                                    <Form.Select
                                        required
                                        aria-label="Enter Payment Status"
                                        name="type"
                                        value={property.type}
                                        onChange={handleChange}
                                    >
                                        <option value=''>--Select--</option>
                                        <option value="Flat">Flat </option>
                                        <option value="Villa">Villa</option>
                                        <option value="Plot">Plot</option>
                                        <option value="Land">Land</option>
                                        <option value="House">House</option>
                                        <option value="Shop">Shop</option>                         
                                        <option value="Apartment">Apartment </option>               
                                        <option value="Commercial Space">Commercial Space</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter  Lead Type.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col> */}

              <Col lg={6}>
                <Form.Group style={{ marginLeft: "13px" }}>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCompany"
                  >
                    Type
                  </Form.Label>
                  <Form.Select
                    // required
                    value={property.type}
                    name="type"
                    onChange={handleChange}
                    style={{ height: "38px", width: "98%" }}
                  >
                    {/* Start Changed by Yamini 11-12-2023 */}
                    <option value="">--Select--</option>
                    <option value="Villa/House">Villa/House</option>
                    <option value="Plot/Land">Plot/Land</option>
                    <option value="Flat/Apartment">Flat/Apartment </option>
                    <option value="Shop/Commercial Space">
                      Shop/Commercial Space
                    </option>
                    {/* <option value="Commercial Space">Commercial Space</option> */}
                    {/* <option value="Land">Land</option>
                    <option value="House">House</option>
                    <option value="Apartment">Apartment </option> */}
                    {/* End Changed by Yamini 11-12-2023 */}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Enter Type.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Project Name
                  </Form.Label>
                  <Select
                    placeholder="Project Name... "
                    value={selectedProject}
                    onChange={handleProject}
                    options={optionProject}
                    //value={salutationValue}
                  ></Select>
                  <Form.Control.Feedback type="invalid">
                    Please Provide Project Name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Property for
                  </Form.Label>
                  <Form.Select
                    aria-label="Select Property for"
                    name="propertyfor"
                    value={property.propertyfor}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  >
                    <option value="">--Select--</option>
                    <option value="Sale">Sale</option>
                    <option value="Buy">Buy</option>
                    <option value="Rent">Rent</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Category
                  </Form.Label>
                  <Form.Select
                    aria-label="Select Leagal Status"
                    name="legalstatus"
                    value={property.legalstatus}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  >
                    {/* {renderCategoryOptions()} */}
                    <option value="">--Select--</option>
                    <option value="Commercial">Commercial</option>
                    <option value="Residential">Residential</option>
                    <option value="Agricultural">Agricultural</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* Start Added by Yamini 12-12-2023 */}
              <Col lg={2}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Measure in
                  </Form.Label>
                  <Form.Select
                    aria-label="Select Measurement"
                    name="areameasure"
                    value={property.areameasure}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  >
                    {renderMeasurementOptions()}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={2}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Area Type
                  </Form.Label>
                  <Form.Select
                    aria-label="Select Measurement"
                    name="areatype"
                    value={property.areatype}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  >
                    {renderAreaOptions()}
                  </Form.Select>
                </Form.Group>
              </Col>
              {/* End Added by Yamini 12-12-2023 */}

              <Col lg={2}>
                <Form.Group className="mx-3" controlId="formBasicArea">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicTitle"
                  >
                    Total Area
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="area"
                    placeholder="Enter area"
                    value={property.area}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicArea">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicTitle"
                  >
                    Property Cost
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="cost"
                    placeholder="Enter Property Cost"
                    value={property.cost}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicOwner">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicOwner"
                  >
                    Assign Staff
                  </Form.Label>
                  {console.log("=====>>>> defaultOwner" , defaultOwner)}
                  {console.log("=====>>>> selectedOwner" , selectedOwner)}
                  <Select
                    // required {/* Edit by Yamini 12-12-2023 */}
                    id="basic-typeahead-single"
                    defaultSelected={defaultOwner}
                    name="owner"
                    labelKey="username"
                    options={optionOwner}
                    value={selectedOwner}
                    onChange={handleOwnerName}
                    placeholder="Choose a state..."
                  ></Select>
                </Form.Group>
              </Col>
              {/* Start Added by Yamini 11-12-2023 */}
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicRegistredBy">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicRegistredBy"
                  >
                    Registred By
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="registredby"
                    placeholder="Registred By"
                    value={property.registredby}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
              {/* End Added by Yamini 11-12-2023 */}
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCountry">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCountry"
                  >
                    Video Url
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="vidurl"
                    placeholder="Enter Video Url"
                    value={property.vidurl}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Row className="d-flex align-items-center">
                  <Col lg={10}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Contact Name
                      </Form.Label>

                      <Select
                        placeholder="Contact Name... "
                        value={option}
                        onChange={handleContact}
                        options={optionContact}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Account Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Button
                      className="btn-md"
                      style={{ marginTop: "32px" }}
                      onClick={handleNewContact}
                    >
                      <i className="fas fa-plus" aria-hidden="true" />
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Status
                  </Form.Label>
                  <Form.Select
                    aria-label="Select Status"
                    name="status"
                    value={property.status}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  >
                    <option value="">--Select--</option>
                    <option value="Available">Available</option>
                    <option value="Sold">Sold</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicDescription">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Description
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    placeholder="Enter Particulars"
                    value={property.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={4}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label">
                    Show On Web
                  </Form.Label>

                  <Form.Check
                    defaultChecked={active} // if checkbox should be checked by default
                    // defaultValue="yes" // use this instead of value if you want uncontrolled component
                    value={active} // state-controlled value of checkbox
                    onChange={(e) => {
                      // e.target.checked will return true or false if checkbox is checked
                      e.target.checked ? setActive(true) : setActive(false);
                    }}
                  ></Form.Check>
                </Form.Group>
              </Col>

              <Col lg={12} className="section-header">
                ADDRESS INFORMATION
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicState">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicState"
                  >
                    State
                  </Form.Label>
                  <Select
                    placeholder="State"
                    value={{ label: property.state, value: property.state }}
                    onChange={handleState}
                    options={state}
                  ></Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCity">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCity"
                  >
                    City
                  </Form.Label>
                  <Select
                    options={cities}
                    placeholder="Enter City"
                    onChange={(e) => {
                      handleSelectListChange(e, "city");
                    }}
                    name="city"
                    value={{ label: property.city, value: property.city }}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicStreet">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Street
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    placeholder="Enter street"
                    value={property.street}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
              {/* <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicCity">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCity"
                                    >
                                        City
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        placeholder="Enter city"
                                        value={property.city}
                                        onChange={handleChange}
                                        style={{ height: '38px' }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicState">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicState"
                                    >
                                        State
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="state"
                                        placeholder="Enter state"
                                        value={property.state}
                                        onChange={handleChange}
                                        style={{ height: '38px' }}
                                    />
                                </Form.Group>
                            </Col> */}

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicPin">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPin"
                  >
                    Pincode
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="pincode"
                    placeholder="Enter pincode"
                    value={property.pincode}
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCountry">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCountry"
                  >
                    Country
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    placeholder="Enter country"
                    //value={property.country}
                    value={property.country} // Set the default value to "India"
                    onChange={handleChange}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </Col>

              {/* <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicOwner">
                                    <Form.Label className="form-view-label" htmlFor="formBasicOwner" >
                                        Assign Staff
                                    </Form.Label>


                                    <Select
                                        required
                                        id="basic-typeahead-single"
                                        defaultSelected={defaultOwner}
                                        name="owner"
                                        labelKey="username"
                                        options={optionOwner}
                                        value={selectedOwner}
                                        onChange={handleOwnerName}
                                        placeholder="Choose a state..."
                                    ></Select>

                                </Form.Group>
                            </Col> */}
              {/* <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicContactId">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicStreet"
                                    >
                                        Contact Person
                                    </Form.Label>
                                    {defaultContact.length > 0 ?
                                        <Typeahead
                                            required
                                            id="basic-typeahead-single2"
                                            defaultSelected={defaultContact}
                                            name="contact"
                                            labelKey="contactname"
                                            options={contactList}
                                            onChange={handleContactName}
                                            placeholder="Choose a contact..."
                                            /> : ''}
                                </Form.Group>
                            </Col> */}

              {/* <Col lg={6}>
                                <Form.Group className="mx-3" >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Contact Name
                                    </Form.Label>
                                    <Select

                                        placeholder="Contact Name... "
                                        value={option}
                                        onChange={handleContact}
                                        options={optionContact}
                                    //value={salutationValue}
                                    ></Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Account Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicDescription">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicStreet"
                                    >
                                        Description
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="description"
                                        placeholder="Enter Particulars"
                                        value={property.description}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicCountry">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCountry"
                                    >
                                        Video Url
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="vidurl"
                                        placeholder="Enter Video Url"
                                        value={property.vidurl}
                                        onChange={handleChange}
                                        style={{ height: '38px' }}
                                    />
                                </Form.Group>
                            </Col> */}
            </Row>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default PropertyEdit;