import React, { useEffect, useState } from 'react';

function AmortizationScheduleEMITable({ emi, loanAmount, interestRate, loanTenure, selectedTenure, calculateClicked }) {
  const [amortizationData, setAmortizationData] = useState([]);

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  useEffect(() => {
    if (calculateClicked && emi) {
      const rows = [];

      const p = parseFloat(loanAmount);
      const r = parseFloat(interestRate) / (12 * 100);
      const n = parseFloat(loanTenure);

      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const totalLoanTenureMonths = selectedTenure === 'years' ? n * 12 : n;
      let monthCount = 0;

      let balance = p;
      let loanPaidTillDate = 0;

      for (let month = currentMonth; month < currentMonth + totalLoanTenureMonths; month++) {
        const interestPayment = balance * r;
        const principalPayment = emi - interestPayment;
        balance -= principalPayment;
        loanPaidTillDate += principalPayment;

        const monthIndex = month % 12;
        const monthName = monthNames[monthIndex];
        const totalPayment = principalPayment + interestPayment;
        monthCount++;
        rows.push(
          <tr key={month}>
            <td>{monthCount}</td>
            <td>{monthName}</td>
            <td>₹ {Math.abs(principalPayment).toFixed(0)}</td>
            <td>₹ {Math.abs(interestPayment).toFixed(0)}</td>
            <td>₹ {Math.abs(totalPayment).toFixed(0)}</td>
            <td>₹ {Math.abs(balance).toFixed(0)}</td>
            <td>{Math.abs(((loanPaidTillDate / p) * 100)).toFixed(2)}%</td>
          </tr>
        );
      }

      setAmortizationData(rows);
    }
  }, [calculateClicked, emi, loanAmount, interestRate, loanTenure, selectedTenure, monthNames]);

  return (
    <>
        <h2>Amortization Schedule</h2>
        <div className="table-responsive mt-4" style={{height:"67vh"}}>
          <table className="table table-bordered">
            <thead className="table-light">
              <tr>
                <th></th>
                <th>Month</th>
                <th>Principal (A)</th>
                <th>Interest (B)</th>
                <th>Total (A + B)</th>
                <th>Balance</th>
                <th>Loan Paid Till Date</th>
              </tr>
            </thead>
            <tbody>{amortizationData}</tbody>
          </table>
        </div>
    </>
  );
}

export default AmortizationScheduleEMITable;