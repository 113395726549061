import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";

import * as constants from '../constants/CONSTANT'; 
import PubSub from "pubsub-js";


const Callback = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const account = location.state;
 

  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    fetchAccount();
  }, []);

  const fetchAccount = () => {
    // Coming from Email
    const CODE = searchParams.get("code");
    const state = searchParams.get("state");
    console.log('Code ', CODE, 'state', state);

    async function fetchToken() {
       let result = await inventoryApi.fetchAndSaveSalesforceToken(CODE, state);
       //console.log('result ', result)
       if (result.company_id) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Authorization Successful",
          message: "Client authorized to salesforce successfully",
        });
        // navigate(`/accounts/${state}`, { state: result });
        navigate(`/settings`, { state: result });
       } else {
        PubSub.publish("RECORD_ERROR_TOAST", {
          title: "Authorized Failed",
          message: 'Some Error Occured',
        });
        navigate(`/settings`, { state: result });
        // navigate(`/accounts/${state}`, { state: {id : state} });
       }
    }
    fetchToken();
  };




  return (
    <div>
     
        <Container>
          <Row>
            <Col></Col>
            <Col>
          <div class="typingIndicatorContainer">
            <div class="typingIndicatorBubble">
              <div class="typingIndicatorBubbleDot"></div>
              <div class="typingIndicatorBubbleDot"></div>
              <div class="typingIndicatorBubbleDot"></div>
            </div>
          </div> <div className="mt-4"> Redirecting...</div>
         
          </Col>
          <Col></Col>
          </Row>
        </Container>
    
    </div>
  );
};
export default Callback;
