import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Image, Modal, NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import authApi from "../api/authApi";
import jwt_decode from "jwt-decode";
import PubSub from "pubsub-js";
/*yamini 18-12-2023 */
import inventoryApi from "../api/inventoryApi"; 
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import { MenuItem } from "react-bootstrap-typeahead";
import Notification from "./common/Notification";
// import io from "socket.io-client";

// import CustomAlert from '../CustomAlert';

const Header = ({socket}) => {

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [userImage, setUserImage] = useState("");

//Start Yamini 18-12-2023
// const [todaymeetinglist, settodaymeetinglist] = useState([]);
// const [showAlert, setShowAlert] = useState(false);
const [unreadMsgCount, setUnreadMsgCount] = useState(0);
const [messages, setMessages] = useState(0);
const [showBellAlert, setShowBellAlert] = useState(false);
const [refreshNotification, setRefreshNotification] = useState(new Date());
// const [imgBody, setImgBody] = useState();
// const socket1 = io.connect('http://localhost:3003');

// {     // note changed URL here
//   path: '/socket.io',
//   // transports: ['websocket'],
//   timeout: 0,
// }


  useEffect(() => {

    notificationAlert()
    if (!localStorage.getItem("token")) navigate("/login");
    if (localStorage.getItem("notifications") && JSON.parse(localStorage.getItem("notifications")).length > 0) {
      navigate("/maintanance");
    }

    try {
      setUserInfo(jwt_decode(localStorage.getItem('token')));
      //console.log(userInfo.username);
    } catch (error) {
      //console.log(error)
    }

    console.log("socket =>", socket)
    socket?.on("greetings", (data) =>{
      console.log("greetings mesg received ", data);
      messageList(false); 
      
    })

  }, []);
  

  useEffect(() => {
    console.log('unreadMsgCount',unreadMsgCount)
    if(unreadMsgCount > 0){
      setShowBellAlert(true);
    }
  }, [unreadMsgCount]);

  // useEffect(() => {
  //   socket1.on("receive_message", (data) => {
  //     console.log('data=====>>>>' , data)
  //     notificationAlert();
  //   });
  // }, [socket1]);

  const formatMessage = (mystr) => {

    let oldStr = mystr;
    mystr = mystr.substring(1);
    var arry = mystr.split(" @");
    var returnArr = [];

    for (var a of arry) {
      var obj = {};
      var value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
      var key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));
      obj[`username`] = value;
      obj[`id`] = key;
      if (key)
        returnArr.push(obj);
    }
    //console.log(returnArr)
    for (var a of returnArr) {
      //console.log(`@[${a.username}](${a.id})`)
      oldStr = oldStr.replace(`@[${a.username}](${a.id})`, `<a href="/users/${a.id}">${a.username}</a>`);
    }

    return oldStr;
  };

// Start Added by yamini 22-12-2023
const notificationAlert =async()=>{
  let tasks = await inventoryApi.fetchUnreadMessages();
  console.log("tasks", tasks);
  setUnreadMsgCount(tasks?.length);
}
// End Added by yamini 22-12-2023


  //Start Yamini 18-12-2023
  const messageList = (open) =>{
    async function init() {
        let tasks = await inventoryApi.fetchUnreadMessages();

      console.log("tasks", tasks);
        
       
        

        if (tasks && tasks?.length > 0) {
          tasks.forEach(msg => {
            msg.description = formatMessage(msg.description);
          });
  
          setUnreadMsgCount(tasks.length);
          setMessages(tasks)
          setShowNotification(open); 
          setShowBellAlert(!open);
        } else {
          //console.log('No data')
          setMessages([])
          setUnreadMsgCount(0);
          setShowNotification(open); 
        }
      }
      init();
  };

  // const messageList = async(open) => {
  //   console.log('call in use effect')
  //   const allMeetings = await inventoryApi.fetchAllMeetings('today');
  //   console.log('allMeetings',allMeetings)
  //   settodaymeetinglist(todaymeetinglist)

  //   async function init() {
  //     let unreadmsg = await inventoryApi.fetchUnreadMessages();
  //     console.log("unreadmsg", unreadmsg);
  //           if (todaymeetinglist.length >= 0) {
  //             setShowAlert(true);
  //             setTimeout(() => {
  //               setShowAlert(false);
  //             }, 10000); 
  //           }
      
  //     if (unreadmsg && unreadmsg?.length > 0) {
  //       unreadmsg.forEach(msg => {
  //         msg.description = formatMessage(msg.description);
  //       });

  //       setUnreadMsgCount(unreadmsg.length);
  //       setMessages(unreadmsg)
  //       console.log('unreadmsg' , unreadmsg)
  //       setShowBellAlert(!open);
  //     } else {
  //       setMessages([])
  //       setUnreadMsgCount(0);
  //     }
  //   }
  //   init();
  // };

  //End Yamini 18-12-2023


  useEffect(() => {
    if (!localStorage.getItem("token")) navigate("/login");

    try {
      setUserInfo(jwt_decode(localStorage.getItem("token")));
      console.log(userInfo.username);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    //console.log("@@myimage " , localStorage.getItem("myimage"))
    //setUserImage(localStorage.getItem("myimage"));
    var dataImage = localStorage.getItem("myimage");
    let bannerImg = document.getElementById("profileimage");
    if (localStorage.getItem("myimage") !== "/img_avatar.png") {
      bannerImg.src = "data:image/png;base64," + dataImage;
    } else {
      bannerImg.src = localStorage.getItem("myimage");
    }

    console.log("RUN----");
  }, [localStorage.getItem("myimage")]);

  const [sidebar, setSidebar] = useState(false);

  const logout = () => {
    authApi.logout();
    navigate("/login");
  };

 
  const toggleSidebar = () => {
    if(sidebar === false){
      setSidebar(true);
      //console.log(sidebar);
    document.querySelector("#sidebar").classList.toggle("active");
    //document.querySelector("#minisidebar").classList.toggle("active");
    }

    else{
      setSidebar(false);
      //console.log(sidebar);
      document.querySelector("#sidebar").classList.toggle("active");
      //document.querySelector("#minisidebar").classList.toggle("active");
    }  
  };

  const [showNotification, setShowNotification] = useState(false);

  const closeNotification = () => {
    setShowNotification(false)
  };

  const openNotificationArea = () =>{
    //console.log('showNotification ', showNotification)
    //setShowNotification(!showNotification); 

      if(!showNotification)
        messageList(true); 
      else
        setShowNotification(false)
        setShowBellAlert(false);
    //setRefreshNotification(new Date())
  }

  // const openNotificationArea = () => {
  //   console.log('showNotification ', showNotification)
  //   setShowNotification(!showNotification); 

  //   if (!showNotification)
  //     messageList(true);
  //   else
  //     setShowNotification(false)
  //   setShowBellAlert(false);
  //   setRefreshNotification(new Date())
  // }


  return (
    <>
      <Navbar className="header px-2" bg="" expand="lg" variant="">
        <button
          type="button"
          id="sidebarCollapse"
          className="btn btn-info"
          onClick={toggleSidebar}
        >
          <i className="fas fa-align-left"></i>
        </button>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#" className="p-0" style={{ fontSize: ".9rem" }}>
              {/* <img src={'/img_avatar.png'} className="rounded-circle" style={{height: "30px"}}/>  */}
              <img
                src={userImage}
                className="rounded-circle"
                id="profileimage"
                style={{ height: "30px" }}
              />
              <Badge style={{ fontSize: ".9rem" }} bg="light" text="dark">
                {userInfo.username}{" "}
              </Badge>
              <Badge bg="success" style={{ fontSize: ".9rem" }}>
                {userInfo.userrole}{" "}
              </Badge>{" "}
            </Nav.Link>
            <Nav.Link
              href="#"
              className="d-flex p-0"
              style={{ alignItems: "center" }}
            >
              <span className="mx-2" style={{ fontSize: ".9rem" }}>
                Company
              </span>{" "}
              <Badge style={{ fontSize: ".9rem" }} bg="secondary">
                {userInfo.companyname}{" "}
              </Badge>{" "}
            </Nav.Link>
          </Nav>

          {/* <Nav className="ml-auto">
            <Nav.Link href="/about">
              <i className="fa-solid fa-bell"></i>
            </Nav.Link>
            {localStorage.getItem("token") ? (
              <Button variant="btn btn-primary" onClick={logout} title="Logout">
                <i className="fa-solid fa-right-from-bracket"></i>
              </Button>
            ) : (
              <></>
            )}
          </Nav> */}

          <Nav className="ml-auto d-flex align-items-center ">

          
            <div class="icon-wrapper">
              <i className="fa-solid fa-bell cic mx-3 mt-1" onClick={openNotificationArea} style={{ cursor: "pointer", fontSize: "1.5rem" }}></i>
              {showBellAlert && <span class="notify-number">{unreadMsgCount}</span>}
    
            </div>

            {/* 
          <i className="fa-solid fa-bell  icon-grey" ></i> */}
            {showNotification &&
              <Notification notifications={messages} closeNotification={closeNotification} refreshNotification={refreshNotification} />}
            {localStorage.getItem("token") ? (
              <Button variant="btn btn-primary" onClick={logout} title="Logout">
                <i className="fa-solid fa-right-from-bracket"></i>
              </Button>
            ) : (
              <></>
            )}

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;