import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-bootstrap-typeahead/css/Typeahead.css";
import moment from "moment";
import inventoryApi from "../api/inventoryApi";
import PubSub from 'pubsub-js';

const PropertySaleEdit = (props) => {
    const [validated, setValidated] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [transactionRecord, setTransactionRecord] = useState(props.transactionRecord ? props.transactionRecord : {parentid : props.parentid, type: props.type, status : 'Paid', category: 'Property Sale', parentname : props.parentName});
    const [transactionRecordTargetDate, setTransactionRecordTargetDate] = useState();
    useEffect(() => {
        if (props.parentid !== null && props.transactionRecord !== null) {
            let current = new Date();
            transactionRecord.lastmodifieddate = moment(current).format('YYYY-MM-DD');
        }
    }, []);

    //===typeahead===
    const [ownerList, setownerList] = useState([]);
    const [defaultOwner, setDefaultOwner] = useState([]);
    const [defaultTargetDate, setDefaultTargetDate] = useState(new Date());
    useEffect(() => {
        async function init() {
            const result = await inventoryApi.fetchUsers();
            if(result) {
                setownerList(result);
                if(transactionRecord.id){
                    setDefaultOwner([{'id':transactionRecord.ownerid, 'username':transactionRecord.ownername}]);
                } else {
                    setDefaultOwner([{'id':'', 'username':''}]);
                    setDefaultTargetDate(new Date()); 
                    transactionRecord.targetdate = defaultTargetDate;
                }
            }
            }
            init();
    }, []);

    const handleOwnerName = (transactionRecord) => {
        if(transactionRecord.length > 0){
            console.log('true');
            setTransactionRecord({ ...transactionRecord, ownerid:transactionRecord[0].id});
        } else {
            console.log('false');
            setTransactionRecord({ ...transactionRecord, ownerid:''});
        }
    }
    //=== /-typeahead ====

    const handleChange = (e) => {
        setTransactionRecord({ ...transactionRecord, [e.target.name]: e.target.value });
    };

    const checkRequredFields = () => {

        console.log(transactionRecord.type);
        console.log(transactionRecord.targetdate); 
        console.log(transactionRecord.status);
        console.log(transactionRecord.parentid);
 
        if((transactionRecord.type && transactionRecord.type.trim() !== '') && transactionRecord.targetdate &&  
           (transactionRecord.status && transactionRecord.status.trim() !== '')) {
             return false;
        } 
        return true;
     }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(checkRequredFields()) {
            setValidated(true);
            return;
        }
        transactionRecord.paymentstatus = transactionRecord.status;
        transactionRecord.title = transactionRecord.category + ' - ' +  transactionRecord.parentname;
        console.log('transactionRecord', transactionRecord);
        /*
        if(transactionRecord.id){

            const result = await inventoryApi.saveEventRec(transactionRecord);
            console.log('result:', result);
            if (result) {
                submitEventRecs();
            }
        }else{
            const result = await inventoryApi.createEventRec(transactionRecord);
            if (result) {
                submitEventRecs();
            }
        }*/
        
        const result = await inventoryApi.createTransaction(transactionRecord);
        console.log('reuslt', result)
            if (result.success) {
                console.log('if result true');
                PubSub.publish('RECORD_SAVED_TOAST', {title: 'Record Saved', message: 'Record saved successfully'});
                navigate(`/properties/${result.id}`, { state: result });
            }
        submitEvents(transactionRecord);
    };

    const submitEvents = (transactionRecord) => {
        //console.log('transactionRecord:', transactionRecord);
        props.submitEvents(transactionRecord);
    };

    return (
    

        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                   Property Sale
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="view-form">
                <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit} controlId="transactionRecordEdit">
                    <Row>
                       
                            
                                    <Col lg={6}>
                                        <Form.Group className="mx-3" controlId="formBasicTitle">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicTitle"
                                            >
                                               Transaction Type
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="type"
                                                value="Income"
                                                disabled
                                                onChange={handleChange}

                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mx-3" controlId="formBasicTitle">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicTitle"
                                            >
                                               Selected Property
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="propname"
                                                value={props.parentName}
                                                disabled
                                                onChange={handleChange}

                                            />
                                        </Form.Group>
                                    </Col>
                                   
                           
                                    <Col lg={6}>
                                        <Form.Group className="mx-3" controlId="formBasicPriority">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicPriority"
                                            >
                                                Category
                                            </Form.Label>
                                            <Form.Select required aria-label="Enter Priority" name="priority" value={transactionRecord.category} onChange={handleChange}>
                                                <option value="Property Sale">Property Sale</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    
                                    
                                    <Col lg={6}>
                                        <Form.Group className="mx-3" controlId="formBasicTargetdate">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicTargetdate"
                                            >
                                                Transaction Date {new Date(transactionRecord.targetdate).toISOString}
                                            </Form.Label>

                                            <Form.Control
                                                required
                                                type="date"
                                                name="targetdate"
                                                placeholder="Enter targetdate"
                                                value={moment(transactionRecord.targetdate).format('YYYY-MM-DD')}
                                                defaultValue={defaultTargetDate}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    
                                    <Col lg={6}>
                                        <Form.Group className="mx-3" controlId="formBasicStatus">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicStatus"
                                            >
                                                Amount
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="amount"
                                                value={transactionRecord.amount}
                                                
                                                onChange={handleChange}

                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mx-3" controlId="formBasicStatus">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicStatus"
                                            >
                                                Payment Status
                                            </Form.Label>
                                            <Form.Select required aria-label="Enter status" name="status" value={transactionRecord.status} onChange={handleChange}>
                                                <option value="Paid">Paid</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                
                                    <Col lg={12}>
                                        <Form.Group className="mx-3" controlId="formBasicDescription">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicDescription"
                                            >
                                                Description
                                            </Form.Label>
                                            <Form.Control
                    as="textarea"
                    name="description"
                    placeholder="Enter description"
                    value={transactionRecord.description}
                    onChange={handleChange}
                  />
                                        </Form.Group>
                                    </Col>
                             
                            
                      
                    </Row>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleSubmit}  >Save</Button>
                <Button onClick={props.onHide} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default PropertySaleEdit;
