import React, { useState, useEffect } from "react";

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import Confirm from "./Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RelatedListTask from "./RelatedListTask";
import inventoryApi from "../api/inventoryApi";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TaskEdit from "./TaskEdit";
//import ProjectSaleEdit from "./ProjectSaleEdit";
import EmailComposer from "./common/EmailComposer";
import moment from "moment/moment";
import RealetedProperty from "./RelatedProperty";
import PubSub from "pubsub-js";
//import Image from "react-bootstrap";
import * as constants from "../constants/CONSTANT";
import maphilight from "maphilight";
import $ from "jquery";
import Modal from "react-bootstrap/Modal";

const ProjectPlanFile = (props) => {
  const [imgUel, setImgUrl] = useState("");

  $(function () {
    $(".maparea").maphilight({
      stroke: false,
      fillColor: "85B061",
      fillOpacity: "0.8",
    });
  });

  const fileURL = (url) => {
    setImgUrl(url);
  };

  return (
    <Modal {...props} fullscreen={true}>
      <Modal.Header closeButton className="align-items-start">
        <Modal.Title className="d-flex flex-column ">
          {props.project.name} PROJECT PLAN
          <div>
            <i>Use mouse in hover and placing the mouse cursor on the target</i>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row>
            <Col lg={8}>
              <img
                className="maparea float-end"
                id="Image-Maps-Com-image-maps-2023-08-22-094056"
                src="/plots/build2.png"
                border="0"
                width="1000"
                height="900"
                orgWidth="1000"
                orgHeight="900"
                usemap="#image-maps-2023-08-22-094056"
                alt=""
              />
              
              <map
                name="image-maps-2023-08-22-094056"
                id="ImageMapsCom-image-maps-2023-08-22-094056"
              >
               
                {/* <area
                  shape="rect"
                  coords="998,898,1000,900"
                  alt="Image Map"
                  style={{ outline: "none" }}
                  title="Image Map"
                  //href="https://www.image-maps.com/"
                  
                /> */}
               
                <area
                  alt=""
                  title="Appartment1"
                  //href="/appartment_images/appartment1.jpg"
                  shape="poly"
                  coords="669,137,556,107,285,148,285,180,557,147,669,173"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment1.jpg")}
                />
               
                <area
                  alt=""
                  title="Appartment2"
                  //href="/appartment_images/appartment2.jpg"
                  shape="poly"
                  coords="669,207,669,170,556,146,287,180,285,212,557,184"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment2.jpg")}
                />
               
                <area
                  alt=""
                  title="Appartment3"
                  //href="/appartment_images/appartment3.jpg"
                  shape="poly"
                  coords="668,240,556,224,287,242,285,210,556,184,668,204"
                  style={{ outline: "none" }}
                  target="_blank"
                  onmouseover="if(document.images) document.getElementById('Image-Maps-Com-image-maps-2023-08-22-094056').src= '';"
                  onmouseout="if(document.images) document.getElementById('Image-Maps-Com-image-maps-2023-08-22-094056').src= 'https://app.image-maps.com/m/private/0/lf0u0dh8qnk20to93tlpkajsjg_project1.png';"
                  onClick={() => fileURL("/plots/appartment3.jpg")}
                />
               
                <area
                  alt=""
                  title="Appartment4"
                  //href="/appartment_images/appartment4.jpg"
                  shape="poly"
                  coords="669,272.00000762939453,667,239.00000762939453,556,224.00000762939453,288,242.00000762939453,286,274.00000762939453,556,263.00000762939453"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment4.jpg")}
                />
               
                <area
                  alt=""
                  title="Appartment5"
                  //href="/appartment_images/appartment5.jpg"
                  shape="poly"
                  coords="669,307.00000762939453,668,273.00000762939453,555,263.00000762939453,286,273.00000762939453,283,303.00000762939453,556,301.00000762939453"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment5.jpg")}
                />
               
                <area
                  alt=""
                  title="Appartment6"
                  //href="/appartment_images/appartment6.jpg"
                  shape="poly"
                  coords="668,341.00000762939453,668,306.00000762939453,556,303.00000762939453,285,303.00000762939453,284,336.00000762939453"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment6.jpg")}
                />
               
                <area
                  alt=""
                  title="Appartment7"
                  //href="/appartment_images/appartment7.jpg"
                  shape="poly"
                  coords="666,375,668,338,556,342,284,336,284,366,556,384"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment7.jpg")}
                />
               
                <area
                  alt=""
                  title="Appartment8"
                  //href="/appartment_images/appartment8.jpg"
                  shape="poly"
                  coords="668,409.00001525878906,669,371.00001525878906,557,380.00001525878906,285,365.00001525878906,284,399.00001525878906,558,421.00001525878906"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment8.jpg")}
                />
               
                <area
                  alt=""
                  title="Appartment9"
                  ////href="/appartment_images/appartment9.jpg"
                  shape="poly"
                  coords="670,444.00001525878906,670,406.00001525878906,556,422.00001525878906,285,397.00001525878906,284,430.00001525878906,558,460.00001525878906"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment9.jpg")}
                />
               
              </map>
              {/* <img
            id="Image-Maps-Com-image-maps-2023-08-17-115829"
            className="maparea float-end"
            src="/plots/build.png"
            border="0"
            width="1000"
            height="900"
            orgWidth="1052"
            orgHeight="961"
            usemap="#image-maps-2023-08-17-115829"
            alt=""
            style={{maxWidth:"1000px" , maxHeight:"900px"}}
          />
          <map
            name="image-maps-2023-08-17-115829"
            id="ImageMapsCom-image-maps-2023-08-17-115829"
          >
            <area
              shape="rect"
              coords="1050,959,1052,961"
              alt="Image Map"
              style={{ outline: "none" }}
              title="Image Map"
              //href="https://www.image-maps.com/"
            />
            <area
              alt=""
              data-for="toolTip1"
              data-place="top"
              title="Appartment1"
              //href="/plots/appartment1.jpg"
              shape="poly"
              coords="301,193,584,153,703,184,703,218,584,199,300,226"
              style={{ outline: "none" }}
              target="_blank"
              onClick={() => fileURL("/plots/appartment1.jpg")}
            />
            <area
              alt=""
              title="Appartment2"
              //href="/plots/appartment1.jpg"
              shape="poly"
              coords="300,227,583,198,704,220,703,253,583,243,302,259"
              style={{ outline: "none" }}
              target="_blank"
              onClick={() => fileURL("/plots/appartment9.jpg")}
            />
            <area
              alt=""
              title="Appartment3"
              //href="/plots/appartment2.jpg"
              shape="poly"
              coords="301,259,586,239,704,255,704,290,586,282,300,291"
              style={{ outline: "none" }}
              target="_blank"
              onClick={() => fileURL("/plots/appartment3.jpg")}
            />
            <area
              alt=""
              title="Appartment4"
              //href="/plots/appartment3.jpg"
              shape="poly"
              coords="702,291,585,283,300,291,298,324,586,324,704,326"
              style={{ outline: "none" }}
              target="_blank"
              onClick={() => fileURL("/plots/appartment4.jpg")}
            />
            <area
              alt=""
              title="Appartment5"
              //href="/plots/appartment4.jpg"
              shape="poly"
              coords="301,324,585,323,702,327,703,364,584,367,301,358"
              style={{ outline: "none" }}
              target="_blank"
              onClick={() => fileURL("/plots/appartment5.jpg")}
            />
            <area
              alt=""
              title="Appartment6"
              //href="/plots/appartment5.jpg"
              shape="poly"
              coords="300,358,586,364,703,361,702,399,586,408,299,393"
              style={{ outline: "none" }}
              target="_blank"
              onClick={() => fileURL("/plots/appartment6.jpg")}
            />
            <area
              alt=""
              title="Appartment7"
              //href="/plots/appartment6.jpg"
              shape="poly"
              coords="299,394,582,407,702,397,703,435,588,449,298,428"
              style={{ outline: "none" }}
              target="_blank"
              onClick={() => fileURL("/plots/appartment7.jpg")}
            />
            <area
              alt=""
              title="Appartment8"
              //href="/plots/appartment7.jpg"
              shape="poly"
              coords="300,426,585,447,702,432,705,469,586,491,302,458"
              style={{ outline: "none" }}
              target="_blank"
              onClick={() => fileURL("/plots/appartment8.jpg")}
            />
          </map> */}
            </Col>
            <Col>
              <Image
                src={imgUel}
                thumbnail
                width="100%"
                height="100%"
                style={{}}
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectPlanFile;