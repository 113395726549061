// export const API_BASE_URL = "http://localhost:3003";
export const API_BASE_URL = "https://property.indicrm.io/ibs";
export const SOCKET_BASE_URL = "https://property.indicrm.io";
// export const SOCKET_BASE_URL = "http://localhost:3003";
export const VIEW_LEAD = "VIEW_LEAD";
export const VIEW_PROPERTY = "VIEW_PROPERTY";
export const EDIT_LEAD = "EDIT_LEAD";
export const DELETE_LEAD = "DELETE_LEAD";
export const VIEW_PRODUCT = "VIEW_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const MODIFY_ALL = "MODIFY_ALL";
export const VIEW_ALL = "VIEW_ALL";
export const VIEW_CONTACT = "VIEW_CONTACT";
export const VIEW_ORDER = "VIEW_ORDER";

//FILE TYPES
export const PDF = "pdf";
export const DOC = "doc";
export const DOCX = "docx";
export const XLS = "xls";
export const XLSX = "xlsx";
export const CSV = "csv";
export const PNG = "png";
export const JPG = "jpg";
export const JPEG = "jpeg";

export const PROJECT_BASE_URL = "https://property.indicrm.io";

export const SF_CLIENT_ID =
  "3MVG9pRzvMkjMb6mR6Hgt51DlrycRslvhw0uj18rXFebFnx8yraPjH881SL05t3BKSa9grNumPLcelv3kf4lU";
export const REDIRECT_URI = "https://property.indicrm.io/callback";

export const LEAD_STATUS_VALUES = [
  {
    label: "Open - Not Contacted",
    sortorder: 1,
    is_converted: false,
    is_lost: false,
  },
  {
    label: "Working - Contacted",
    sortorder: 2,
    is_converted: false,
    is_lost: false,
  },
  {
    label: "Closed - Converted",
    sortorder: 3,
    is_converted: true,
    is_lost: false,
  },
  {
    label: "Closed - Not Converted",
    sortorder: 4,
    is_converted: false,
    is_lost: true,
  },
];
export const Contact_Type = [
  {
    label: "Property Owner",
    sortorder: 1,
  },
  {
    label: "Buyer",
    sortorder: 2,
  },
  {
    label: "Seller",
    sortorder: 3,
  },
  {
    label: "Agent",
    sortorder: 4,
  },
  {
    label: "Staff",
    sortorder: 5,
  },
];
