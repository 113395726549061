import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RelatedListTask from "./RelatedListTask";
import inventoryApi from "../api/inventoryApi";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TaskEdit from "./TaskEdit";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";

const ContactView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const contact = location.state;
  const [contact, setContact] = useState(location.state ? location.state : {});
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [relatedListTasks, setRelatedListTasks] = useState(true);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [files, setFiles] = useState([]);
  const [refreshFileList, setRefreshFileList] = useState();
  const [refreshTaskList, setRefreshTaskList] = useState(Date.now());

  useEffect(() => {
    //taskList();
    // filesList();
    fetchContact();
  }, []);

  const fetchContact = () => {
    // Coming from Email
    console.log("from url lead id: ", location, contact.id);
    if (
      !contact.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      console.log("IF----");
      contact.id = location.pathname.split("/")[2];
      //setRefreshFileList(Date.now())
      setRefreshTaskList(Date.now());
    }

    //console.log('proepties ', property.id);
    async function initContact() {
      let result = await inventoryApi.fetchContact(contact.id);
      ////console.log('result:', result);
      if (result) {
        //console.log('property: ',result);
        setContact(result);
      } else {
        setContact({});
      }
    }
    initContact();
  };

  const taskList = () => {
    async function init() {
      let task = await inventoryApi.fetchTasks(contact.id);
      if (task && task?.length > 0) {
        setTasks(task);
      } else {
        setTasks([]);
      }
    }
    init();
  };
  const filesList = () => {
    async function init() {
      let file = await inventoryApi.fetchFiles(contact.id);
      console.log(file);
      if (file && file?.length > 0) {
        setFiles(file);
      } else {
        setFiles([]);
      }
    }
    init();
  };

  const deleteContact = async () => {
    const result = await inventoryApi.deleteContact(contact.id);
    if (result.success) navigate(`/contacts`);
  };

  const editContact = () => {
    navigate(`/contacts/${contact.id}/e`, { state: contact });
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };

  const submitfiles = () => {
    setModalShowFile(false);
    setFiles([]);
    setRefreshFileList(Date.now());
    filesList();
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setRelatedListTasks(true);
      setRelatedListFiles(false);
    } else if (key === "files") {
      setRelatedListTasks(false);
      setRelatedListFiles(true);
    }
  };
  return (
    <div>
      <Container>
        <CustomSeparator
          cmpListName="Contacts"
          currentCmpName={contact.firstname + " " + contact.lastname}
          indexLength="2"
          url="/contacts"
        ></CustomSeparator>
      </Container>

      {contact && (
        <Container className="mt-4">
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteLead={deleteContact}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="lead"
            />
          )}
          <Row className="view-form">
            <Col></Col>
            <Col lg={8} className="ibs-form-section">
              <Row className="view-form-header align-items-center">
                <Col lg={3}>
                  Contact
                  <h4>{contact.firstname + " " + contact.lastname}</h4>
                </Col>
                <Col lg={9} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editContact(true)}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <label>Name</label>
                  <span>
                    {contact.salutation} {contact.firstname} {contact.lastname}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Contact Type</label>
                  <span>{contact.type}</span>
                </Col>
                <Col lg={6}>
                  <label>Title</label>
                  <span>{contact.title}</span>
                </Col>

                <Col lg={6}>
                  <label>Email</label>
                  <span>{contact.email}</span>
                </Col>
                <Col lg={6}>
                  <label>Phone</label>
                  <span>{contact.phone}</span>
                </Col>
                {/*<Col lg={6}>
                <label>Company</label>
                <span>{contact.company}</span>
        </Col>*/}
                <Col lg={6}>
                  <label>Street</label>
                  <span>{contact.street}</span>
                </Col>
                <Col lg={6}>
                  <label>City</label>
                  <span>{contact.city}</span>
                </Col>
                <Col lg={6}>
                  <label>State</label>
                  <span>{contact.state}</span>
                </Col>
                <Col lg={6}>
                  <label>Pincode</label>
                  <span>{contact.pincode}</span>
                </Col>
                <Col lg={6}>
                  <label>Country</label>
                  <span>{contact.country}</span>
                </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="tasks"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                <Tab eventKey="tasks" title="Tasks"></Tab>
                <Tab eventKey="files" title="Files"></Tab>
              </Tabs>
              {relatedListTasks && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}
                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  //parent={contact}
                  parentid={contact.id}
                  table="contact"
                  submitTasks={submitTasks}
                />
              )}
              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  Upload File
                </Button>
              )}
              {modalShowTaskfile && (
                <FilesCreate
                  show={modalShowTaskfile}
                  onHide={() => setModalShowFile(false)}
                  parent={contact}
                  table="lead"
                  submitfiles={submitfiles}
                />
              )}
            </Card.Header>
            <Card.Body>
              {relatedListTasks ? (
                <RelatedListTask
                  parent={contact}
                  refreshTaskList={refreshTaskList}
                />
              ) : (
                ""
              )}
              {relatedListFiles ? (
                <RelatedListFiles
                  parent={contact}
                  files={files}
                  refreshFileList={refreshFileList}
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default ContactView;
