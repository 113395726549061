import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import PubSub from "pubsub-js";
import CityState from "../constants/CityState.json";
import Select from "react-select";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";
import jwt_decode from "jwt-decode";
// import io from "socket.io-client";

const LeadEdit = (props) => {
  // const socket = io.connect('http://localhost:3003');
  const location = useLocation();
  const navigate = useNavigate();
  const [lead, setLead] = useState(location.state ? location.state : {});
  //const { accounts } = useSelector((state) => state.api);
  const [validated, setValidated] = useState(false);
  const [leadStatusArray, setleadStatusArray] = useState(
    JSON.parse(localStorage.getItem("lead_status"))
  );
  const [state, setState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const [selectedProject, setSelectedProject] = useState();
  const [optionProject, setOptionProject] = useState({});
  const [selectedOwner, setSelectedOwner] = useState();  /* Added by yamini 15-12-2023 */
  /*useEffect(() => {
      let fetchedAccounts = [];
      accounts.map((item) => {
        fetchedAccounts.push({
          label: item.firstname,
          population: 4780127,
          capital: "Montgomery",
          region: "South",
        });
      });
      setOptions(fetchedAccounts);
    }, [accounts]);*/

  // Fetach all users and set to owner
  const [ownerList, setownerList] = useState([]);
  const [defaultOwner, setDefaultOwner] = useState([]);


  /* Start Update by yamini 15-12-2023 */
  
  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchUsers();
      console.log("Owners: ", result);
      if (result) {
        //result.push({"id": '', "username": ''});
        let owners = [];
        result.map((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.username;
            owners.push(obj);
          });
        setownerList(owners);


        // let user = jwt_decode(localStorage.getItem("token"));
        // console.log('user  :-  ',user);
        // var obj = {};
        // obj.value = user.id;
        // obj.label = user.username;
        // console.log('obj  :-  ',obj);
        // setSelectedOwner(obj);
        // setDefaultOwner(obj);
        // setLead({ ...lead, ownerid: user.id }); /* Changed by yamini 16-12-2023 */
        //   console.log('ownerid: user.id',  user.id)


        // if (location.state.id) {
        //     console.log('edit lead', lead);
        //     setDefaultOwner([{ 'id': lead.ownerid, 'username': lead.ownername }]);
        //     console.log(defaultOwner);
        // } else {
        //     console.log('create  case');
        //     setDefaultOwner([{ 'id': '', 'username': '' }]);
        // }
      }
      
      // Fetch Owners from user
      const userresult = await inventoryApi.fetchUsers();
      if (userresult) {
        setownerList(userresult);
        if (lead.id) {
          console.log("edit case", lead);
          setDefaultOwner([
            { id: lead.ownerid, username: lead.ownername },
          ]);
        } else {
          console.log("create  case");
          setDefaultOwner([{ id: "", username: "" }]);
        }
      }

      const ownerList = await inventoryApi.fetchUsers();
      console.log("accountList--> ", ownerList);
      if (ownerList && ownerList.length) {
        console.log("ownerList-->", ownerList);
        let ar = [];
        ownerList.map((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.firstname + " " + item.lastname;
          ar.push(obj);
          console.log("obj--> ", obj);
        });
        if (location?.state) {
          console.log("ar", ar);
          console.log("location.state.contactid", location.state);
          let acc = ar.filter((acc) => acc.value === location.state.ownerid);
          console.log("acc", acc);
          setSelectedOwner(acc);
        }
        setownerList(ar);


      let projectList;
      projectList = await inventoryApi.fetchProperties();
      console.log("accountList--> ", projectList);
      if (projectList && projectList.length) {
        console.log("accountList--> ", projectList);
        let ar = [];
        projectList.map((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.name;
          ar.push(obj);
          // console.log("obj--> ", obj);
        });

        
        
        if (location?.state) {
          let acc = ar.filter((acc) => acc.value === location.state.propertyid);
          setSelectedProject(acc);
        }
        setOptionProject(ar);
      } else {
        setOptionProject([]);
      }
    }

    let defaultValue = location?.state?.state
      ? location?.state?.state
      : "Rajasthan";
    let st = [];
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === defaultValue) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
      var state = {};
      obj.value = obj.state;
      obj.label = obj.state;
      ////console.log(" obj.label >"+ obj.label)
      st.push(obj);
    });
    setCities(filteredCities);

    let finalStates = {};
    st = st.filter(function (currentObject) {
      if (currentObject.value in finalStates) {
        return false;
      } else {
        finalStates[currentObject.value] = true;
        return true;
      }
    });
    //console.log('st:', st);
    setState(st);

    if (location?.state) {
    } else {
        // edit by yamini for set ownerid 16-12-2023
        let user = jwt_decode(localStorage.getItem("token"));
        console.log('user  :-  ',user);
        var obj = {};
        obj.value = user.id;
        obj.label = user.username;
        // setSelectedOwner(obj);
        // setDefaultOwner(obj);
      setLead({
        ...lead,
        city: "Ajmer",
        state: "Rajasthan",
        country: "India",
        status: "Open - Not Contacted",
        ownerid: user.id, /* Added by yamini 16-12-2023 */
      });
    }
  }
    init();
  }, []);

  /* End Update by yamini 15-12-2023 */
  

  const handleChange = (e) => {
    setLead({ ...lead, [e.target.name]: e.target.value });
  };

  const handleOwnerName = (event) => {
    console.log('event' , event);
    setSelectedOwner(event);
    setLead({ ...lead, ownerid: event.value });
  };
  
  const checkRequredFields = () => {
    console.log(lead.firstname);
    console.log(lead.lastname);
    console.log(lead.status);
    console.log(lead.phone);
    // console.log(lead.ownerid);

    if (
      lead.firstname &&
      lead.firstname.trim() !== "" &&
      lead.lastname &&
      lead.lastname.trim() !== "" &&
      lead.status &&
      lead.status.trim() !== "" &&
      lead.phone &&
      lead.phone.trim() !== "" 
    //   lead.ownerid &&
    //   lead.ownerid.trim() !== ""
    ) {
      return false;
    }
    return true;
  };

  
  // useEffect(() => {
  //   socket.on("receive_message", (data) => {
  //     console.log('data.message lead :-  ', data);
      
      
  //   });
  // }, [socket]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkRequredFields()) {
      setValidated(true);
      return;
    }

    let result = {};
    console.log("-----");

    //========= Logic to perform Create or Edit ======
    if (lead.id) {
      result = await inventoryApi.saveLead(lead);
      if (result.success) {

        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/leads/${lead.id}`, { state: lead });
      }
    } else {
      result = await inventoryApi.createLead(lead);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        
        navigate(`/leads/${result.id}`, { state: result });
      }
    }
    
  };


  // edit by Yamini 14-12-2023
  const handleCancel = () => {
    if (lead.id) navigate("/leads/" + lead.id, { state: lead });
    else navigate("/leads");
  };

//   const handleOwnerName = (event) => {
//     if (event.length > 0) {
//       setLead({ ...lead, ownerid: event[0].id });
//     } else {
//       setLead({ ...lead, ownerid: "" });
//     }
//   };


  const handleState = (e) => {
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    setCities(filteredCities);
    setLead({ ...lead, state: e.value, city: "" });
  };

  const handleSelectListChange = (value, name) => {
    //console.log('contact:', lead);
    setLead({ ...lead, [name]: value.value });
    setSelectedCity(value.value);
  };

  const handleProject = (e) => {
    //console.log('e', e)
    setSelectedProject(e);
    // setContact({ ...contact, accountid: e.value, accountname: e.label });
    setLead({ ...lead, propertyid: e.value });
  };
  
  return (
    <Container className="view-form">
      {location?.state?.id ? (
        <CustomSeparator
          cmpListName="Leads"
          cmpViewName={lead.firstname + " " + lead.lastname}
          currentCmpName="Edit"
          indexLength="2"
          indexViewLength="3"
          cmpViewUrl={"/leads/" + lead.id}
          url="/leads"
        ></CustomSeparator>
      ) : (
        <CustomSeparator
          cmpListName="Leads"
          currentCmpName="Create"
          indexLength="2"
          url="/leads"
        ></CustomSeparator>
      )}

      <Row className="mt-4">
        <Col></Col>
        <Col lg={8} className="ibs-form-section">
          <Form
            className="mt-3"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                {lead.id ? (
                  <>
                    Edit Lead
                    <h4>{lead.firstname + " " + lead.lastname}</h4>
                  </>
                ) : (
                  "New Lead"
                )}
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {/* <Form.Group className="mx-3" controlId="formBasicFirstName">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Salutation
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="salutation"
                                        placeholder="Enter salutation"
                                        value={lead.salutation}
                                        onChange={handleChange}
                                    />
                                </Form.Group> */}

                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Salutation
                  </Form.Label>
                  <Form.Select
                    aria-label="Select Salutation"
                    name="salutation"
                    value={lead.salutation}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="Mr">Mr.</option>
                    <option value="Mrs">Mrs.</option>
                    <option value="Ms">Ms.</option>
                    <option value="Dr">Dr.</option>
                    <option value="Prof">Prof.</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicFirstName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    First Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="firstname"
                    placeholder="Enter firstname"
                    value={lead.firstname}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicLastName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicLastName"
                  >
                    Last Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="lastname"
                    placeholder="Enter lastname"
                    value={lead.lastname}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicEmail">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicEmail"
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={lead.email}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicPhone">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPhone"
                  >
                    Phone
                  </Form.Label>
                  <Form.Control
                    required
                    type="phone"
                    name="phone"
                    placeholder="Enter phone"
                    value={lead.phone}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCompany"
                  >
                    Title
                  </Form.Label>
                  {/* <Form.Control

                                        type="text"
                                        name="title"
                                        placeholder="Enter Title"
                                        value={lead.title}
                                        onChange={(e) => handleChange(e)}
                                    /> */}

                  <Form.Select
                    value={lead.title}
                    name="title"
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="CEO">CEO</option>
                    <option value="Director">Director</option>
                    <option value="Manager">Manager</option>
                    <option value="Owner">Owner</option>
                    <option value="Partner">Partner</option>
                    <option value="Executive">Executive</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Enter Title.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFees"
                  >
                    Status
                  </Form.Label>
                  <Form.Select
                    required
                    aria-label="Enter Status"
                    name="status"
                    onChange={handleChange}
                    value={lead.status}
                  >
                    <option value="">--Select-Status--</option>
                    {leadStatusArray.map((item, index) => (
                      <option value={item.label} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    Enter LeadStatus.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicLeadSource"
                  >
                    Source
                  </Form.Label>

                  <Form.Select
                    aria-label="Enter status"
                    value={lead.leadsource}
                    name="leadsource"
                    onChange={handleChange}
                  >
                    <option value="">--Select-Source--</option>
                    <option value="Phone">Phone</option>
                    <option value="Partner Referral">Partner Referral</option>
                    <option value="BNI">BNI</option>
                    <option value="Purchased List">Purchased List</option>
                    <option value="Web">Web</option>
                    <option value="Email">Email</option>
                    <option value="Whatsapp">Whatsapp</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Instagram">Instagram</option>
{/* Start Added by Yamini 26-12-2023 */}
                    <option value="99acres">99acres</option>
                    <option value="Magic Brick">Magic Brick</option>
{/* End Added by Yamini 26-12-2023 */}
                    <option value="Other">Other</option>

                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Enter Lead Source.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFees"
                  >
                    Industry
                  </Form.Label>
                  <Form.Select
                    aria-label="Enter Industry"
                    value={lead.industry}
                    name="industry"
                    onChange={handleChange}
                  >
                    <option value="">--Select-Industry--</option>
                    <option value="Agriculture">Agriculture</option>
                    <option value="Apparel">Apparel</option>
                    <option value="Banking">Banking</option>
                    <option value="Biotechnology">Biotechnology</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Communications">Communications</option>
                    <option value="Construction">Construction</option>
                    <option value="Consulting">Consulting</option>
                    <option value="Education">Education</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Energy">Energy</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Entertainment">Entertainment</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Finance">Finance</option>
                    <option value="Food and Beverage">Food and Beverage</option>
                    <option value="Government">Government</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Hospitality">Hospitality</option>
                    <option value="Insurance">Insurance</option>
                    <option value="Legal">Legal</option>
                    <option value="Machinery">Machinery</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Media">Media</option>
                    <option value="Non Profit">Non Profit (NGO)</option>
                    <option value="Recreation">Recreation</option>
                    <option value="Retail">Retail</option>
                    <option value="Shipping">Shipping</option>
                    <option value="Technology">Technology</option>
                    <option value="Telecommunications">
                      Telecommunications
                    </option>
                    <option value="Transportation">Transportation</option>
                    <option value="Utilities">Utilities</option>
                    <option value="Other">Other</option>
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    Enter Industry.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Intrested Property
                  </Form.Label>
                  <Select
                    placeholder="Property Name... "
                    value={selectedProject}
                    onChange={handleProject}
                    options={optionProject}
                    //value={salutationValue}
                  ></Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide Property Name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicStreet">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Budget
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="budget"
                    placeholder="Enter Budget"
                    value={lead.budget}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicStreet">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Intrested Location
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    placeholder="Enter Location"
                    value={lead.location}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicStreet">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Company
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    placeholder="Enter company"
                    value={lead.company}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicOwner">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicOwner"
                  >
                    Assign Staff
                  </Form.Label>
                  {/* {defaultOwner.length > 0 ? */}
                  {console.log("=====>>>> defaultOwner" , defaultOwner)}
                  {console.log("=====>>>> selectedOwner" , selectedOwner)}
                  <Select
                    // required {/* Edit by Yamini 12-12-2023 */}
                    id="basic-typeahead-single"
                    defaultSelected={defaultOwner}
                    name="owner"
                    labelKey="username"
                    options={ownerList}
                    value={selectedOwner}
                    onChange={handleOwnerName}
                    placeholder="Choose a state..."
                  ></Select>
                  {/* : ''} */}
                </Form.Group>
              </Col>
              <Col lg={12} className="section-header">
                ADDRESS INFORMATION
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicState">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicState"
                  >
                    State
                  </Form.Label>
                  <Select
                    placeholder="State"
                    value={{ label: lead.state, value: lead.state }}
                    onChange={handleState}
                    options={state}
                  ></Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCity">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCity"
                  >
                    City
                  </Form.Label>
                  <Select
                    options={cities}
                    placeholder="Enter City"
                    onChange={(e) => {
                      handleSelectListChange(e, "city");
                    }}
                    name="city"
                    value={{ label: lead.city, value: lead.city }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicStreet">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Street
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    placeholder="Enter street"
                    value={lead.street}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicPin">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPin"
                  >
                    Pincode
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="pincode"
                    placeholder="Enter pincode"
                    value={lead.pincode}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCountry">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCountry"
                  >
                    Country
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    placeholder="Enter country"
                    value={lead.country}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFees"
                  >
                    Description
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    placeholder="Enter Description"
                    value={lead.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default LeadEdit;
