import React, { useState, useEffect } from "react";

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import Confirm from "./Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RelatedListTask from "./RelatedListTask";
import inventoryApi from "../api/inventoryApi";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TaskEdit from "./TaskEdit";
//import ProjectSaleEdit from "./ProjectSaleEdit";
import EmailComposer from "./common/EmailComposer";
import moment from "moment/moment";
import RealetedProperty from "./RelatedProperty";
import PubSub from "pubsub-js";
//import Image from "react-bootstrap";
import * as constants from "../constants/CONSTANT";
import Modal from "react-bootstrap/Modal";

const Project3DFile = (props) => {
  useEffect(() => {
    console.log(props.show3DURL);
  }, []);

  return (
    <Modal {...props} fullscreen={true}>
      <Modal.Header closeButton className="align-items-start">
        <Modal.Title className="d-flex flex-column ">
          {props.project.name} PROJECT 3D PLAN
          <div>
            <i>Use mouse wheel to zoom in / out and rotate the 3d model</i>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <model-viewer
          style={{ width: "100%", height: "100%", background: "linear-gradient(#80FFDB, #5390D9)"}}
          // className="model-viewer"
          src={props.show3DURL}
          shadow-intensity="1"
          ar
          ar-scale="fixed"
          camera-controls
          touch-action="pan-y"
          
          xr-environment
          alt="A 3D model carousel"
        ></model-viewer>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default Project3DFile;
