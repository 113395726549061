import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { ShimmerTable } from "react-shimmer-effects";
import Select from "react-select";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "./InfoPill";
import Badge from "react-bootstrap/Badge";
import CustomSeparator from "./Breadcrumbs/CustomSeparator";
import { unparse as PapaUnparse } from "papaparse"; /* Added by Yamini 11-12-2023 */
import fileDownload from "js-file-download"; /* Added by Yamini 11-12-2023 */

const PropertyList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [properties, setProperties] = useState();
  const [filterObj, setFilterObj] = useState({});
  const [optionProject, setOptionProject] = useState({});
  const [selectedProject, setSelectedProject] = useState();
  // const [measurementFilter, setMeasurementFilter] = useState(""); /* Added by Yamini 11-12-2023 */
  const [measurementFilter, setMeasurementFilter] = useState({
    unit: "",
    area: "",
  }); /* Added by Yamini 12-12-2023 */

  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchProperties();
      //if(result && result.errors && result.errors)
      if (result) {
        setBody(result);
        setProperties(result);
      } else {
        setBody([]);
        setProperties([]);
      }

      let projectList = await inventoryApi.fetchProjects();
      if (projectList && projectList.length) {
        let ar = [];
        projectList.map((item) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.name;
          ar.push(obj);
        });
        setOptionProject(ar);
      } else {
        setOptionProject([]);
      }
    }
    init();
  }, []);

  // Start Added by Yamini 12-12-2023

  useEffect(() => {
    onFilterType();
  }, [filterObj, measurementFilter]);

  const onFilterType = () => {
    if (body) {
      let tempData = properties.filter((item) => {
        const typeFilter =
          !filterObj.type ||
          filterObj.type === "" ||
          item.type === filterObj.type;

        const projectFilter =
          !filterObj.projectid ||
          filterObj.projectid === "" ||
          item.projectid === filterObj.projectid;

        // const areaFilter =
        //   !measurementFilter.selectedUnit ||
        //   measurementFilter.selectedUnit === "" ||
        //   `${item.area}, ${item.areameasure}`
        //     .toLowerCase()
        //     .includes(measurementFilter.selectedUnit.toLowerCase());

        const areaFilter =
          !measurementFilter.selectedUnit ||
          measurementFilter.selectedUnit === "" ||
          `${item.area}, ${item.areameasure}`
            .toLowerCase()
            .includes(measurementFilter.selectedUnit.toLowerCase());

        const valueFilter =
          !measurementFilter.area ||
          measurementFilter.area === "" ||
          item.area === measurementFilter.area;

        const minAreaFilter =
          !measurementFilter.minArea ||
          measurementFilter.minArea === "" ||
          parseInt(item.area) >= parseInt(measurementFilter.minArea);

        const maxAreaFilter =
          !measurementFilter.maxArea ||
          measurementFilter.maxArea === "" ||
          parseInt(item.area) <= parseInt(measurementFilter.maxArea);

        return (
          typeFilter &&
          projectFilter &&
          areaFilter &&
          valueFilter &&
          minAreaFilter &&
          maxAreaFilter
        );
      });
      setBody(tempData);
    }
  };

  const handleUnitChange = (e) => {
    const selectedUnit = e.target.value;

    setMeasurementFilter({ ...measurementFilter, selectedUnit });
  };

  const handleMinAreaChange = (e) => {
    setMeasurementFilter({ ...measurementFilter, minArea: e.target.value });
  };

  const handleMaxAreaChange = (e) => {
    setMeasurementFilter({ ...measurementFilter, maxArea: e.target.value });
  };

  // End Added by Yamini 12-12-2023

  // const onFilterType = () => {
  //   if (body) {
  //     let tempData = properties.filter((item) => {
  //       if (
  //         !filterObj.type ||
  //         filterObj.type === "" ||
  //         item.type === filterObj.type
  //       ) {
  //         return item;
  //       }
  //     });
  //     tempData = tempData.filter((item) => {
  //       if (
  //         !filterObj.projectid ||
  //         filterObj.projectid === "" ||
  //         item.projectid === filterObj.projectid
  //       ) {
  //         return item;
  //       }
  //     });

  //     tempData = tempData.filter((item) => {
  //       if (
  //         !filterObj.area ||
  //         filterObj.area === "" ||
  //         item.area === filterObj.area
  //       ) {
  //         return item;
  //       }
  //     });
  //     setBody(tempData);
  //   }
  // };

  const handleChange = (e) => {
    setFilterObj({ ...filterObj, type: e.target.value });
  };

  const handleProject = (e) => {
    if (e) {
      setFilterObj({ ...filterObj, projectid: e.value });
    } else {
      setFilterObj({ ...filterObj, projectid: null });
    }
    setSelectedProject(e);
  };

  // Create table headers consisting of 4 columns.
  const header = [
    {
      //  Changed by Yamini 11-12-2023
      title: "Property Name",
      prop: "name",
      isSortable: true,
      isFilterable: true,
      cell: (row) => (
        <Link to={"/properties/" + row.id} state={row}>
          {row.name}
        </Link>
      ),
      //  Added by Yamini 13-12-2023
      cellProps: {
        style: { width: "20%" },
      },
    },
    {
      title: "Project Name",
      prop: "projectname",
      isSortable: true,
      isFilterable: true,
    },
    {
      title: "Type",
      prop: "type",
      isSortable: true,
      isFilterable: true,
    },
    {
      title: "Measurement",
      prop: "area",
      isSortable: true,
      isFilterable: true,
      cell: (row) => {
        const measurement = [row.area, row.areameasure].filter(Boolean);
    
        return measurement?.length > 0 ? (
          <div className="task-list-description">
            {measurement.join(", ")}
          </div>
        ) : null;
      },
    },
    {
      title: "Status",
      prop: "status",
      isFilterable: true,
      isSortable: true,
      // cell: (row) => {
      //   return (
      //     <Badge bg={getStatusClass(row.leadstatus)} style={{display: "block", paddingBottom : "5px"}}>
      //       {row.leadstatus}
      //     </Badge>
      //   );

      // },
      cell: (row) => (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100px", // Edit by Yamini 13-12-2023
            height: "15px", // Adjust the height for better roundness
            padding: "5px 10px",
            fontSize: "12px",
            backgroundColor: row.status === "Available" ? "#28a745" : "#dc3545",
            color: "white",
            borderRadius: "15px", // Make it round
          }}
        >
          {row.status}
        </span>
      ),
    },

    // { title: 'City', prop: 'city', isSortable: true, isFilterable: true },
    // {
    //   title: "Contact",
    //   prop: "contactname",
    //   isSortable: true,
    //   isFilterable: true,
    // },
    // { title: "Phone", prop: "phone", isSortable: true, isFilterable: true },
    {
      title: "Assigned To",
      prop: "ownername",
      isSortable: true,
      isFilterable: true,
    },
    // start 28-12-2023
    {
      title: "Location",
      prop: "street",
      isSortable: true,
      isFilterable: true,
      cellProps: {
        style: { width: "10%" },
      },
    },
    {
      title: "City",
      prop: "city",
      isSortable: true,
      isFilterable: true,
    },

    {
      title: "State",
      prop: "state",
      isSortable: true,
      isFilterable: true,
    },
    // {
    //   title: "Address",
    //   prop: "address",
    //   isSortable: true,
    //   isFilterable: true,
    //   cell: (row) => (
    //     <span>
    //       <div className="address">{`${row.street}, ${row.city}, ${row.pincode}, ${row.state}`}</div>
    //     </span>
    //   ),
    //   //  Added by Yamini 13-12-2023
    //   cellProps: {
    //     style: { width: "20%" },
    //   },
    // },

    // End 28-12-2023
  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  // const formattedData = data.map(item => ({
  //   ...item,
  //   address: `${item.street}, ${item.city}, ${item.state}`,
  // }));

  const createProperty = () => {
    navigate(`/properties/e`);
  };

  /*Start Added by Yamini 11-12-2023 */
  const downloadCSV = () => {
    if (body) {
      const csv = PapaUnparse(body);
      fileDownload(csv, "property_data.csv");
    }
  };
  /*End Added by Yamini 11-12-2023 */

  return (
    <Container>
      <CustomSeparator
        // cmpListName="Report List"
        currentCmpName="Properties"
        indexLength="0"
        url="/properties"
      ></CustomSeparator>
      <Row className="g-0">
        <Col lg={12} className="px-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                  // body={formattedData} headers={columns}
                },
              }}
              sortProps={{
                initialState: {
                  prop: "createddate",
                  order: "desc",
                },
              }}
            >
              {/* edit by yamini 18-12-2023 */}
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={5}
                  className="d-flex flex-col justify-content-start align-items-end row"
                  style={{ marginBottom: "12px" }}
                >
                  <div className="col-6">
                    <Filter />
                  </div>
                  <div className="col-2">
                    <PaginationOptions labels={labels} />
                  </div>
                  <div className="col-4">
                    <Form.Group className="mt-4" controlId="formBasicStatus">
                      <Form.Select
                        aria-label="Enter status"
                        name="type"
                        onChange={handleChange}
                      >
                        <option value="">--Select Type--</option>
                        <option value="Villa/House">Villa/House</option>
                        <option value="Plot/Land">Plot/Land</option>
                        <option value="Flat/Apartment">Flat/Apartment </option>
                        <option value="Shop/Commercial Space">
                          Shop/Commercial Space
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Col>

                {/* Start Added by Yamini 11-12-2023 */}
                <Col
                  xs={12}
                  sm={6}
                  lg={3}
                  className="d-flex flex-col justify-content-start align-items-center"
                >
                  <Form.Group
                    className="mt-4"
                    controlId="formBasicMeasurement"
                    // style={{ width: "150px" }}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Min Area"
                        name="minArea"
                        value={measurementFilter.minArea}
                        onChange={handleMinAreaChange}
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Max Area"
                        name="maxArea"
                        value={measurementFilter.maxArea}
                        onChange={handleMaxAreaChange}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="mt-4"
                    controlId="formBasicMeasurement"
                    // style={{ width: "100px" }}
                  >
                    <Form.Select
                      aria-label="Enter status"
                      name="type"
                      onChange={handleUnitChange}
                      defaultValue={{ value: "", label: "All" }}
                    >
                      <option value="">All</option>
                      <option value="Square Feet">Square Feet </option>
                      <option value="Square Meter">Square Meter</option>
                      <option value="Square yard">Square yard</option>
                      <option value="Acre">Acre</option>
                      <option value="Hectare">Hectare</option>
                      <option value="Bigha">Bigha</option>
                      <option value="Biswa">Biswa </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                {/* End Added by Yamini 11-12-2023 */}

                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-start align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <div>
                    <Select
                      placeholder="Project Name... "
                      className="custom-select username"
                      onChange={handleProject}
                      options={optionProject}
                      value={selectedProject}
                      isClearable
                    ></Select>
                  </div>
                  <div style={{ marginBottom: "15px" }} className="d-flex">
                    <InfoPill left="Total" right={body?.length} />
                    <div className="mt-4">
                      {/*Start Added by Yamini 11-12-2023  */}
                      <Button
                        className="btn-sm mr-2 me-3"
                        variant="outline-success"
                        onClick={downloadCSV}
                      >
                        <i
                          className="fa-solid fa-file-csv"
                          style={{ fontSize: "15px" }}
                        ></i>
                      </Button>
                      {/*End Added by Yamini 11-12-2023  */}
                      <Button
                        className="btn-sm"
                        variant="outline-primary"
                        onClick={() => createProperty(true)}
                      >
                        New Property
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>

              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </Container>
  );
};

export default PropertyList;
