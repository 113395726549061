import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import inventoryApi from "../../api/inventoryApi";

const PieChart = () => {
  const [leadTypesCounts, setLeadTypesCounts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const leadCountByType = await inventoryApi.fetchReportByName(
        "area_wise_property_report"
      );
      let labelCounts = {};

      leadCountByType?.forEach((allresultItem) => {
        const key = `${allresultItem.city}, ${allresultItem.state}`;
        if (labelCounts.hasOwnProperty(key)) {
          labelCounts[key].count += parseInt(allresultItem.count);
        } else {
          labelCounts[key] = {
            //name: `${allresultItem.city}, ${allresultItem.state}`,
            name: `${allresultItem.city}`,
            count: parseInt(allresultItem.count),
          };
        }
      });
      console.log("leadCountByType", leadCountByType);
      console.log("key");

      setLeadTypesCounts(Object.values(labelCounts));
    };

    fetchData();
  }, []);

  const options = {
    chart: {
      type: "pie",
      animations: {
        enabled: true,
      },
    },
    legend: {
      position: "top",
    },
    labels: leadTypesCounts.map((item) => item.name),
    dataLabels: {
      enabled: true,
      style: {
        //textShadow: ["white"],
        colors: ["white"],
      },
      // formatter: function (val, opts) {
      //   const percentage = ((leadTypesCounts[opts.seriesIndex].count / series.reduce((a, b) => a + b, 0)) * 100).toFixed(2);
      //   return `${percentage}%`;
      // },
      formatter: function (val, opts) {
        return leadTypesCounts[opts.seriesIndex].count.toString();
      },
    },
    plotOptions: {
      pie: {
        stroke: {
          colors: "transparent", // Remove border between colors
        },
      },
    },
    colors: [
      "rgba(155, 199, 132, 0.5)",
      "rgba(54, 162, 235, 0.5)",
      "rgba(205, 120, 86, 0.5)",
      "rgba(75, 192, 192, 0.5)",
      "rgba(153, 102, 255, 0.5)",
      "rgba(255, 159, 64, 0.5)",
      "rgba(255, 99, 132, 0.5)",
      "rgba(54, 162, 235, 0.5)",
      "rgba(255, 206, 86, 0.5)",
      "rgba(75, 192, 192, 0.5)",
      "rgba(153, 102, 255, 0.5)",
      "rgba(255, 159, 64, 0.5)",
    ],
    // tooltip: {
    //   y: {
    //     formatter: function (value, { seriesIndex, dataPointIndex }) {
    //       const name = leadTypesCounts[dataPointIndex].name;
    //       const percentage = (
    //         (leadTypesCounts[dataPointIndex].count /
    //           series.reduce((a, b) => a + b, 0)) *
    //         100
    //       ).toFixed(2);
    //       return ` ${percentage}%`;
    //     },
    //   },
    // },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const series = leadTypesCounts.map((item) => item.count);
  console.log("series", series);

  return (
    <div className="chart-container">
      <h2 className="chart-title">Property Area Wise</h2>{" "}
      <div className="chart-wrapper">
        {" "}
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          width="80%"
        />{" "}
      </div>{" "}
    </div>
  );
};

export default PieChart;
