import React, { useState, useEffect } from "react";

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import Confirm from "./Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RelatedListTask from "./RelatedListTask";
import inventoryApi from "../api/inventoryApi";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TaskEdit from "./TaskEdit";
//import ProjectSaleEdit from "./ProjectSaleEdit";
import EmailComposer from "./common/EmailComposer";
import moment from "moment/moment";
import RealetedProperty from "./RelatedProperty";
import PubSub from "pubsub-js";
//import Image from "react-bootstrap";
import * as constants from "../constants/CONSTANT";
import maphilight from "maphilight";
import $ from "jquery";
import Modal from "react-bootstrap/Modal";

const ProjectPlanFile2 = (props) => {
  const [imgUel, setImgUrl] = useState("");

  $(function () {
    $(".maparea").maphilight({
      stroke: false,
      fillColor: "85B061",
      fillOpacity: "0.8",
    });
  });

  const fileURL = (url) => {
    setImgUrl(url);
  };

  return (
    <Modal {...props} fullscreen={true}>
      <Modal.Header closeButton className="align-items-start">
        <Modal.Title className="d-flex flex-column ">
          {props.project.name} PROJECT PLAN
          <div>
            <i>Use mouse in hover and placing the mouse cursor on the target</i>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row>
            <Col lg={8}>
              <img
                className="maparea float-end"
                id="Image-Maps-Com-image-maps-2023-08-22-101303"
                src="/plots/build1.png"
                border="0"
                width="1000"
                height="900"
                orgWidth="1000"
                orgHeight="900"
                usemap="#image-maps-2023-08-22-101303"
                alt=""
              />
              <map
                name="image-maps-2023-08-22-101303"
                id="ImageMapsCom-image-maps-2023-08-22-101303"
              >
                {/* <area shape="rect" coords="998,898,1000,900" alt="Image Map" style={{ outline: 'none' }} title="Image Map" href="https://www.image-maps.com/" /> */}
                <area
                  alt=""
                  title="Appartment1"
                  onClick={() => fileURL("/plots/appartment1.jpg")}
                //   href="/plots/appartment1.jpg"
                  shape="poly"
                  coords="343,294,423,319,498,314,498,378,425,385,347,357"
                  style={{ outline: "none" }}
                  target="_blank"
                />
                <area
                  alt=""
                  title="Appartment2"
                  onClick={() => fileURL("/plots/appartment2.jpg")}
                //   href="/plots/appartment2.jpg"
                  shape="poly"
                  coords="497,444,499,375,426,381,352,355,352,420,426,450"
                  style={{ outline: "none" }}
                  target="_blank"
                />
                <area
                  alt=""
                  title="Appartment3"
                  onClick={() => fileURL("/plots/appartment3.jpg")}
                //   href="/plots/appartment3.jpg"
                  shape="poly"
                  coords="498,509,498,441,427,448,351,420,353,479,427,514"
                  style={{ outline: "none" }}
                  target="_blank"
                />
                <area
                  alt=""
                  title="Appartment4"
                  onClick={() => fileURL("/plots/appartment4.jpg")}
                //   href="/plots/appartment4.jpg"
                  shape="poly"
                  coords="504,570,502,507,432,513,357,479,357,543,432,578"
                  style={{ outline: "none" }}
                  target="_blank"
                />
                <area
                  alt=""
                  title="Appartment5"
                  onClick={() => fileURL("/plots/appartment5.jpg")}
                //   href="/plots/appartment5.jpg"
                  shape="poly"
                  coords="503,636,501,570,432,578,360,543,360,602,432,642"
                  style={{ outline: "none" }}
                  target="_blank"
                />
                <area
                  alt=""
                  title="Appartment6"
                  onClick={() => fileURL("/plots/appartment6.jpg")}
                //   href="/plots/appartment6.jpg"
                  shape="poly"
                  coords="506,691,502,633,433,642,365,607,361,663,434,703"
                  style={{ outline: "none" }}
                  target="_blank"
                />
                <area
                  alt=""
                  title="Appartment7"
                  onClick={() => fileURL("/plots/appartment6.jpg")}
                //   href="/plots/appartment7.jpg"
                  shape="poly"
                  coords="597,402.00000762939453,681,425.00000762939453,749,417.00000762939453,749,479.00000762939453,686,486.00000762939453,599,454.00000762939453"
                  style={{ outline: "none" }}
                  target="_blank"
                />
                <area
                  alt=""
                  title="Appartment8"
                  onClick={() => fileURL("/plots/appartment8.jpg")}
                //   href="/plots/appartment8.jpg"
                  shape="poly"
                  coords="748,537.0000076293945,746,479.00000762939453,688,482.00000762939453,600,454.00000762939453,598,511.00000762939453,684,546.0000076293945"
                  style={{ outline: "none" }}
                  target="_blank"
                />
                <area
                  alt=""
                  title="Appartment9"
                  onClick={() => fileURL("/plots/appartment9.jpg")}
                //   href="/plots/appartment9.jpg"
                  shape="poly"
                  coords="748,597.0000076293945,746,538.0000076293945,685,543.0000076293945,599,513.0000076293945,600,571.0000076293945,684,607.0000076293945"
                  style={{ outline: "none" }}
                  target="_blank"
                />
                <area
                  alt=""
                  title="Appartment10"
                  onClick={() => fileURL("/plots/appartment10.jpg")}
                //   href="/plots/appartment10.jpg"
                  shape="poly"
                  coords="747,656,750,598,688,606,602,570,605,627,684,663"
                  style={{ outline: "none" }}
                  target="_blank"
                />
                <area
                  alt=""
                  title="Appartment11"
                  onClick={() => fileURL("/plots/appartment1.jpg")}
                  //href="/plots/appartment1.jpg"
                  shape="poly"
                  coords="53,370,135,410,214,402,216,474,137,482,56,434"
                  style={{ outline: "none" }}
                  target="_blank"
                />
                <area
                  alt=""
                  title="Appartment11"
                  //href="/plots/appartment2.jpg"
                  shape="poly"
                  coords="215,402,211,332,131,339,50,306,56,374,134,410"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment4.jpg")}
                />
                <area
                  alt=""
                  title="Appartment12"
                  //href="/plots/appartment3.jpg"
                  shape="poly"
                  coords="221,542,219,473,138,479,57,432,63,499,144,551"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment1.jpg")}
                />
                <area
                  alt=""
                  title="Appartment13"
                  //href="/plots/appartment4.jpg"
                  shape="poly"
                  coords="223,611,222,537,142,549,66,498,65,559,145,620"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment3.jpg")}
                />
                <area
                  alt=""
                  title="Appartment14"
                  //href="/plots/appartment5.jpg"
                  shape="poly"
                  coords="228,674,224,608,145,617,68,562,78,635,149,683"
                  style={{ outline: "none" }}
                  target="_blank"
                  onClick={() => fileURL("/plots/appartment5.jpg")}
                />
              </map>
            </Col>
            <Col>
              <Image
                src={imgUel}
                thumbnail
                width="100%"
                height="100%"
                style={{}}
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectPlanFile2;
