import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import inventoryApi from "../../api/inventoryApi";
// import { cl } from '@fullcalendar/core/internal-common';

const BarChart = () => {
  const [monthName, setMonthName] = useState([]);
  const [incomeCount, setIncomeCount] = useState([]);
  const [expenseCount, setExpenseCount] = useState([]);

  var month = [];
  useEffect(() => {
    async function init() {
      const fetchData = await inventoryApi.fetchReportByName(
        "current_year_income_expense_month_wise"
      );

      console.log("fetchData=>" , fetchData);
      let months = [];
      let incomeData = [];
      let expenseData = [];
      if (fetchData && fetchData.length) {
        fetchData.map((item) => {
          if(months.indexOf(item.createdmonth?.trim()) === -1) {
            months.push(item.createdmonth?.trim());
          }
          if(item.type === 'Income'){
            incomeData.push(item.totalamount);
          }else if(item.type === 'Expense'){
            expenseData.push(item.totalamount);
          }

        });
        console.log("incomeData " , incomeData);
        console.log("expenseData " , expenseData);
        setMonthName(months);
        setIncomeCount(incomeData);
        setExpenseCount(expenseData);
      }
    }
    init();
  }, []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Properties income/expense",
      },
    },
  };
  var ch = "";
  // const labels = monthName.map((item) => {
  //   if (ch !== item.value) {
  //     ch = item.value;
  //     return item.value;
  //   }
  // });
  const labels  = monthName;

  //const labels = branchArr;
  // console.log('monthName : ', monthName);
  //console.log("leadCount"+JSON.stringify(monthName));
  //console.log("motnhhCount"+JSON.stringify(monthCount));
  const data = {
    labels,
    datasets: [
      {
        label: "Income",
        // data: incomeCount,
        data : incomeCount,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        // : 'rgba(255, 215, 0,0.7)' ,
      },
      {
        label: "Expense",
        // data: expenseCount,
        data : expenseCount,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        // backgroundColor: 'rgba(25, 135, 84, 0.7)',
      },
    ],
  };
  return <Bar options={options} data={data} width={100} height={50} />;
};

export default BarChart;
