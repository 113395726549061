import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, ProgressBar } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import FormData from "form-data";
import axios from "axios";
import moment from "moment";
import PubSub from "pubsub-js";
import * as constants from "../constants/CONSTANT";

const FilesCreate = (props) => {
  const [files, setFiles] = useState([]);
  const [isShow, setIsShow] = useState(true);
  const [progress, setProgress] = useState(0);
  const [fileDescription, setFileDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const imageType = [["png"], ["gif"], ["jpg"], ["jpeg"]];
  const fileType = [
    ["csv"],
    ["doc"],
    ["docx"],
    ["mp4"],
    ["pdf"],
    ["ppt"],
    ["pptx"],
    ["svg"],
    ["txt"],
    ["xls"],
    ["xlsx"],
  ];
  const [fileSortOrder, setFileSortOrder] = useState(""); /* Added by Yamini 12-12-2023 */
  const [sortOrders, setSortOrders] = useState([]); /* Added by Yamini 13-12-2023 */
  const MAX_SINGLE_FILE_SIZE_MB = 100;

/* Start Added by Yamini 13-12-2023 */
  useEffect(() => {
    async function init() {
      let filesData = await inventoryApi.fetchFiles(props.parent.id);
      if(filesData){
      let sortOrderValues = filesData.map(file => parseInt(file.sortorder));
      setSortOrders(sortOrderValues);
      console.log("sortOrders:", sortOrderValues);
      }
    }
    init();
  }, [props.parent.id]);

  useEffect(() => {
    const maxSortOrder = sortOrders.length > 0 ? Math.max(...sortOrders) : 0;   
    console.log("maxSortOrder", maxSortOrder); 
    setFileSortOrder(maxSortOrder + 1);
  }, [sortOrders]);

/* End Added by Yamini 13-12-2023 */

  const handleImageChange = (event) => {
    const documentTypes = ["project_image", "project_plan", "property_image"];
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (props.table === "property" || props.table === "project") {
        if (documentTypes.includes(selectedFileType)) {
          const fileTypeValid = imageType.some((types) =>
            types.includes(selectedFile.name.split(".").pop().toLowerCase())
          );
          if (!fileTypeValid) {
            setErrorMessage(
              `File with the following extensions are allowed: ${imageType
                .flat()
                .join(", ")}.`
            );
            setError(true);
            setSelectedImage(selectedFile);
          } else {
            setErrorMessage("");
            setError(false);
            setSelectedImage(selectedFile);
          }
        } else if (selectedFileType === "project_3d_plan") {
          const fileSizeValid =
            selectedFile.size / 1024 / 1024 <= MAX_SINGLE_FILE_SIZE_MB;
          //console.log("fileSizeValid", fileSizeValid);
          if (
            fileSizeValid &&
            selectedFile.name.split(".").pop().toLowerCase() === "glb"
          ) {
            setErrorMessage("");
            setError(false);
            setSelectedImage(selectedFile);
          } else {
            setErrorMessage(
              `File with the following extensions are allowed: glb and the file size should not exceed ${MAX_SINGLE_FILE_SIZE_MB} MB.`
            );
            setSelectedImage(selectedFile);
            setError(true);
          }
        } else if (selectedFileType === "other") {
          const fileTypeValid = fileType.some((types) =>
            types.includes(selectedFile.name.split(".").pop().toLowerCase())
          );
          if (!fileTypeValid) {
            setErrorMessage(
              `File with the following extensions are allowed: ${fileType
                .flat()
                .join(", ")}.`
            );
            setError(true);
            setSelectedImage(selectedFile);
          } else {
            setErrorMessage("");
            setError(false);
            setSelectedImage(selectedFile);
          }
        } else {
         
          setError(true);
          setSelectedImage(selectedFile);
        }
      } else {
        setSelectedImage(selectedFile);
        setError(false);
        setErrorMessage("");
      }
    } else {
      //console.log('yezs')
      setError(true);
    }
  };

  const handleSelectTypeChange = (event) => {
    let selectedType = event.target.value;
    console.log('selectedImage',selectedType,selectedImage)
    if (selectedImage && selectedType) {
      if (props.table === "property" || props.table === "project") {
        if (
          selectedType === "project_image" ||
          selectedType === "project_plan" ||
          selectedType === "property_image"
        ) {
          const fileTypeValid = imageType.some((types) =>
            types.includes(selectedImage.name.split(".").pop().toLowerCase())
          );
          if (!fileTypeValid) {
            setErrorMessage(
              `File with the following extensions are allowed: ${imageType
                .flat()
                .join(", ")}.`
            );
            setError(true);
          } else {
            setErrorMessage("");
            setError(false);
            setSelectedImage(selectedImage);
          }
        } else if (selectedType === "project_3d_plan") {
          const fileSizeValid =
            selectedImage.size / 1024 / 1024 <= MAX_SINGLE_FILE_SIZE_MB;
          console.log("fileSizeValid", fileSizeValid);
          if (
            fileSizeValid &&
            selectedImage.name.split(".").pop().toLowerCase() === "glb"
          ) {
            setErrorMessage("");
            setError(false);
            setSelectedImage(selectedImage);
          } else {
            setErrorMessage(
              `File with the following extensions are allowed: glb and the file size should not exceed ${MAX_SINGLE_FILE_SIZE_MB} MB.`
            );
            setError(true);
          }
        } else if (selectedType === "other") {
          const fileTypeValid = fileType.some((types) =>
            types.includes(selectedImage.name.split(".").pop().toLowerCase())
          );
          if (!fileTypeValid) {
            setErrorMessage(
              `File with the following extensions are allowed: ${fileType
                .flat()
                .join(", ")}.`
            );
            setError(true);
          } else {
            setErrorMessage("");
            setError(false);
            setSelectedImage(selectedImage);
          }
        }
      } else {
        setSelectedImage(selectedImage);
        setError(false);
      }
    }
    
    setSelectedFileType(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    //console.log(files);
    if (props.table === "property" || props.table === "project") {
      if (selectedImage && selectedFileType) {
        const token = localStorage.getItem("token");
        let current = new Date();
    
        var formData = new FormData();
        /* for (let i = 0; i < files.length; i++) {
            formData.append(`files${i}`, files[i]);
            
          } */
    
        /* if(props.table === 'project' && selectedFileType === 'Project_3D_Plan'){
            const fileResult = await inventoryApi.fetchFileWithParentIdAndType(props.parent.id, selectedFileType);
            console.log('fileResult',fileResult)
            if(fileResult && fileResult){
              const result = await inventoryApi.deleteFile(file.id);
            } 
          } */
        if (selectedImage) {
          formData.append("image", selectedImage);
          formData.append(`sortorder`, fileSortOrder);  /* Added by Yamini 12-12-2023 */
          formData.append(`description`, fileDescription);
          formData.append(`fileType`, selectedFileType);
        }
        console.log("props.parent.id", props.parent);
        /*  const result = await inventoryApi.createFile(props.parent.id, formData);
        if (result) {
          if (selectedFileType === "project_image" && result.imageUrl) {
            props.onImageUpload(result.imageUrl); // Pass the URL to parent component
          }
          PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Record Saved",
            message: "Record saved successfully",
          });
          submitfiles();
        } */
        axios
          .post(
            `${constants.API_BASE_URL}/api/files/${props.parent.id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
              },
              onUploadProgress: (data) => {
                // Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total));
              },
            }
          )
          .then((response) => {
            PubSub.publish("RECORD_SAVED_TOAST", {
              title: "Record Saved",
              message: "Record saved successfully",
            });
            submitfiles();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setErrorMessage('Please select document type and file');
        setError(true);
      }
    }else{
      if (selectedImage) {
        const token = localStorage.getItem("token");
        let current = new Date();
        var formData = new FormData();
        if (selectedImage) {
          formData.append("image", selectedImage);
          formData.append(`description`, fileDescription);
          formData.append(`fileType`, selectedFileType);
        }
        console.log("props.parent.id", props.parent);
        axios
          .post(
            `${constants.API_BASE_URL}/api/files/${props.parent.id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
              },
              onUploadProgress: (data) => {
                // Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total));
              },
            }
          )
          .then((response) => {
            PubSub.publish("RECORD_SAVED_TOAST", {
              title: "Record Saved",
              message: "Record saved successfully",
            });
            submitfiles();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setErrorMessage('Please select file');
        setError(true);
      }
    }
    
  };

  const submitfiles = () => {
    props.submitfiles();
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload Files
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {progress > 0 && (
          <ProgressBar now={progress} label={`${progress}%`} srOnly />
        )}
        {(props.table === "property" || props.table === "project") && (
          <Form.Group controlId="formBasicFileType" className="mt-3">
            <Form.Label>Document Type</Form.Label>
            <Form.Select
              value={selectedFileType}
              onChange={handleSelectTypeChange}
            >
              <option value="">Select Type</option>
              {props.table === "project" && (
                <>
                  <option value="project_image">Project Image</option>
                  <option value="project_plan">Project Plan</option>
                  <option value="project_3d_plan">Project 3D Plan</option>
                </>
              )}
              {props.table === "property" && (
                <>
                  <option value="property_image">Property Image</option>
                </>
              )}
              <option value="other">Other</option>
            </Form.Select>
          </Form.Group>
        )}


{/* Start Added by Yamini 12-12-2023 */}
        {selectedFileType === "property_image" && (
        <Form.Group controlId="formBasicSortOrder" className="mt-2">
                  <Form.Label>Image Sort Order</Form.Label>
                  <Form.Control
                    type="number"
                    name="sortorder"
                    placeholder="Enter Image Sort Order"
                    defaultValue={fileSortOrder}
                    onChange={(e) => setFileSortOrder(e.target.value)} 
                  />
                </Form.Group>
        )}
{/* End Added by Yamini 12-12-2023 */}


        {/* {selectedFileType && ( */}
        <Form.Group controlId="formBasicImage" className="mt-2">
          <Form.Label>Select Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </Form.Group>
        {/* )} */}

        <Form.Group controlId="formBasicDescription" className="mt-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            placeholder="Enter description"
            onChange={(e) => setFileDescription(e.target.value)}
          />
        </Form.Group>
        {errorMessage && (
          <div className="text-danger">
            <b>{errorMessage}</b>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="submit">
          <Button variant="success" onClick={handleSubmit} disabled={error}>
            Submit
          </Button>
        </div>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

//export default FilesCreate;

export default FilesCreate;
